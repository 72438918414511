import React from 'react';

export const Home = React.lazy(() => import('../Pages/Home'));
export const Companies = React.lazy(() => import('../Pages/CompaniesList'));
export const People = React.lazy(() => import('../Pages/PeopleList'));
export const FundingRounds = React.lazy(() => import('../Pages/FundingRounds'));
export const CompanyProfile = React.lazy(() => import('../Pages/CompanyProfile'));
export const Pipeline = React.lazy(() => import('../Pages/PipelineCompanies'));
export const Crypto = React.lazy(() => import('../Pages/Crypto'));
export const Flags = React.lazy(() => import('../Pages/Flag'));
export const SignIn = React.lazy(() => import('../Pages/SignIn'));
export const Landing = React.lazy(() => import('../Pages/Landing'));
export const NotFound = React.lazy(() => import('../Components/NotFound'));
