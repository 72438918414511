export const graphInfo = [{
  key: 'headcount',
  title: 'Headcount',
  color: '#0ED654'
}, {
  key: 'revenue',
  title: 'Revenue',
  color: '#2061EF'
}, {
  key: 'email_invoice',
  title: 'Email Invoices',
  color: '#EF202F'
}, {
  key: 'app_dau',
  title: 'App DAU',
  color: '#750fce'
}, {
  key: 'web_traffic',
  title: 'Web MAU',
  color: '#bf088e'
}, {
  key: 'sdk_integrations',
  title: 'SDK Integrations',
  color: '#02bdbd'
}, {
  key: 'g2crowd_num_ratings',
  title: 'G2 Crowd Ratings',
  color: '#f5cd05'
}, {
  key: 'open_jobs',
  title: 'Job Openings',
  color: '#bf4900'
}, {
  key: 'github_star_count',
  title: 'GitHub Stars',
  color: '#177d15'
}, {
  key: 'github_ranking',
  title: 'GitHub Ranking',
  color: '#2856a1'
}];