import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/company/profile';
import * as ACTIONS from '../../actions/company/profile';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CompanyProfileAction } from '../../types/company/profile';

export function* companyProfile({ payload }) {
  try {
    const response = yield call(API.getCompanyProfile, payload);
    yield put(ACTIONS.getCompanyProfileResponse(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* companyProfileSaga() {
  yield takeLatest(CompanyProfileAction.GET_COMPANY_PROFILE_REQUEST, companyProfile);
}
