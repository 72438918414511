import React from 'react';
import { Button } from 'antd';
import styles from './SeeMoreResultsButton.module.scss';

export default ({ onClick }) => (
  <div className={styles.seeMoreResultsButton}>
    <Button 
      xclassName={styles.button}
      onClick={onClick}
    >
      See More Results
    </Button>
  </div>
);
