import { PresentAction } from '../../types/present';

export const createPresentation = (presentationId, entityIds) => ({
  type: PresentAction.CREATE_PRESENTATION,
  payload: { presentationId, entityIds }
});

export const setPresentation = (presentationId, entityIds) => ({
  type: PresentAction.SET_PRESENTATION,
  payload: { presentationId, entityIds }
});

export const getPresentation = (presentationId) => ({
  type: PresentAction.GET_PRESENTATION,
  payload: { presentationId }
});
