import { LsvpUserAction } from '../../types/lsvp-user';
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/lsvp-user/list';
import * as Actions from '../../actions/lsvp-user/list';
import { dispatchSnackbarError } from '../../../Utils/Shared';

export function* getAllLSVPUsers() {
  try {
    const response = yield call(API.getLSVPUsers);
    yield put(Actions.setAllLSVPUsers(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* lsvpUserSaga() {
  yield takeLatest(LsvpUserAction.GET_ALL_LSVP_USERS, getAllLSVPUsers);
}
