import { PipelineListAction } from '../../types/pipeline/list';

export const getPipelineListRequest = payload => ({
  type: PipelineListAction.GET_PIPELINE_LIST_REQUEST,
  payload
});

export const getPipelineListResponse = payload => ({
  type: PipelineListAction.GET_PIPELINE_LIST_RECEIVE,
  payload
});

export const getPipelineListCountRequest = payload => ({
  type: PipelineListAction.GET_PIPELINE_LIST_COUNT_REQUEST,
  payload
});

export const getPipelineListCountResponse = payload => ({
  type: PipelineListAction.GET_PIPELINE_LIST_COUNT_RECEIVE,
  payload
});

export const downloadPipelineCSVAction = (downloadResolver, params = {}) => ({
  type: PipelineListAction.DOWNLOAD_PIPELINE_CSV,
  payload: {
    downloadResolver,
    params
  }
});

export const getMorePipelineListRequest = payload => ({
  type: PipelineListAction.GET_MORE_PIPELINE_LIST_REQUEST,
  payload
});

export const getMorePipelineListResponse = payload => ({
  type: PipelineListAction.GET_MORE_PIPELINE_LIST_RECEIVE,
  payload
});
