import React, { useState } from 'react';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import FilterControls from '../Filters/FilterControls';
import styles from './ColumnTitle.module.scss';

export default ({
  title,
  filters,
  filterKey,
  staticOptions,
  titleFilter,
  filterExceptions,
  addToFilters
}) => {
  const [visible, setVisible] = useState();

  if (!titleFilter) { return title; }

  if (filterExceptions && filterExceptions.includes(filterKey)) { return title; }

  const content = (
    <FilterControls
      filters={filters}
      filterKey={filterKey}
      staticOptions={staticOptions}
      visible={visible}
      addToFilters={addToFilters}
    />
  );

  if (!content) { return title; }

  return (
    <span className={styles.columnTitle}>
      <Popover
        content={content}
        trigger="click"
        placement="bottomLeft"
        visible={visible}
        onVisibleChange={setVisible}
      >
        <span className={styles.title}>{title}</span>
        <FontAwesomeIcon
          className={styles.icon}
          size="xs"
          icon={faFilter}
        />
      </Popover>
    </span>
  );
};