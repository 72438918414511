export const FundingRoundsList = {
  GET_FUNDING_ROUNDS_LIST: 'GET_FUNDING_ROUNDS_LIST',
  SET_FUNDING_ROUNDS_LIST: 'SET_FUNDING_ROUNDS_LIST',
  GET_MORE_FUNDING_ROUNDS_LIST: 'GET_MORE_FUNDING_ROUNDS_LIST',
  SET_MORE_FUNDING_ROUNDS_LIST: 'SET_MORE_FUNDING_ROUNDS_LIST',
  UPDATE_FUNDING_ROUNDS_LIST: 'UPDATE_FUNDING_ROUNDS_LIST',
  GET_FUNDING_ROUNDS_CHARTS: 'GET_FUNDING_ROUNDS_CHARTS',
  SET_FUNDING_ROUNDS_CHARTS: 'SET_FUNDING_ROUNDS_CHARTS',
  DOWNLOAD_FUNDING_ROUNDS_CSV: 'DOWNLOAD_FUNDING_ROUNDS_CSV',
  SET_FUNDING_ROUNDS_CHARTS_NEED_RELOADING: 'SET_FUNDING_ROUNDS_CHARTS_NEED_RELOADING',
};
