import { formatNumber } from "./NumberFormatter";

export const formatMoney = ({
  amount,
  currency = 'USD',
  abbreviated = true,
  appendCurrency = true,
  precision = 2,
  formatThousand = false
}) => formatNumber({ amount, currency, abbreviated, appendCurrency, precision, formatThousand });
