import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Table, Tag } from 'antd';
import { PersonNameColumn } from './PersonNameColumn';
import {
  renderOwners,
  parseLink,
  StatusColor
} from '../../../Utils/Shared';
import LinkedInIcon from '../../../Assets/images/LinkedIn_Logo.svg';
import AffinityIcon from '../../../Assets/images/Affinity.png';
import PeopleExpandedRow from '../PeopleExpandedRow/PeopleExpandedRow';
import { LoadingOutlined } from '@ant-design/icons';
import { changePersonSector } from '../../../Store/actions/people/details';
import CurrentPosition from './CurrentPosition';
import PriorPosition from './PriorPosition';
import {
  getFilterParams,
} from '../../../Utils/FiltersHelper';
import { useDeepCompareEffect } from '../../../Utils/UseDeepCompareEffect';
import { formatNumber } from '../../../Utils/NumberFormatter';
import {
  filterKeys,
  EntityType,
  Activity,
  ActionType
} from '../../../Utils/Constants';
import { logActivity } from '../../../Store/actions/log/actions';
import TableDropdown from '../../TableDropdown/TableDropdown';
import ActionButton from '../../Action/ActionButton';
import ActionPopover from '../../Action/ActionPopover';
import {
  getActionStatus,
  ActionStatus,
  getWokenSnooze
} from '../../../Utils/Action';
import { removeAction } from '../../../Store/actions/action/actions';
import LastInteractionDate from '../../Shared/LastInteractionDate';
import AddOwnersModal from '../../AddOwnersModal/AddOwnersModal';
import ColumnTitle from '../../Shared/ColumnTitle';
import Highlights from '../Highlights';
import tableStyles from '../../Shared/Table.module.scss';
import styles from './PeopleTable.module.scss';

export default ({
  people,
  count,
  sectors,
  highlights,
  filters,
  titleFilter,
  filterExceptions,
  scrollbarStyle,
  loadingMore,
  addToFilters,
  fetchMorePeople,
  getPersonSummary,
  getPersonFlag
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const lsvpUser = useSelector(state => state.login.lsvpUser);
  const loading = useSelector(state => state.loader.listLoader || state.loader.globalSearchPeopleLoader);
  const teamIds = useSelector(state => Object.keys(state.teamViews?.people ?? {}));
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    setExpandedRowKeys([]);
  }, [filters]);

  const columns = [
    {
      title: (
        <ColumnTitle
          title="Name"
          filters={filters}
          filterKey={filterKeys.personName}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'name',
      key: 'name',
      className: styles.name,
      width: 160,
      render: (name, person) => (
        <div>
          <PersonNameColumn
            name={name}
            photoUrl={person.photoUrl}
            regions={person.regions}
          />
        </div>
      )
    },
    {
      dataIndex: 'socialMediaLinks',
      key: 'socialMediaLinks',
      className: styles.socialMediaLinks,
      width: 25,
      render: (socialMediaLinks, { crmEntityId }) => {
        const links = [];
        if (socialMediaLinks?.['linkedin']) {
          links.push((
            <a
              className={styles.socialLink}
              href={parseLink(socialMediaLinks['linkedin'])}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.socialIcon} src={LinkedInIcon} alt="LinkedInIcon" />
            </a>
          ));
        }
        if (crmEntityId) {
          links.push((
            <a
              className={styles.socialLink}
              href={`https://lsvp.affinity.co/persons/${crmEntityId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.socialIcon} src={AffinityIcon} alt="AffinityIcon" />
            </a>
          ));
        }
        return links;
      }
    },
    {
      title: (
        <ColumnTitle
          title="Score"
          filters={filters}
          filterKey={filterKeys.score}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'score',
      key: 'score',
      className: styles.score,
      width: 60,
      align: 'right',
      sortDirections: ['descend', 'ascend'],
      render: score => <span>{formatNumber({ amount: score, precision: 1 })}</span>
    },
    {
      title: (
        <ColumnTitle
          title="Highlights"
          filters={filters}
          filterKey={filterKeys.highlights}
          staticOptions={highlights.map(highlight => ({value: highlight.id, label: highlight.name}))}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'highlights',
      key: 'highlights',
      className: styles.highlights,
      width: 150,
      render: highlights => (
        <Highlights highlights={highlights} />
      )
    },
    {
      title: (
        <ColumnTitle
          title="Current Position"
          filters={filters}
          filterKey={filterKeys.currentPosition}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'currentPosition',
      key: 'currentPosition',
      className: styles.currentPosition,
      width: 140,
      render: (currentPosition, { currentPositionCreatedAt }) => (
        <CurrentPosition
          currentPosition={currentPosition}
          currentPositionCreatedAt={currentPositionCreatedAt}
        />
      )
    },
    {
      title: (
        <ColumnTitle
          title="Prior Position"
          filters={filters}
          filterKey={filterKeys.priorPosition}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'priorPosition',
      key: 'priorPosition',
      className: styles.priorPosition,
      width: 140,
      render: priorPosition => <PriorPosition priorPosition={priorPosition} />
    },
    {
      title: (
        <ColumnTitle
          title="Sector"
          filters={filters}
          filterKey={filterKeys.sector}
          staticOptions={sectors.map(sector => ({value: sector.id, label: sector.name}))}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      className: `${styles.sector} ${tableStyles.dropdown}`,
      width: 140,
      render: person => (
        <TableDropdown
          values={sectors || []}
          selection={person.sector}
          tooltip="Change Sector"
          onSelect={sectorId => dispatch(changePersonSector(sectorId, person.id))}
        />
      )
    },
    {
      title: (
        <ColumnTitle
          title="Last Interaction"
          filters={filters}
          filterKey={filterKeys.lastInteraction}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'lastInteraction',
      key: 'lastInteraction',
      className: styles.lastInteraction,
      width: 130,
      render: lastInteraction => (
        <LastInteractionDate date={lastInteraction} />
      )
    },
    {
      title: (
        <ColumnTitle
          title="Owner"
          filters={filters}
          filterKey={filterKeys.owner}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'owners',
      key: 'owners',
      className: styles.owners,
      width: 150,
      render: (owners, person) => (
        <span>
          {owners.length ? <span className={styles.owner}>{renderOwners(owners)}</span> : null}
          <AddOwnersModal
            currentOwners={owners}
            entityId={person.id}
            entityType={EntityType.PERSON}
          />
        </span>
      )
    },
    {
      title: (
        <ColumnTitle
          title="Status"
          filters={filters}
          filterKey={filterKeys.status}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'status',
      key: 'status',
      className: styles.status,
      width: 165,
      render: (status, person) => (
        <ActionPopover
          actions={person.actions}
          entityId={person.id}
          entityType={EntityType.PERSON}
          teamIds={teamIds}
          originalOwners={person.owners}
          originalStatus={person.status}
          showSnoozed={filters.showSnoozed?.[0]?.value.toString() === 'true'}
          showBlocked={filters.showBlocked?.[0]?.value.toString() === 'true'}
          team={filters.team?.[0]?.value}
          flag={person.flag?.comment}
          getPersonFlag={getPersonFlag}
          singleActionType={ActionType.ASSIGN}
        >
          <Tag
            className={styles.statusTag}
            color={StatusColor[status]}
          >
            {status?.toUpperCase() || 'NONE'}
          </Tag>
        </ActionPopover>
      )
    },
    {
      className: styles.action,
      width: 40,
      render: person => (
        <ActionButton
          actions={person.actions}
          entityId={person.id}
          entityType={EntityType.PERSON}
          teamIds={teamIds}
          owners={person.owners}
          status={person.status}
          showSnoozed={filters.showSnoozed?.[0]?.value.toString() === 'true'}
          showBlocked={filters.showBlocked?.[0]?.value.toString() === 'true'}
          team={filters.team?.[0]?.value}
          flag={person.flag?.comment}
          getPersonFlag={getPersonFlag}
        />
      )
    }
  ];

  const getMorePeople = () => {
    if (count > people.length) {
      fetchMorePeople({
        ...getFilterParams(filters),
        limit: 50,
        offset: people.length
      });
    }
  };

  const handleScroll = event => {
    if (event.target.scrollTop === 0) {
      return; //Scrolling horizontally
    }

    const maxScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentScroll = event.target.scrollTop;

    if (!loadingMore && Math.abs(currentScroll - maxScroll) <= 100) {
      getMorePeople();
    }
  };

  const onRowCollapsed = key => {
    setExpandedRowKeys(expandedRowKeys.filter(rowKey => rowKey !== key));
  };

  const onRowExpanded = key => {
    if (!expandedRowKeys.includes(key)) {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  return (
    <div
      className={`${styles.peopleTable} ${tableStyles.table} mt-2 position-relative`}
      onScroll={handleScroll}
      key={'peopleTable'}
    >
      <Table
        loading={loading}
        columns={columns}
        dataSource={
          people
            ? people.map(person => ({
              ...person,
              key: person.id
            }))
            : []
        }
        pagination={false}
        scroll={scrollbarStyle}
        expandable={{
          expandedRowKeys,
          expandedRowRender: person => <PeopleExpandedRow person={person} team={filters.team?.[0]?.value} />,
          onExpand: (expanded, person) => {
            if (expanded) {
              if (!person.summary) {
                getPersonSummary(person.id);
              }
              const action = getWokenSnooze(person.actions, lsvpUser.id);
              if (action) {
                dispatch(removeAction({
                  entityId: person.id,
                  entityType: EntityType.PERSON,
                  actionType: action.actionType,
                  teamId: action.teamId,
                  userId: lsvpUser.id
                }));
                dispatch(logActivity(Activity.VIEW_WOKEN_PERSON, lsvpUser.id, {
                  personId: person.id
                }));
              }
              dispatch(logActivity(Activity.EXPAND_PERSON, lsvpUser.id, {
                personId: person.id
              }));
              onRowExpanded(person.key);
            } else {
              onRowCollapsed(person.key);
            }
          },
          rowExpandable: person => !person?.summary?.hasNoSummary
        }}
        rowClassName={person => {
          const actionStatus = getActionStatus(person.actions, lsvpUser.id, filters.team?.[0]?.value) ;
          if (actionStatus.includes(ActionStatus.BLOCKED)) { return tableStyles.blocked; }
          if (actionStatus.includes(ActionStatus.SNOOZED)) { return tableStyles.snoozed }
          if (actionStatus.includes(ActionStatus.WOKEN)) { return tableStyles.woken }
          if (actionStatus.includes(ActionStatus.FLAGGED)) { return tableStyles.flagged; }
          return '';
        }} 
      />
      {loadingMore && (
        <div className={tableStyles.spinner}>
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      )}
    </div>
  );
};
