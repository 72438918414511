import _ from 'lodash';
import { axiosInstance } from '../index';

const assignLSVPUsersToEntity = async ({
  entityId,
  entityType,
  addedUserIds = []
}) => {
  if (_.isEmpty(addedUserIds)) return;
  const request = addedUserIds.map(lsvpUserId =>
    axiosInstance.post(`lsvpUser/assign`, {
      entityId,
      entityType: entityType.toUpperCase(),
      lsvpUserId
    })
  );
  return await Promise.all(request);
};

const unassignLSVPUsersFromEntity = async ({
  entityId,
  entityType,
  removedUserIds = []
}) => {
  if (_.isEmpty(removedUserIds)) return;
  const request = removedUserIds.map(lsvpUserId =>
    axiosInstance.delete(`lsvpUser/unassign`, {
      data: {
        entityId,
        entityType: entityType.toUpperCase(),
        lsvpUserId
      }
    })
  );
  return await Promise.all(request);
};

export default { assignLSVPUsersToEntity, unassignLSVPUsersFromEntity };
