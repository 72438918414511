import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/company/list';
import * as ACTIONS from '../../actions/company/list';
import updateLoader from '../../actions/Loader';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CompanyListAction } from '../../types/company/list';

function* companiesList({ payload }) {
  try {
    yield put(updateLoader({ listLoader: true }));
    const response = yield call(API.getCompaniesList, payload);
    yield put(ACTIONS.getCompaniesListResponse(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ listLoader: false }));
  }
}

function* downloadCompaniesList({ payload }) {
  try {
    yield put(updateLoader({ csvLoader: true }));
    const { downloadResolver, params } = payload;
    const response = yield call(API.downloadCompaniesList, params);
    downloadResolver(response?.data?.data);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ csvLoader: false }));
  }
}

function* moreCompaniesList({ payload }) {
  try {
    const response = yield call(API.getCompaniesList, payload);
    yield put(ACTIONS.getMoreCompaniesListResponse(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* companiesListSaga() {
  yield takeLatest(CompanyListAction.GET_COMPANIES_LIST_REQUEST, companiesList);
  yield takeLatest(CompanyListAction.DOWNLOAD_COMPANIES_CSV, downloadCompaniesList);
  yield takeLatest(CompanyListAction.GET_MORE_COMPANIES_LIST_REQUEST, moreCompaniesList);
}
