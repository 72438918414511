import { CompanyListAction } from '../../types/company/list';

export const getCompaniesListRequest = payload => ({
  type: CompanyListAction.GET_COMPANIES_LIST_REQUEST,
  payload
});

export const getCompaniesListResponse = payload => ({
  type: CompanyListAction.GET_COMPANIES_LIST_RECEIVE,
  payload
});

export const downloadCompaniesCSVAction = (downloadResolver, params = {}) => ({
  type: CompanyListAction.DOWNLOAD_COMPANIES_CSV,
  payload: {
    downloadResolver,
    params
  }
});

export const getMoreCompaniesListRequest = payload => ({
  type: CompanyListAction.GET_MORE_COMPANIES_LIST_REQUEST,
  payload
});

export const getMoreCompaniesListResponse = payload => ({
  type: CompanyListAction.GET_MORE_COMPANIES_LIST_RECEIVE,
  payload
});
