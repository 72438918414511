import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/team/teamMembers';
import * as Actions from '../../actions/team/teamMembers';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { TeamMemberAction } from '../../types/team/teamMembers';


export function* getTeamMembers({ payload }) {
  try {
    const response = yield call(API.getTeamMembers, payload);
    yield put(Actions.setTeamMembers({ teamMembers: response.data.data.teamMembers }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* teamMembersSaga() {
  yield takeLatest(TeamMemberAction.GET_TEAM_MEMBERS, getTeamMembers);
}
