import React, { useEffect } from 'react';
import CompanyTable from '../CompanyList/CompanyTable/CompanyTable';
import SeeMoreResultsButton from './SeeMoreResultsButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMoreCompaniesSearchList,
  getSearchCompanySummary,
  getSearchCompanyFlag
} from '../../Store/actions/filter/globalSearch';
import { getCompaniesSearchList } from '../../Store/actions/filter/globalSearch';
import sharedStyles from '../Shared/Shared.module.scss';
import styles from './GlobalSearchCompaniesTable.module.scss';

export default function GlobalSearchCompaniesTable({ keyword }) {
  const dispatch = useDispatch();

  const companiesState = useSelector(state => state.globalSearch.companiesState);
  const lsvpUser = useSelector(state => state.login.lsvpUser);

  useEffect(() => {
    dispatch(
      getCompaniesSearchList({
        keyword,
        limit: 50,
        offset: 0,
        userId: lsvpUser.id
      })
    );
  }, [keyword, lsvpUser, dispatch]);

  return (
    <div className={`container-fluid ${sharedStyles.pagePadding}`}>
      <h4 className={styles.searchTitle}>Companies</h4>
      <CompanyTable
        {...companiesState}
        filters={{ keyword }}
        getCompanySummary={(id, params) => dispatch(getSearchCompanySummary(id, params))}
        getCompanyFlag={id => dispatch(getSearchCompanyFlag(id))}
        handleLoadMore={false}
        scrollbarStyle={{ x: 'calc(100% - 64px)', y: '488px' }}
      />
      {companiesState.companies?.length > 0 && (
        <SeeMoreResultsButton
          onClick={() =>
            dispatch(
              getMoreCompaniesSearchList({
                keyword,
                limit: 50,
                offset: companiesState.companies.length,
                userId: lsvpUser.id
              })
            )
          }
        />
      )}
    </div>
  );
}
