import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/crm/company';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CrmCompanyAction } from '../../types/crm/company';
import {
  setCompanyPeople,
  localAddCompanyPerson
} from '../../actions/crm/company';

export function* fetchCompanyPeople({ payload }) {
  try {
    const { companyId } = payload;
    const response = yield call(API.fetchCompanyPeople, companyId);
    yield put(setCompanyPeople({ companyId, people: response.data.data.people }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* addCompanyPerson({ payload }) {
  try {
    const { companyId, email, domain } = payload;
    const response = yield call(API.addCompanyPerson, companyId, email, domain);
    yield put(localAddCompanyPerson({ companyId, person: response.data.data.person }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* crmCompanySaga() {
  yield takeLatest(CrmCompanyAction.GET_COMPANY_PEOPLE, fetchCompanyPeople);
  yield takeLatest(CrmCompanyAction.ADD_COMPANY_PERSON, addCompanyPerson);
}

