/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import { isEmptyObject, isEmptyFilter } from './Shared';
import { filterKeys } from './Constants';

const sanitizeValue = filter => {
  const sanitize = filter => (typeof filter === 'object' ? { value: filter.value, label: filter.label } : filter);
  if (Array.isArray(filter)) { return filter.map(sanitize); } 
  return sanitize(filter);
};

export const filterDelimiter = '|';

export const removeFilter = (filters, updateFilters, tag, key = 0) => {
  const oldFilter = filters[tag];
  if (Array.isArray(oldFilter) && oldFilter?.length > 1) {
    const newFilter = oldFilter.filter((item, index) => index !== key);
    const updatedFilters = { ...filters, [tag]: newFilter };
    updateFilters(updatedFilters);
  } else {
    const { [tag]: removedFilter, ...updatedFilters } = filters;
    updateFilters(updatedFilters);
  }
};

export const updateTags = (
  updatedFilters,
  setTags,
  excludedFilterKeys
) => {
  const updatedTags = {};
  for (const [key, value] of Object.entries(updatedFilters)) {
    if (excludedFilterKeys.includes(key)) continue;

    updatedTags[key] = [];
    if (Array.isArray(value) && value.length) {
      value.forEach(filter => {
        filter.value && updatedTags[key].push(filter.label);
      });
    } else {
      value?.value && updatedTags[key].push(value.label);
    }
  }
  setTags(updatedTags);
};

export const dateFilterKeys = [
  filterKeys.lastInteractionDate,
  filterKeys.lastInteractionBefore,
  filterKeys.lastInteractionAfter,
  'announcedBefore',
  'announcedAfter'
];

// Get and Update URL with applied filters

export function parseUrlFilters() {
  const filters = {};
  document.location.search
    .substr(1)
    .split('&')
    .forEach(function (text) {
      // Format: BaseURL ? filterKey = value | label & filterKey = value | label ...
      const item = text.split('=');
      const key = decodeURIComponent(item[0]?.trim());
      const value = decodeURIComponent(item[1]?.trim());
      if (key && value) {
        const [filterValue, filterTag] = value.split(filterDelimiter);
        const updatedValue =
          dateFilterKeys.includes(key) && filterValue !== '0'
            ? moment(filterValue).toISOString()
            : filterValue;
        const filter = { label: filterTag, value: updatedValue };
        if (filters[key]) {
          // convert single value to array to use spread operator for both cases
          const prevFilterValues = Array.isArray(filters[key])
            ? filters[key]
            : [filters[key]];
          filters[key] = [...prevFilterValues, filter];
        } else {
          filters[key] = [filter];
        }
      }
    });
  return filters;
}

export function insertParamWithTagIntoURL(key, filter) {
  if (!filter?.value) return '';

  key = encodeURIComponent(key);
  const value = encodeURIComponent(filter.value);
  const label = encodeURIComponent(filter.label);

  return `${key}=${value}${filterDelimiter}${label}&`;
}

export function updateURLParams(filters, excludedKeys = []) {
  let url = '?';
  Object.entries(filters).forEach(([key, filter]) => {
    if (excludedKeys.includes(key)) {
      return;
    }
    if (Array.isArray(filter)) {
      filter.forEach(item => {
        url += insertParamWithTagIntoURL(key, item);
      });
    } else {
      url += insertParamWithTagIntoURL(key, filter);
    }
  });
  // remove last `&` from search params
  return url.slice(0, -1);
}

export const getRemoveTagFunction = ({
  filters,
  setFilters,
  setTags,
  unAppliedFilters,
  setUnAppliedFilters,
  excludedFilterKeys
}) => (tag, key) => {
  const updateFilters = updatedFilters => {
    setFilters(updatedFilters);
    updateTags(updatedFilters, setTags, excludedFilterKeys);
  };

  removeFilter(filters, updateFilters, tag, key);
  removeFilter(unAppliedFilters, setUnAppliedFilters, tag, key);
};

export const getSanitizedFilters = filters => Object.entries(filters).reduce(
  (sanitized, [key, value]) => key && value && !isEmptyObject(value) ? { ...sanitized, [key]: sanitizeValue(value) } : sanitized,
  {}
);

export const getNonemptyFilters = filters => Object.entries(filters).reduce(
  (nonempty, [key, value]) => !isEmptyFilter(value) ? { ...nonempty, [key]: value } : nonempty,
  {}
);

export const getNormalizedFilters = filters => Object.entries(filters).reduce(
  (normalized, [key, value]) => {
    if (isEmptyFilter(value)) {
      return normalized;
    }
    if (!Array.isArray(value)) {
      value = [value];
    }
    value = value.filter(({ value }) => value !== undefined);
    value = value.map(({ label, value }) => ({ label, value: value.toString() }));
    value = _.sortBy(value, 'value');
    return value.length !== 0 ? { ...normalized, [key]: value } : normalized;
  },
  {}
)

export const addSanitizedFilters = (filters = {}, moreFilters = {}) => ({ ...filters, ...getSanitizedFilters(moreFilters) });

export function putGrowthAsFirstSort(sortColumns) {
  const growthIndex = sortColumns.findIndex(
    ([column]) => column === filterKeys.growth
  );

  if (growthIndex !== -1) {
    const growth = sortColumns.splice(growthIndex, 1)[0];

    sortColumns.unshift(growth);
  }

  return sortColumns;
}

const parseSortFilter = sortColumns =>
  sortColumns.flatMap(([column, order]) => `[${column}, ${order}]`);

export const getFilterParams = filters =>
  Object.entries(addSanitizedFilters({}, filters)).reduce(
    (params, [key, value]) => {
      let parsedValue;
      if (Array.isArray(value)) {
        if (key === 'sort') {
          parsedValue = parseSortFilter(value);
        } else {
          parsedValue = value.flatMap(filter => filter.value);
        }
      } else {
        if (typeof value !== 'object' || !(value?.label && !value.value)) {
          parsedValue = value.value ? value.value : value;
        }
      }

      return parsedValue ? { ...params, [key]: parsedValue } : params;
    },
    {}
  );

export const addColumnToSorting = (sortCol, sortOrder, sort = []) => {
  const updatedSorting = sort.filter(([column]) => column !== sortCol);

  sortOrder && updatedSorting.push([sortCol, sortOrder]);

  return updatedSorting;
};

export const updateFilterGroup = (filterGroup, addToFilters) => addToFilters({
  [filterKeys.filterGroup]: !_.isEmpty(filterGroup.filters) || !_.isEmpty(filterGroup.filterGroup) ? [{
    label: 'Filter Group',
    value: JSON.stringify(filterGroup)
  }] : []
});

export const wrapFilterGroup = (filters, addToFilters) => {
  let filterGroup;
  try { filterGroup = JSON.parse(filters[filterKeys.filterGroup]?.[0]?.value); } catch(e) {};
  if (filterGroup) {
    filterGroup = { filterGroup: [filterGroup] }
    updateFilterGroup(filterGroup, addToFilters);
  }
};
