export const CompanyDetailsAction = {
  FETCH_COMPANY_OWNER: 'FETCH_COMPANY_OWNER',
  UPDATE_COMPANY_OWNER: 'UPDATE_COMPANY_OWNER',
  SET_LAST_INACTIVE_COMPANY: 'SET_LAST_INACTIVE_COMPANY',
  CHANGE_COMPANY_SECTOR: 'CHANGE_COMPANY_SECTOR',
  GET_COMPANY_LEADERSHIP: 'GET_COMPANY_LEADERSHIP',
  SET_COMPANY_LEADERSHIP: 'SET_COMPANY_LEADERSHIP',
  GET_COMPANY_COMPETITORS: 'GET_COMPANY_COMPETITORS',
  SET_COMPANY_COMPETITORS: 'SET_COMPANY_COMPETITORS',
  ADD_COMPANY_COMPETITOR: 'ADD_COMPANY_COMPETITOR',
  REMOVE_COMPANY_COMPETITOR: 'REMOVE_COMPANY_COMPETITOR',
  GET_COMPANY_FUNDING_ROUNDS: 'GET_COMPANY_FUNDING_ROUNDS',
  SET_COMPANY_FUNDING_ROUNDS: 'SET_COMPANY_FUNDING_ROUNDS',
  GET_COMPANY_TOP_RATED_EMPLOYEES: 'GET_COMPANY_TOP_RATED_EMPLOYEES',
  SET_COMPANY_TOP_RATED_EMPLOYEES: 'SET_COMPANY_TOP_RATED_EMPLOYEES',
  GET_COMPANY_MORE_TOP_RATED_EMPLOYEES: 'GET_COMPANY_MORE_TOP_RATED_EMPLOYEES',
  SET_COMPANY_MORE_TOP_RATED_EMPLOYEES: 'SET_COMPANY_MORE_TOP_RATED_EMPLOYEES',
  GET_COMPANY_GRAPH: 'GET_COMPANY_GRAPH',
  SET_COMPANY_GRAPH: 'SET_COMPANY_GRAPH',
  GET_COMPETITORS_GRAPH: 'GET_COMPETITORS_GRAPH',
  SET_COMPETITORS_GRAPH: 'SET_COMPETITORS_GRAPH',
  GET_COMPANY_GRAPH_TYPES: 'GET_COMPANY_GRAPH_TYPES',
  SET_COMPANY_GRAPH_TYPES: 'SET_COMPANY_GRAPH_TYPES',
  UPDATE_COMPANY_SECTOR_IC: 'UPDATE_COMPANY_SECTOR_IC',
  UPDATE_COMPANY_PROJECTED_RAISE_DATE: 'UPDATE_COMPANY_PROJECTED_RAISE_DATE',
  UPDATE_COMPANY_FINANCIALS: 'UPDATE_COMPANY_FINANCIALS',
  UPDATE_COMPANY_NEXT_STEP: 'UPDATE_COMPANY_NEXT_STEP',
  UPDATE_COMPANY_STATUS: 'UPDATE_COMPANY_STATUS',
  UPDATE_COMPANY_URGENT: 'UPDATE_COMPANY_URGENT',
  LOCAL_UPDATE_COMPANY_SECTOR_IC: 'LOCAL_UPDATE_COMPANY_SECTOR_IC',
  LOCAL_UPDATE_COMPANY_PROJECTED_RAISE_DATE: 'LOCAL_UPDATE_COMPANY_PROJECTED_RAISE_DATE',
  LOCAL_UPDATE_COMPANY_FINANCIALS: 'LOCAL_UPDATE_COMPANY_FINANCIALS',
  LOCAL_UPDATE_COMPANY_NEXT_STEP: 'LOCAL_UPDATE_COMPANY_NEXT_STEP',
  LOCAL_UPDATE_COMPANY_STATUS: 'LOCAL_UPDATE_COMPANY_STATUS',
  LOCAL_UPDATE_COMPANY_URGENT: 'LOCAL_UPDATE_COMPANY_URGENT'
};
