import { GlobalSearchAction } from '../../types/filter/globalSearch';
import { CrmIdAction } from '../../types/crm/id';
import { EntityType } from '../../../Utils/Constants';
import { COMPANIES_INITIAL_STATE } from '../company/list';
import { PEOPLE_INITIAL_STATE } from '../people/list';
import { Action } from '../../types/action';
import {
  localAddAction,
  localRemoveAction,
  localChangeAction
} from '../shared/action';
import { LsvpUserAction } from '../../types/lsvp-user';

export const INITIAL_STATE = {
  companiesState: COMPANIES_INITIAL_STATE,
  peopleState: PEOPLE_INITIAL_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GlobalSearchAction.SET_COMPANIES_SEARCH_LIST:
      return {
        ...state,
        companiesState: { ...state.companiesState, ...action.payload }
      };
    case GlobalSearchAction.GET_MORE_COMPANIES_SEARCH_LIST:
      return {
        ...state,
        companiesState: { ...state.companiesState, loadingMore: true }
      };
    case GlobalSearchAction.SET_MORE_COMPANIES_SEARCH_LIST:
      return {
        ...state,
        companiesState: {
          ...state.companiesState,
          loadingMore: false,
          companies: [
            ...state.companiesState.companies,
            ...(action.payload?.companies || [])
          ]
        }
      };
    case GlobalSearchAction.SET_PEOPLE_SEARCH_LIST:
      return {
        ...state,
        peopleState: { ...state.peopleState, ...action.payload }
      };
    case GlobalSearchAction.GET_MORE_PEOPLE_SEARCH_LIST:
      return {
        ...state,
        peopleState: { ...state.peopleState, loadingMore: true }
      };
    case GlobalSearchAction.SET_MORE_PEOPLE_SEARCH_LIST:
      return {
        ...state,
        peopleState: {
          ...state.peopleState,
          loadingMore: false,
          people: [
            ...state.peopleState.people,
            ...(action.payload?.people || [])
          ]
        }
      };

    case CrmIdAction.LOCAL_UPDATE_CRM_ID: {
      const { id, entityType, crmEntityId } = action.payload;
      switch (entityType) {
        case EntityType.COMPANY:
          return {
            ...state,
            companiesState: {
              ...state.companiesState,
              companies: state.companiesState.companies.map(company => company.id === id ? {
                ...company,
                crmEntityId,
                summary: company.summary ? { ...company.summary, crmEntityId } : undefined
              } : company)
            }
          };

        default:
          return state;
      }
    }

    case Action.LOCAL_ADD_ACTION: {
      const companiesState = localAddAction({
        state: state.companiesState,
        type: EntityType.COMPANY,
        key: 'companies',
        params: { ...action.payload, showSnoozed: true, showBlocked: true }
      });
      const peopleState = localAddAction({
        state: state.peopleState,
        type: EntityType.PERSON,
        key: 'people',
        params: { ...action.payload, showSnoozed: true, showBlocked: true }
      });
      return {
        ...state,
        companiesState,
        peopleState
      };
    }

    case Action.LOCAL_REMOVE_ACTION: {
      const companiesState = localRemoveAction({
        state: state.companiesState,
        type: EntityType.COMPANY,
        key: 'companies',
        params: action.payload
      });
      const peopleState = localRemoveAction({
        state: state.peopleState,
        type: EntityType.PERSON,
        key: 'people',
        params: action.payload
      });
      return {
        ...state,
        companiesState,
        peopleState
      };
    }

    case Action.LOCAL_CHANGE_ACTION: {
      const companiesState = localChangeAction({
        state: state.companiesState,
        type: EntityType.COMPANY,
        key: 'companies',
        params: action.payload
      });
      const peopleState = localChangeAction({
        state: state.peopleState,
        type: EntityType.PERSON,
        key: 'people',
        params: action.payload
      });
      return {
        ...state,
        companiesState,
        peopleState
      };
    }

    case LsvpUserAction.LOCAL_ASSIGN_LSVP_USERS_TO_ENTITY: {
      const { entityId, entityType, addedUserIds, lsvpUsers } = action.payload;
      if (entityType !== EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        companiesState: {
          ...state.companiesState,
          companies: state.companiesState.companies.map(company => 
            company.id === entityId ? {
              ...company,
              owners: [...company.owners, ...lsvpUsers.filter(user => addedUserIds.includes(user.id))]
            } : company
          )
        }
      };
    }

    case LsvpUserAction.LOCAL_UNASSIGN_LSVP_USERS_FROM_ENTITY: {
      const { entityId, entityType, removedUserIds } = action.payload;
      if (entityType !== EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        companiesState: {
          ...state.companiesState,
          companies: state.companiesState.companies.map(company => 
            company.id === entityId ? {
              ...company,
              owners: company.owners.filter(owner => !removedUserIds.includes(owner.id))
            } : company
          )
        }
      };
    }


    default:
      return state;
  }
};
