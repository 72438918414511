import {
  ActionType,
  SpecialTeamId,
  ActionDetailsType,
} from "./Constants";

export const ActionStatus = {
  BLOCKED: 'BLOCKED',
  SNOOZED: 'SNOOZED',
  WOKEN: 'WOKEN',
  FLAGGED: 'FLAGGED'
};

export const getActionStatus = (actions, userId, team)  => {
  if (!actions) { return []; }
  const status = [];
  for (const action of actions) {
    if (
      !action.deactivatedAt
      &&
      (
        (
          action.teamId === SpecialTeamId.ME
          &&
          action.userId === userId
        )
        ||
        action.teamId === SpecialTeamId.EVERYONE
        ||
        (
          action.teamId !== SpecialTeamId.ME
          &&
          action.teamId === team
        )
      )
    ) {
      switch (action.actionType) {
        case ActionType.BLOCK:
          status.push(ActionStatus.BLOCKED);
          break;
        case ActionType.ASSIGN:
        case ActionType.SNOOZE:
          if (action.triggeredAt) {
            status.push(ActionStatus.WOKEN);
          } else {
            status.push(ActionStatus.SNOOZED);
          }
          break;
        case ActionType.FLAG:
          status.push(ActionStatus.FLAGGED);
          break;
        default:
          break;
      }
    }
  }
  return status;
}

export const getWokenSnooze = (actions, userId, team)  => actions.find((action) => (
  !action.deactivatedAt
  &&
  action.actionType === ActionType.SNOOZE
  &&
  action.triggeredAt
  &&
  (
    (
      action.teamId === SpecialTeamId.ME
      &&
      action.userId === userId
    )
    ||
    action.teamId === SpecialTeamId.EVERYONE
    ||
    action.teamId === team
  )
));

export const getActionTypeDescription = (actionType, brief) => {
  switch (actionType) {
    case ActionType.ASSIGN:
      return brief ? 'Claimed' : 'Assigned';
    case ActionType.SNOOZE:
      return 'Snoozed';
    case ActionType.BLOCK:
      return 'Blocked';
    case ActionType.FLAG:
      return 'Flagged';
    case ActionType.CLASSIFY:
      return 'Classified';
    default:
      return 'Acted on';
  }
};

export const getActionDetailsDescription = details => {
  switch (details.type) {
    case ActionDetailsType.DAYS:
      return `${details.days} days`;
    case ActionDetailsType.CONTACT:
      return `${details.daysWithoutContact}/${details.daysWithContact} days without/with contact`;
    case ActionDetailsType.EVENT:
      const events = [];
      if (details.absoluteRevenue) { events.push(`revenue hits ${details.absoluteRevenue}`); }
      if (details.absoluteHeadcount) { events.push(`headcount hits ${details.absoluteHeadcount}`); }
      if (details.absoluteApps) { events.push(`apps hit ${details.absoluteApps}`); }
      if (details.relativeRevenue) { events.push(`revenue up ${details.relativeRevenue}%`); }
      if (details.relativeHeadcount) { events.push(`headcount up ${details.relativeHeadcount}%`); }
      if (details.relativeApps) { events.push(`apps up ${details.relativeApps}%`); }
      return events.join(' or ');
    case ActionDetailsType.CLASSIFICATION:
      return details.classification;
    default:
      return '';
  }
}

export const isBaseAction = actionType => [ActionType.ASSIGN, ActionType.SNOOZE, ActionType.BLOCK].includes(actionType);