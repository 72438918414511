import React from 'react';
import { Radio } from 'antd';
import SearchableSelect from '../Shared/SearchableSelect';
import FilterSearchAPI from '../../Network/Apis/filterSearch';
import { stopPropagation } from '../../Utils/Shared';
import {
  SearchIndex,
  anyFilterValue,
  noneFilterValue
} from '../../Utils/Constants';
import styles from './InteractionRelativeToPositionControls.module.scss';

const RadioType = {
  PARTNER: 'partner',
  ANY: 'any',
  NONE: 'none'
};

const userSearch = {
  searchFunction: FilterSearchAPI.searchByKeyword,
  params: { index: SearchIndex.LSVP_USER }
};

export default ({
  filters,
  filterKey,
  label,
  orNone,
  addToFilters
}) => {
  const isAny = () => filters[filterKey]?.[0]?.value === anyFilterValue;

  const isNone = () => filters[filterKey]?.[0]?.value === noneFilterValue;

  const getDefaultValue = () => {
    if (isAny()) { return RadioType.ANY; }
    if (isNone()) { return RadioType.NONE; }
    return RadioType.PARTNER;
  }

  const getItems = () => (isAny() || isNone() || !filters[filterKey] ? [] : filters[filterKey]);

  const onTypeChange = type => {
    switch (type) {
      case RadioType.ANY:
        addToFilters({ [filterKey]: [{ value: anyFilterValue, label: 'Any Partner' }] });
        break;
      case RadioType.NONE:
        addToFilters({ [filterKey]: [{ value: noneFilterValue, label: 'No Partners' }] });
        break;
      default:
        addToFilters({ [filterKey]: [] });
        break;
    }
  };

  const onPartnerChange = filter => addToFilters({ [filterKey]: filter });

  return (
    <div
      className={styles.currentPositionAfterInteractionControls}
      onScroll={stopPropagation}
    >
      <div className={styles.heading}>{label}</div>
      <Radio.Group
        defaultValue={getDefaultValue()}
        onChange={event => onTypeChange(event.target.value)}
      >
        <Radio
          key={RadioType.PARTNER}
          value={RadioType.PARTNER}
        >
          Partner
        </Radio>
        <SearchableSelect
          className={styles.partnerSelect}
          mode="multiple"
          items={getItems()}
          search={userSearch}
          disabled={isAny()}
          onChange={onPartnerChange}
        />
        <Radio
          className={styles.anyRadio}
          key={RadioType.ANY}
          value={RadioType.ANY}
        >
          Any Partner
        </Radio>
        {orNone ? (
          <Radio
            className={styles.noneRadio}
            key={RadioType.NONE}
            value={RadioType.NONE}
          >
            No Partners
          </Radio>
        ): null}
      </Radio.Group>
    </div>
  );
};
