import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/team/teamViews';
import * as Actions from '../../actions/team/teamViews';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../../Utils/Shared';
import { TeamViewAction } from '../../types/team/teamViews';


export function* getTeamViews({ payload }) {
  try {
    const response = yield call(API.getTeamViews, payload);
    yield put(Actions.setTeamViews({ teamViews: response.data.data.teamViews, viewType: payload?.viewType }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* saveTeamView({ payload }) {
  try {
    const response = yield call(API.saveTeamView, payload);
    yield put(Actions.setTeamViews({ teamViews: response.data.data.teamViews, viewType: payload.viewType }));
    yield call(dispatchSnackbarSuccess, 'Team view saved');
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* addTeamView({ payload }) {
  try {
    const response = yield call(API.addTeamView, payload);
    yield put(Actions.setTeamViews({ teamViews: response.data.data.teamViews, viewType: payload.viewType }));
    yield call(dispatchSnackbarSuccess, 'Team view added');
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* deleteTeamView({ payload }) {
  try {
    const response = yield call(API.deleteTeamView, payload);
    yield put(Actions.setTeamViews({ teamViews: response.data.data.teamViews, viewType: payload.viewType }));
    yield call(dispatchSnackbarSuccess, 'Team view deleted');
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* teamViewsSaga() {
  yield takeLatest(TeamViewAction.GET_TEAM_VIEWS, getTeamViews);
  yield takeLatest(TeamViewAction.SAVE_TEAM_VIEW, saveTeamView);
  yield takeLatest(TeamViewAction.ADD_TEAM_VIEW, addTeamView);
  yield takeLatest(TeamViewAction.DELETE_TEAM_VIEW, deleteTeamView);
}
