import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from 'antd';
import ShowMoreText from 'react-show-more-text';
import { unescapeNote } from '../../../Utils/Affinity';
import styles from './ReplyNoteItem.module.scss';

export default function NoteItem(props) {
  const {
    replyNoteContent,
    replyNoteCharacterName,
    replyNoteDate
  } = props;

  return (
    <div className={styles.replyNoteItem}>
      <div className={styles.noteInfo}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faLevelDownAlt} className={styles.levelDownIcon} />
          <Avatar size={24} alt="avatar-img" />
          <span className={styles.avatarName}>{replyNoteCharacterName}</span>
        </div>
        <div className="d-flex align-items-center">
          <span className={styles.noteDate}>{replyNoteDate}</span>
        </div>
      </div>
      <div className={styles.noteContent}>
        <ShowMoreText
          lines={6}
          more={<p>View More</p>}
          less={<p>View Less</p>}
          className={styles.noteContentText}
          anchorClass={styles.textControl}
          expanded={false}
          keepNewLines
        >
          {unescapeNote(replyNoteContent)}
        </ShowMoreText>
      </div>
    </div>
  );
}
