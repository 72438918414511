import { SectorAction } from '../../types/sector/list';

export const getSectors = payload => ({
  type: SectorAction.GET_SECTORS,
  payload
});

export const setSectors = payload => ({
  type: SectorAction.SET_SECTORS,
  payload
});
