import { TeamViewAction } from '../../types/team/teamViews';

export const getTeamViews = payload => ({
  type: TeamViewAction.GET_TEAM_VIEWS,
  payload
});

export const setTeamViews = payload => ({
  type: TeamViewAction.SET_TEAM_VIEWS,
  payload
});

export const saveTeamView = payload => ({
  type: TeamViewAction.SAVE_TEAM_VIEW,
  payload
});

export const addTeamView = payload => ({
  type: TeamViewAction.ADD_TEAM_VIEW,
  payload
});

export const deleteTeamView = payload => ({
  type: TeamViewAction.DELETE_TEAM_VIEW,
  payload
});
