import { call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../../../Network/Apis/people/flag';
import { setPeopleList } from '../../actions/people/list';
import { setPeopleSearchList } from '../../actions/filter/globalSearch';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { PeopleFlagAction } from '../../types/people/flag';
import { GlobalSearchAction } from '../../types/filter/globalSearch';

const addFlagToPerson = (people, personFlag) => people.map(person =>
  person.id === personFlag.person.id
    ? { ...person, flag: personFlag }
    : person
);

function* setPersonFlag(personFlag, message) {
  const peopleList = yield select(state => state.peopleList);
  yield put(
    setPeopleList({
      ...peopleList,
      message,
      people: addFlagToPerson(peopleList?.people, personFlag)
    })
  );
}

function* setGlobalSearchPersonFlag(personFlag, message) {
  const peopleState = yield select(state => state.globalSearch.peopleState);
  yield put(
    setPeopleSearchList({
      ...peopleState,
      message,
      people: addFlagToPerson(peopleState?.people, personFlag)
    })
  );
}

export function* personFlag({ type, payload }) {
  try {
    const response = yield call( API.getPersonFlag, payload.personId);
    const { personFlag, message } = response.data.data;
    switch (type) {
      case PeopleFlagAction.GET_PERSON_FLAG:
        yield setPersonFlag(personFlag, message);
        break;
      case GlobalSearchAction.GET_SEARCH_PERSON_FLAG:
        yield setGlobalSearchPersonFlag(personFlag, message);
        break;
      default:
        dispatchSnackbarError(`Unexpected action type: ${type}`);
        break;
    }
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* personFlagSaga() {
  yield takeEvery(
    [
      PeopleFlagAction.GET_PERSON_FLAG,
      GlobalSearchAction.GET_SEARCH_PERSON_FLAG
    ],
    personFlag
  );
}
