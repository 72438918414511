import { axiosInstance } from '../index';

const updatePersonStatus = async (id, newStatus) => {
  return axiosInstance.put(`people/${id}/status`, {
    newStatus
  });
};

const getPersonProfile = async personId => {
  return await axiosInstance.get(`people/${personId}`);
};

const changePersonSector = async (sectorId, personId) => {
  return axiosInstance.post(`people/${personId}/change-sector`, {
    sectorId
  });
};

export default {
  updatePersonStatus,
  getPersonProfile,
  changePersonSector
};
