import React from 'react';
import {
  formatDate,
  getDifferenceBetweenDates,
  daysAgo
} from '../../../Utils/Shared';
import styles from './CurrentPosition.module.scss';

export default ({ currentPosition, currentPositionCreatedAt }) => {
  if (
    !currentPosition
    ||
    !currentPosition.position
    ||
    !currentPosition.positionStartDate
    ||
    (!currentPosition.companyName && !currentPosition.companyId)
  ) {
    return null;
  }

  const start = currentPosition.positionStartDate.slice(0, 10);
  const { years, months } = getDifferenceBetweenDates(
    formatDate(start),
    formatDate(Date())
  );
  return (
    <div className={styles.currentPosition}>
      <div>{currentPosition.companyName || currentPosition.companyId}</div>
      <div>{currentPosition.position}, <span>{` ${years}y ${months}m`}</span></div>
      <div>{`${formatDate(start, 'MMM YYYY')} - Present`}</div>
      <div className={styles.created}>Created {daysAgo(currentPositionCreatedAt)}</div>
    </div>
  );
};
