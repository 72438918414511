import { call, put, takeLatest } from 'redux-saga/effects';
import { CrmIdAction } from '../../types/crm/id';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { localUpdateCrmId } from '../../actions/crm/id';
import API from '../../../Network/Apis/crm/id';

export function* setCrmId({ payload }) {
  try {
    yield call(API.setCrmId, payload);
    yield put(localUpdateCrmId(payload));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* crmIdSaga() {
  yield takeLatest(CrmIdAction.SET_CRM_ID, setCrmId);
}
