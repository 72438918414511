import React, { useState, useEffect } from 'react';
import { swapTags, renderUserSuggestion } from './utils';
import { MentionsInput, Mention } from 'react-mentions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from 'antd';
import ShowMoreText from 'react-show-more-text';
import { unescapeNote } from '../../../Utils/Affinity';
import styles from './NoteItem.module.scss';

export default function NoteItem({
  noteId,
  noteContent,
  noteCharacterName,
  noteDate,
  deleteNoteAction,
  updateNoteAction,
  getLsvpUsers,
  readonly,
  small
}) {
  const [editing, setEditing] = useState(false);
  const [content, setContent] = useState(noteContent);

  useEffect(() => {
    setContent(noteContent);
  }, [noteContent]);

  const users = getLsvpUsers();

  const handleDeleteNote = () => {
    deleteNoteAction(noteId);
  };

  return (
    <div className={styles.noteItem}>
      <div className={styles.info}>
        <div className="d-flex align-items-center">
          <Avatar size={24} alt="avatar-img" />
          <span className={styles.avatarName}>{noteCharacterName}</span>
        </div>
        <div className="d-flex align-items-center">
          <span className={styles.noteDate}>{noteDate}</span>
        </div>
      </div>
      {editing ? (
        <div className={styles.editNote}>
          <div>
            <MentionsInput
              value={content}
              markup="@*__id__*"
              onChange={(_event, newValue, _newPlainTextValue, _mentions) => {
                setContent(newValue);
              }}
              allowSpaceInQuery
              className="edit-note-input"
            >
              <Mention
                trigger="@"
                displayTransform={(id, display) => `@${display}`}
                data={users}
                renderSuggestion={renderUserSuggestion}
              />
            </MentionsInput>
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-center">
            <button
              onClick={() => {
                setEditing(false);
              }}
              className={styles.dismissButton}
            >
              Dismiss
            </button>
            <button
              onClick={() => {
                updateNoteAction(noteId, swapTags(content));
                setEditing(false);
              }}
              className={styles.updateButton}
            >
              Update
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.noteContent}>
            <ShowMoreText
              lines={6}
              more={<p>View More</p>}
              less={<p>View Less</p>}
              className={`{styles.noteContentText} ${small ? styles.noteContentSmallText : ''}`}
              anchorClass={styles.textControl}
              expanded={false}
              keepNewLines
            >
              {unescapeNote(noteContent)}
            </ShowMoreText>
          </div>
          <div className={styles.noteControl}>
            {
              readonly ? (
                <>&nbsp;</>
              ) : (
                <>
                  <FontAwesomeIcon
                    onClick={() => {
                      setEditing(true);
                    }}
                    icon={faPen}
                    className={styles.penIcon}
                  />
                  <FontAwesomeIcon
                    onClick={handleDeleteNote}
                    icon={faTrash}
                    className={styles.trashIcon}
                  />
                </>
              )
            }
          </div>
        </>
      )}
    </div>
  );
}
