import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import sharedStyles from '../Shared/Shared.module.scss';
import styles from './GlobalSearchResultsHead.module.scss';

export default ({ searchResult, onClose }) => (
  <div className={`${styles.globalSearchResultsHead} ${sharedStyles.pagePadding}`}>
    <p className={styles.searchResults}>Search results for “{searchResult}”</p>
    <Button
      className={styles.button}
      onClick={onClose}
    >
      <CloseOutlined title="Close" />
    </Button>
  </div>
);
