import React from 'react';
import CompanyWebSite from '../../../Assets/images/Website.png';
import Crunchbase from '../../../Assets/images/Crunchbase.png';
import Affinity from '../../../Assets/images/Affinity.png';
import LinkedIn from '../../../Assets/images/LinkedIn Icon.png';
import Facebook from '../../../Assets/images/Facebook Icon.png';
import Twitter from '../../../Assets/images/Twitter Icon.png';
import { getSocialMediaLinks } from '../../../Utils/Shared';
import styles from './CompanySocialLinks.module.scss';

export default ({ socialMediaData, companyWebSiteLink, verticalMargin, smallIcons }) => {
  const {
    linkedin_url: linkedInLink,
    twitter_url: twitterLink,
    affinity_url: affinityLink,
    facebook_url: facebookLink,
    cb_url: crunchbaseLink
  } = getSocialMediaLinks(socialMediaData);
  return (
    <div className={`${styles.comopanySocialLinks} ${verticalMargin ? styles.verticalMargin : ''}`}>
      {companyWebSiteLink && (
        <a
          href={companyWebSiteLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={`${styles.icon} ${smallIcons ? styles.small : ''}`}
            src={CompanyWebSite}
            alt="CompanyWebSite"
          />
        </a>
      )}
      {linkedInLink && (
        <a
          href={linkedInLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={`${styles.icon} ${smallIcons ? styles.small : ''}`}
            src={LinkedIn}
            alt="LinkedIn"
          />
        </a>
      )}
      {twitterLink && (
        <a
          href={twitterLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={`${styles.icon} ${smallIcons ? styles.small : ''}`}
            src={Twitter}
            alt="Twitter"
          />
        </a>
      )}
      {facebookLink && (
        <a
          href={facebookLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={`${styles.icon} ${smallIcons ? styles.small : ''}`}
            src={Facebook}
            alt="Facebook"
          />
        </a>
      )}
      {affinityLink && (
        <a
          href={affinityLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={`${styles.icon} ${smallIcons ? styles.small : ''}`}
            src={Affinity}
            alt="Affinity"
          />
        </a>
      )}
      {crunchbaseLink && (
        <a
          href={crunchbaseLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={`${styles.icon} ${smallIcons ? styles.small : ''}`}
            src={Crunchbase}
            alt="Crunchbase"
          />
        </a>
      )}
    </div>
  );
};
