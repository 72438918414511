import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/landing';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import {
  setLanding,
  setLandingFlag
} from '../../actions/landing';
import { LandingAction } from '../../types/landing';

export function* getLanding({ payload }) {
  try {
    const response = yield call(API.getLanding, payload);
    yield put(setLanding({
      token: payload.token,
      details: response.data.data.details
    }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* getFlag({ payload }) {
  try {
    const response = yield call(API.getFlag, payload);
    yield put(setLandingFlag(response.data.data.flag));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* addCrmInteraction({ payload }) {
  try {
    const { filters, postAction, ...rest } = payload;
    yield call(API.addCrmInteraction, rest);
    if (postAction) {yield put(postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* addFlagAction({ payload }) {
  try {
    const { filters, postAction, ...rest } = payload;
    yield call(API.addFlagAction, rest);
    if (postAction) { yield put(postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* landingSaga() {
  yield takeLatest(LandingAction.GET_LANDING, getLanding);
  yield takeLatest(LandingAction.GET_LANDING_FLAG, getFlag);
  yield takeLatest(LandingAction.ADD_LANDING_CRM_INTERACTION, addCrmInteraction);
  yield takeLatest(LandingAction.ADD_LANDING_FLAG_ACTION, addFlagAction);
}
