import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/funding/list';
import * as Actions from '../../actions/funding/list';
import updateLoader from '../../actions/Loader';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { FundingRoundsList } from '../../types/funding';

export function* fundingRoundsList({ payload }) {
  try {
    yield put(updateLoader({ listLoader: true }));
    const response = yield call(API.getFundingRoundsList, payload);
    yield put(Actions.setFundingRoundsList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ listLoader: false }));
  }
}

export function* moreFundingRoundsList({ payload }) {
  try {
    const response = yield call(API.getFundingRoundsList, payload);
    yield put(Actions.setMoreFundingRoundsList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* fundingRoundsCharts({ payload }) {
  try {
    const response = yield call(API.getFundingRoundsCharts, payload);
    yield put(Actions.setFundingRoundsCharts(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* downloadFundingRoundsList({ payload }) {
  try {
    yield put(updateLoader({ csvLoader: true }));
    const { downloadResolver, params } = payload;
    const response = yield call(API.downloadFundingRoundsList, params);
    downloadResolver(response?.data?.data);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ csvLoader: false }));
  }
}

export function* fundingRoundsListSaga() {
  yield takeLatest(FundingRoundsList.GET_FUNDING_ROUNDS_LIST, fundingRoundsList);
  yield takeLatest(FundingRoundsList.GET_MORE_FUNDING_ROUNDS_LIST, moreFundingRoundsList);
  yield takeLatest(FundingRoundsList.GET_FUNDING_ROUNDS_CHARTS, fundingRoundsCharts);
  yield takeLatest(FundingRoundsList.DOWNLOAD_FUNDING_ROUNDS_CSV, downloadFundingRoundsList);
}
