import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from './Auth';
import PATH_NAME from '../Routes/PathNames';
import { LocalStorageKeys } from './LocalStorage';

const allowedPaths = [
  PATH_NAME.HOME,
  PATH_NAME.COMPANIES,
  PATH_NAME.COMPANY_PROFILE,
  PATH_NAME.PEOPLE,
  PATH_NAME.FUNDING_ROUNDS,
  PATH_NAME.PIPELINE,
  PATH_NAME.FLAGS
];

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={props => {
        if (Auth.isAuth()) {
          if (
            process.env.REACT_APP_STAGE === 'production' &&
            !allowedPaths.includes(rest.path)
          ) {
            return <div>Coming Soon</div>;
          }
          return <Component {...props} />;
        } else {
          localStorage.setItem(
            LocalStorageKeys.REDIRECT_URL,
            window.location.href
          );

          return <Redirect to={PATH_NAME.SIGN_IN} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
