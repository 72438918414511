import { LsvpUserAction } from '../../types/lsvp-user';

export const LSVP_USERS_INITIAL_STATE = {
  users: []
};

export default (state = LSVP_USERS_INITIAL_STATE, action) => {
  switch (action.type) {
    case LsvpUserAction.SET_ALL_LSVP_USERS:
      return { ...state, users: action.payload.users };
    default:
      return state;
  }
};
