import { call, put, takeLatest } from 'redux-saga/effects';
import { GlobalSearchAction } from '../../types/filter/globalSearch';
import CompaniesAPI from '../../../Network/Apis/company/list';
import PeopleAPI from '../../../Network/Apis/people/list';
import { dispatchSnackbarError, } from '../../../Utils/Shared';
import * as Actions from '../../actions/filter/globalSearch';
import updateLoader from '../../actions/Loader';

function* getCompaniesList(keyword, { limit, offset, userId }) {
  const result = yield call(CompaniesAPI.getCompaniesList, {
    keyword,
    limit,
    offset,
    userId
  });
  return { companies: result.data.data.companies };
}

function* getPeopleList(keyword, { limit, offset, userId }) {
  const result = yield call(PeopleAPI.getPeopleList, {
    keyword,
    limit,
    offset,
    userId
  });
  return { people: result.data.data.people };
}

export function* fetchCompaniesSearchResults({
  payload: { keyword, ...filters }
}) {
  try {
    yield put(updateLoader({ globalSearchCompaniesLoader: true }));

    const companiesList = yield getCompaniesList(keyword, filters);

    yield put(Actions.setCompaniesSearchList(companiesList));
  } catch (err) {
    yield call(dispatchSnackbarError, err);
  } finally {
    yield put(updateLoader({ globalSearchCompaniesLoader: false }));
  }
}

export function* fetchMoreCompaniesSearchResults({
  payload: { keyword, ...filters }
}) {
  try {
    const companiesList = yield getCompaniesList(keyword, filters);

    yield put(Actions.setMoreCompaniesSearchList(companiesList));
  } catch (err) {
    yield call(dispatchSnackbarError, err);
  }
}

export function* fetchPeopleSearchResults({
  payload: { keyword, ...filters }
}) {
  try {
    yield put(updateLoader({ globalSearchPeopleLoader: true }));

    const peopleList = yield getPeopleList(keyword, filters);

    yield put(Actions.setPeopleSearchList(peopleList));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ globalSearchPeopleLoader: false }));
  }
}

export function* fetchMorePeopleSearchResults({
  payload: { keyword, ...filters }
}) {
  try {
    const peopleList = yield getPeopleList(keyword, filters);

    yield put(Actions.setMorePeopleSearchList(peopleList));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* globalSearchSaga() {
  yield takeLatest(
    GlobalSearchAction.GET_COMPANIES_SEARCH_LIST,
    fetchCompaniesSearchResults
  );
  yield takeLatest(
    GlobalSearchAction.GET_MORE_COMPANIES_SEARCH_LIST,
    fetchMoreCompaniesSearchResults
  );
  yield takeLatest(
    GlobalSearchAction.GET_PEOPLE_SEARCH_LIST,
    fetchPeopleSearchResults
  );
  yield takeLatest(
    GlobalSearchAction.GET_MORE_PEOPLE_SEARCH_LIST,
    fetchMorePeopleSearchResults
  );
}
