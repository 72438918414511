import { axiosInstance } from '../index';
const getPipelineList = async params => {
  return await axiosInstance.get(`pipeline`, { params, handlerEnabled: true });
};

const downloadPipelineList = async (params = {}) => {
  return await axiosInstance.get(`pipeline/csv`, {
    params,
    handlerEnabled: true
  });
};

export default {
  getPipelineList,
  downloadPipelineList
};
