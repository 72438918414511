import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './Store';
import App from './Pages/App';
import {
  initService as initErrorReportService,
  ErrorBoundary
} from './Utils/ErrorReport';
import './index.scss';
import * as FullStory from '@fullstory/browser';

const devMode = process.env.REACT_APP_STAGE !== 'production'

if (devMode) {
  window.atlas = {
    getState: () => store.getState()
  };
}

initErrorReportService();

FullStory.init({
  orgId: 'Z0EZP',
  devMode
});

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary Component={App} />
  </Provider>,
  document.querySelector('#root')
);
