import { axiosInstance } from '../index';

const getAffinityLists = async () => {
  return await axiosInstance.get('crm/list');
};

const getAllAffinityLists = async () => {
  return await axiosInstance.get('crm/list/all');
};

const addAffinityList = async ({ id, name, details }) => {
  return await axiosInstance.post('crm/list', {
    id,
    name,
    details
  });
};

const addAffinityListCompany = async ({ listId, listName, companyName, companyDomain, status, sectorIc }) => {
  return await axiosInstance.post(`crm/list/${listId}/company`, {
    listName,
    companyName,
    companyDomain,
    status,
    sectorIc
  });
};

const deleteAffinityListCompany = async ({ listId, companyId }) => {
  return await axiosInstance.delete(`crm/list/${listId}/company`, {
    data: { companyId }
  });
};

export default {
  getAffinityLists,
  getAllAffinityLists,
  addAffinityList,
  addAffinityListCompany,
  deleteAffinityListCompany
};
