import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import * as LazyComponent from '../Utils/LazyLoaded';
import Loader from '../Components/Loader/Loader';
import PrivateRoute from '../Utils/PrivateRoute';
import { PATH_NAME } from './PathNames';

export default () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute
          component={LazyComponent.Home}
          path={PATH_NAME.HOME}
          exact
        />
        <PrivateRoute
          component={LazyComponent.Companies}
          path={PATH_NAME.COMPANIES}
          exact
        />
        <PrivateRoute
          component={LazyComponent.People}
          path={PATH_NAME.PEOPLE}
          exact
        />
        <PrivateRoute
          component={LazyComponent.FundingRounds}
          path={PATH_NAME.FUNDING_ROUNDS}
          exact
        />
        <PrivateRoute
          component={LazyComponent.CompanyProfile}
          path={PATH_NAME.COMPANY_PROFILE}
          exact
        />
        <PrivateRoute
          component={LazyComponent.Pipeline}
          path={PATH_NAME.PIPELINE}
          exact
        />
        <PrivateRoute
          component={LazyComponent.Crypto}
          path={PATH_NAME.CRYPTO}
          exact
        />
        <PrivateRoute
          component={LazyComponent.Flags}
          path={PATH_NAME.FLAGS}
          exact
        />
        {/* Public routes that doesn't need any auth */}
        <LazyComponent.SignIn
          path={PATH_NAME.SIGN_IN}
          exact
        />
        <LazyComponent.Landing
          path={PATH_NAME.LANDING}
          exact
        />
      </Switch>
    </Suspense>
  );
};
