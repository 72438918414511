import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/trigger';
import * as Actions from '../../actions/trigger';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { TriggerAction } from '../../types/trigger';


export function* getTriggers({ payload }) {
  try {
    const response = yield call(API.getTriggers, payload);
    yield put(Actions.setTriggers({ triggers: response.data.data }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* triggerSaga() {
  yield takeLatest(TriggerAction.GET_TRIGGERS, getTriggers)
}
