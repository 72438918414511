export const CrmEntityAction = {
  GET_ENTITY_NOTES: 'GET_ENTITY_NOTES',
  SET_ENTITY_NOTES: 'SET_ENTITY_NOTES',
  CREATE_ENTITY_NOTE: 'CREATE_ENTITY_NOTE',
  UPDATE_ENTITY_NOTE: 'UPDATE_ENTITY_NOTE',
  DELETE_ENTITY_NOTE: 'DELETE_ENTITY_NOTE',
  GET_ENTITY_INTERACTIONS: 'GET_ENTITY_INTERACTIONS',
  GET_MORE_ENTITY_INTERACTIONS: 'GET_MORE_ENTITY_INTERACTIONS',
  SET_ENTITY_INTERACTIONS: 'SET_ENTITY_INTERACTIONS',
  SET_MORE_ENTITY_INTERACTIONS: 'SET_MORE_ENTITY_INTERACTIONS'
};
