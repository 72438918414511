import { TriggerAction } from '../../types/trigger';

export const getTriggers = payload => ({
  type: TriggerAction.GET_TRIGGERS,
  payload
});

export const setTriggers = payload => ({
  type: TriggerAction.SET_TRIGGERS,
  payload
});
