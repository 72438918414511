import React from 'react';
import {
  InputNumber,
  Menu,
  Dropdown
} from 'antd';
import { formatNumber } from '../../Utils/NumberFormatter';
import styles from './Range.module.scss';

export default ({
  min,
  max,
  range,
  className,
  onChangeMin,
  onChangeMax
}) => {
  const isAny = value => !value || value === 'any';

  const onClickMin = ({ key }) => onChangeMin(key);

  const onClickMax = ({ key }) => onChangeMax(key);

  const formatter = value => value.toString().split('').filter(d => Number.isInteger(+d)).join('');

  const minPredicate = value => isAny(max) || +value < +max;

  const maxPredicate = value => isAny(min) || +value > +min;

  const renderOptions = (values, predicate) => [
    <Menu.Item key="any">Any</Menu.Item>,
    ...values.filter(predicate).map(value => <Menu.Item key={value}>{formatNumber({ amount: value })}</Menu.Item>)
  ];

  const minMenu = <Menu onClick={onClickMin}>{renderOptions(range.min, minPredicate)} </Menu>;

  const maxMenu = <Menu onClick={onClickMax}>{renderOptions(range.max, maxPredicate)} </Menu>;

  return (
    <div className={`${styles.range} ${className}`}>
      <Dropdown overlay={minMenu} trigger={['click']}>
        <InputNumber
          className={styles.value}
          placeholder="Min"
          value={min}
          formatter={formatter}
          min={0}
          onChange={onChangeMin}
        />
      </Dropdown>
      <Dropdown overlay={maxMenu} trigger={['click']}>
        <InputNumber
          className={styles.value}
          placeholder="Max"
          value={max}
          formatter={formatter}
          min={0}
          onChange={onChangeMax}
        />
      </Dropdown>
    </div>
  );
};
