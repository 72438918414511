import React from 'react';
import styles from './Loader.module.scss';

export default () => {
  return (
    <div className={styles.loader}>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
