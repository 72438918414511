import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import './ModalHeader.scss';

export const ModalHeader = ({ updateSearchTerm, title, visible }) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    updateSearchTerm('');
  }, [updateSearchTerm, visible]);

  const handleTextChange = useCallback(
    debounce(() => {
      updateSearchTerm(searchTerm);
    }, 300),
    [searchTerm]
  );

  useEffect(() => {
    handleTextChange();

    return handleTextChange.cancel;
  }, [searchTerm, handleTextChange]);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <h5 className="add-owner-title">{title}</h5>
      <div className="filter-field">
        {visible && (
          <input
            type="text"
            placeholder="Filter by name"
            onChange={e => setSearchTerm(e.target.value)}
          />
        )}
        <FontAwesomeIcon icon={faFilter} className="filter-icon" />
      </div>
    </div>
  );
};
