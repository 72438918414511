import React from 'react';
import _ from 'lodash';

export const swapTags = text => {
  let displayText = _.trim(_.clone(text));
  const tags = text.match(/@\[([^)]+)\]\(([^)]+)\)/gi) || [];
  tags.forEach(myTag => {
    const matches = myTag.match(/[a-zA-Z0-9 ]+/gi);
    const tagDisplayValue = `@${matches[0]}`;
    displayText = displayText.replace(
      new RegExp(_.escapeRegExp(myTag), 'gi'),
      tagDisplayValue
    );
  });
  return displayText;
};

export const getUserIdsFromTags = text => {
  const tags = text.match(/@\[([^)]+)\]\(([^)]+)\)/gi) || [];
  const allUserIds = tags.map(myTag => {
    const data = myTag.match(/[a-zA-Z0-9 ]+/gi);
    return data?.[1];
  });
  return _.uniq(allUserIds);
}

export const renderUserSuggestion = entry => {
  return <div>{entry?.display}</div>;
};
