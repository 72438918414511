import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/auto';
import * as Actions from '../../actions/auto';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { AutoFlagAction } from '../../types/auto';


export function* getAutoFlags({ payload }) {
  try {
    const response = yield call(API.getAutoFlags, payload);
    yield put(Actions.setAutoFlags({ autoFlags: response.data.data }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* autoFlagSaga() {
  yield takeLatest(AutoFlagAction.GET_AUTO_FLAGS, getAutoFlags)
}
