import _ from 'lodash';
import { PATH_NAME } from '../Routes/PathNames';
import { capitalizeFirstOfEachWord } from './Utils';

export const nullFilterValue = 'null';
export const anyFilterValue = 'any';
export const nonInAffinity = 'nia';
export const noneFilterValue = 'none';

export const EntityType = {
  COMPANY: 'company',
  PERSON: 'person',
  FUNDING_ROUND: 'funding_round'
};

export const EntityTypeCode = {
  COMPANY: 1,
  PERSON: 0,
  FUNDING_ROUND: 9
};

export const EntityTypeName = {
  COMPANY: 'Company Traction',
  PERSON: 'Person',
  FUNDING_ROUND: 'Funding Round'
};

export const menuItems = [
  { label: 'Home', route: PATH_NAME.HOME },
  { label: 'Companies', route: PATH_NAME.COMPANIES },
  { label: 'People', route: PATH_NAME.PEOPLE },
  { label: 'Funding Rounds', route: PATH_NAME.FUNDING_ROUNDS },
  { label: 'Pipeline', route: PATH_NAME.PIPELINE },
  { label: 'Crypto', route: PATH_NAME.CRYPTO },
  { label: 'Flags', route: PATH_NAME.FLAGS }
];

export const filterKeys = {
  acquirer: 'acquirer',
  action: 'action',
  appDau: 'appDau',
  appDauWoW: 'appDauWoW',
  appDauMoM: 'appDauMoM',
  appDauQoQ: 'appDauQoQ',
  appDauYoY: 'appDauYoY',
  appDownloadsWoW: 'appDownloadsWoW',
  appDownloadsMoM: 'appDownloadsMoM',
  appDownloadsQoQ: 'appDownloadsQoQ',
  appDownloadsYoY: 'appDownloadsYoY',
  careerTenure: 'careerTenure',
  companyDescription: 'companyDescription',
  companyId: 'companyId',
  companyName: 'companyName',
  crmList: 'crmList',
  cryptoName: 'cryptoName',
  currentInvestor: 'currentInvestor',
  currentPosition: 'currentPosition',
  daysSinceAnnouncement: 'daysSinceAnnouncement',
  direction: 'direction',
  education: 'education',
  emailInvoiceMoM: 'emailInvoiceMoM',
  emailInvoiceQoQ: 'emailInvoiceQoQ',
  emailInvoiceYoY: 'emailInvoiceYoY',
  employeeCount: 'employeeCount',
  endDate: 'endDate',
  entityType: 'entityType',
  filterGroup: 'filterGroup',
  flagDate: 'flagDate',
  flagId: 'flagId',
  flagMy: 'flagMy',
  flagName: 'flagName',
  flagOwner: 'flagOwner',
  flagState: 'flagState',
  flagTime: 'flagTime',
  flagInteraction: 'flagInteraction',
  foundingDate: 'foundingDate',
  funding: 'funding',
  gitHubRanking: 'gitHubRanking',
  gitHubScore: 'gitHubScore',
  groupByOwner: 'groupByOwner',
  growth: 'growth',
  hash: 'hash',
  headcountMoM: 'headcountMoM',
  headcountQoQ: 'headcountQoQ',
  headcountYoY: 'headcountYoY',
  hidePortfolio: 'hidePortfolio',
  highlights: 'highlights',
  industry: 'industry',
  interactionAfterCurrentPosition: 'interactionAfterCurrentPosition',
  investor: 'investor',
  lastAssignedDate: 'lastAssignedDate',
  lastInteraction: 'lastInteraction',
  lastInteractionAfter: 'lastInteractionAfter',
  lastInteractionBefore: 'lastInteractionBefore',
  currentPositionAfterInteraction: 'currentPositionAfterInteraction',
  currentPositionAfterMeeting: 'currentPositionAfterMeeting',
  lastInteractionDate: 'lastInteractionDate',
  lastStatusDate: 'lastStatusDate',
  leadInvestor: 'leadInvestor',
  limit: 'limit',
  meetingAfterCurrentPosition: 'meetingAfterCurrentPosition',
  monthlyRevenue: 'monthlyRevenue',
  noFunding: 'noFunding',
  noInteraction: 'noInteraction',
  nonNotable: 'nonNotable',
  notableAngel: 'notableAngel',
  offset: 'offset',
  order: 'order',
  otherExperience: 'otherExperience',
  owner: 'owner',
  partnerInteraction: 'partnerInteraction',
  period: 'period',
  personName: 'personName',
  postMoney: 'postMoney',
  previousInvestor: 'previousInvestor',
  priorPosition: 'priorPosition',
  priorPositionTenure: 'priorPositionTenure',
  prominentInvestor: 'prominentInvestor',
  recency: 'recency',
  region: 'region',
  revenueMoM: 'revenueMoM',
  revenueQoQ: 'revenueQoQ',
  revenueYoY: 'revenueYoY',
  revenueVolumeMoM: 'revenueVolumeMoM',
  revenueVolumeQoQ: 'revenueVolumeQoQ',
  revenueVolumeYoY: 'revenueVolumeYoY',
  roundAmount: 'roundAmount',
  roundName: 'roundName',
  score: 'score',
  sector: 'sector',
  sectorIc: 'sectorIc',
  showAllQuarters: 'showAllQuarters',
  showBlocked: 'showBlocked',
  showCharts: 'showCharts',
  showExited: 'showExited',
  showPortfolio: 'showPortfolio',
  showSnoozed: 'showSnoozed',
  showTest: 'showTest',
  sort: 'sort',
  stage: 'stage',
  startDate: 'startDate',
  status: 'status',
  stealth: 'stealth',
  talentQuality: 'talentQuality',
  team: 'team',
  teamView: 'teamView',
  timeInCurrentStatus: 'timeInCurrentStatus',
  title: 'title',
  trigger: 'trigger',
  valuation: 'valuation',
  webTraffic: 'webTraffic',
  webTrafficWoW: 'webTrafficWoW',
  webTrafficMoM: 'webTrafficMoM',
  webTrafficQoQ: 'webTrafficQoQ',
  webTrafficYoY: 'webTrafficYoY'
};

export const drilldownKeys  = {
  quarter: 'drilldownQuarter',
  quarterCoverage: 'drilldownQuarterCoverage',
  investor: 'drilldownInvestor',
  sector: 'drilldownSector',
  region: 'drilldownRegion',
  industry: 'drilldownIndustry',
  industryCoverage: 'drilldownIndustryCoverage',
  stage: 'drilldownStage',
  classification: 'drilldownClassification'
};

export const commonFilters = {
  sort: 'sort'
};

export const commonExcludedFilterKeys = [
  ...Object.values(commonFilters),
  'origin',
  'team',
  'teamView'
];

export const fundingFilterOptions = [
  { value: '2000000', label: '< $2M' },
  { value: '5000000', label: '< $5M' },
  { value: '10000000', label: '< $10M' },
  { value: '20000000', label: '< $20M' },
  { value: '50000000', label: '< $50M' },
  { value: '100000000', label: '< $100M' },
  { value: '200000000', label: '< $200M' },
  { value: '500000000', label: '< $500M' }
];

export const Status = {
  NAMED_ACCOUNT: 'Named Account',
  ACTIVE_DEAL: 'Active Deal',
  TOP_OF_FUNNEL: 'Top of Funnel',
  NEW: 'New',
  PROSPECT: 'Prospect',
  TOP_PROSPECT: 'Top Prospect',
  HARD_TO_CRACK: 'Hard to Crack',
  IN_CONTACT: 'In Contact',
  TRACKING: 'Tracking',
  DUE_DILIGENCE: 'Due Diligence',
  ONE_PAGER: 'One Pager',
  TERM_SHEET: 'Term Sheet',
  PORTFOLIO: 'Portfolio',
  DECLINE: 'Decline',
  LOST: 'Lost',
  PASSED: 'Passed',
  NONE: 'None'
};

export const scoreFilterOptions = [
  { value: '90-', label: '> 90' },
  { value: '80-90', label: '80-90' },
  { value: '70-80', label: '70-80' },
  { value: '-70', label: '< 70' }
];

export const gitHubRankingFilterOptions = [
  { value: '90-', label: '> 90' },
  { value: '80-90', label: '80-90' },
  { value: '70-80', label: '70-80' },
  { value: '-70', label: '< 70' }
];

export const gitHubScoreFilterOptions = [
  { value: 'any', label: 'Any' },
  { value: '90', label: '90th percentile' },
  { value: '80', label: '80th percentile' },
  { value: '70', label: '70th percentile' }
];

export const statusFilterOptions = [
  { value: Status.NAMED_ACCOUNT, label: 'Named Account' },
  { value: Status.ACTIVE_DEAL, label: 'Active Deal' },
  { value: Status.TOP_OF_FUNNEL, label: 'Top of Funnel' },
  { value: Status.NEW, label: 'New' },
  { value: Status.PROSPECT, label: 'Prospect' },
  { value: Status.TOP_PROSPECT, label: 'Top Prospect' },
  { value: Status.HARD_TO_CRACK, label: 'Hard to Crack' },
  { value: Status.IN_CONTACT, label: 'In Contact' },
  { value: Status.TRACKING, label: 'Tracking' },
  { value: Status.DUE_DILIGENCE, label: 'Due Diligence' },
  { value: Status.ONE_PAGER, label: 'One Pager' },
  { value: Status.TERM_SHEET, label: 'Term Sheet' },
  { value: Status.PORTFOLIO, label: 'Portfolio' },
  { value: Status.DECLINE, label: 'Decline' },
  { value: Status.LOST, label: 'Lost' },
  { value: Status.PASSED, label: 'Passed' },
  { value: noneFilterValue, label: 'None' },
  { value: nonInAffinity, label: 'Not in Affinity' }
];

export const NO_INTERACTION_VALUE = '0';

export const currentStatusFilterOptions = [
  { value: 'any', label: 'Any' },
  { value: '7', label: '< 7 days' },
  { value: '30', label: '< 30 days' },
  { value: '90', label: '< 90 days' },
  { value: '180', label: '< 180 days' },
  { value: '365', label: '< 365 days' }
];

export const lastInteractionFilterOptions = [
  { value: '1', label: 'None in the last month' },
  { value: '3', label: 'None in the last 3 months' },
  { value: '6', label: 'None in the last 6 months' },
  { value: '12', label: 'None in the last 12 months' },
  { value: 'never', label: 'No Interaction' },
  { value: 'noMeeting', label: 'No Meeting' },
  { value: 'noEmail', label: 'No Email' },
  { value: 'any', label: 'Any Interaction' },
  { value: 'meeting', label: 'Any Meeting' },
  { value: 'email', label: 'Any Email' }
];

export const companyLastInteractionFilterOptions = [
  { value: '1', label: 'None in the last month' },
  { value: '3', label: 'None in the last 3 months' },
  { value: '6', label: 'None in the last 6 months' },
  { value: '12', label: 'None in the last 12 months' },
  { value: 'never', label: 'Never' },
  { value: 'any', label: 'Any' },
  { value: 'all', label: 'All' }
];

export const peopleLastInteractionFilterOptions = [
  { value: '1', label: 'None in the last month' },
  { value: '3', label: 'None in the last 3 months' },
  { value: '6', label: 'None in the last 6 months' },
  { value: '12', label: 'None in the last 12 months' },
  { value: 'never', label: 'Never' },
  { value: 'any', label: 'Any' },
  { value: 'all', label: 'All' }
];

export const peopleTitleOptions = [
  'Founder', 'President', 
  'CEO', 'COO', 'CTO', 'VP', 
  'Chief Executive Officer', 'Chief Operating Officer', 
  'Chief Technical Officer', 'Vice President', 'Director',
].map(t => ({ label: t, value: t }));

export const peopleSeniorityOptions = [
  { label: 'Founder', value: 'Founder' },
  { label: 'C-Suite', value: 'C-Suite' },
  { label: 'Vice President', value: 'Vice President' },
  { label: 'Director', value: 'Director' }
];

export const partnerInteractionTimeOptions = [
  { value: '-1', label: '< 1 day' },
  { value: '2-7', label: '2-7 days' },
  { value: '8-15', label: '8-15 days' },
  { value: '16-30', label: '16-30 days' },
  { value: '31-90', label: '31-90 days' },
  { value: '91-365', label: '91-365 days' },
  { value: '366-', label: '> 365 days' },
  { value: 'future', label: 'Future' }
];

export const partnerInteractionTypeOptions = [
  { value: 'meeting', label: 'Meeting' },
  { value: 'emailSent', label: 'Email Sent' },
  { value: 'emailReceived', label: 'Email Received' }
];

export const fundingRange = {
  min: [1, 1000000, 5000000, 10000000, 25000000],
  max: [1000000, 5000000, 10000000, 25000000, 50000000]
};

export const roundAmountRange = {
  min: [500000, 1000000, 5000000, 10000000, 15000000, 25000000, 50000000],
  max: [1000000, 5000000, 10000000, 15000000, 25000000, 50000000, 75000000]
};

export const postMoneyRange = {
  min: [50000000, 100000000, 200000000, 500000000, 1000000000, 2000000000],
  max: [100000000, 200000000, 500000000, 1000000000, 2000000000, 5000000000]
};

export const daysSinceAnnouncementRange = {
  min: [7, 30, 90, 180, 365],
  max: [7, 30, 90, 180, 365]
};

export const employeeCountRange = {
  min: [5, 10, 20, 50, 100, 500, 1000, 5000],
  max: [10, 20, 50, 100, 500, 1000, 5000]
};

export const monthlyRevenueRange = {
  min: [1, 100000, 500000, 1000000, 10000000, 50000000, 100000000],
  max: [100000, 500000, 1000000, 10000000, 50000000, 100000000]
};

export const appDauRange = {
  min: [100, 1000, 10000, 100000, 1000000],
  max: [100, 1000, 10000, 100000, 1000000]
};

export const webTrafficRange = {
  min: [1, 100000, 500000, 1000000, 10000000, 50000000, 100000000],
  max: [100000, 500000, 1000000, 10000000, 50000000, 100000000]
};

export const startDateRange = {
  from: [
    { value: 30, label: '1 month ago' },
    { value: 90, label: '3 months ago' },
    { value: 180, label: '6 months ago' },
    { value: 365, label: '1 year ago' },
    { value: 530, label: '2 years ago' },
    { value: 1460, label: '4 years ago' }
  ],
  to: [
    { value: 30, label: '1 month ago' },
    { value: 90, label: '3 months ago' },
    { value: 180, label: '6 months ago' },
    { value: 365, label: '1 year ago' },
    { value: 530, label: '2 years ago' },
    { value: 1460, label: '4 years ago' }
  ]
};

export const periodRange = {
  min: [0, 25, 50, 75, 100, 200, 300, 400, 500],
  max: [25, 50, 75, 100, 200, 300, 400, 500]
};

export const scoreRange = {
  min: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
  max: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
};

export const InvestmentStageId = {
  ANGEL: 'angel',
  PRE_SEED: 'pre_seed',
  SEED: 'seed',
  SERIES_A: 'series_a',
  SERIES_B: 'series_b',
  SERIES_C: 'series_c',
  SERIES_D: 'series_d',
  SERIES_E: 'series_e',
  SERIES_F: 'series_f',
  SERIES_G: 'series_g',
  SERIES_H: 'series_h',
  SERIES_I: 'series_i',
  SERIES_J: 'series_j',
  CONVERTIBLE_NOTE: 'convertible_note',
  CORPORATE_ROUND: 'corporate_round',
  DEBT_FINANCING: 'debt_financing',
  EQUITY_CROWDFUNDING: 'equity_crowdfunding',
  GRANT: 'grant',
  INITIAL_COIN_OFFERING: 'initial_coin_offering',
  NON_EQUITY_ASSISTANCE: 'non_equity_assistance',
  POST_IPO_DEBT: 'post_ipo_debt',
  POST_IPO_EQUITY: 'post_ipo_equity',
  POST_IPO_SECONDARY: 'post_ipo_secondary',
  PRIVATE_EQUITY: 'private_equity',
  PRODUCT_CROWDFUNDING: 'product_crowdfunding',
  SECONDARY_MARKET: 'secondary_market',
  SERIES_UNKNOWN: 'series_unknown',
  UNDISCLOSED: 'undisclosed'
};

export const investmentStageLabels = Object.entries(InvestmentStageId).map(
  ([key, value]) => ({
    label: capitalizeFirstOfEachWord(
      key.toLocaleLowerCase().replace(/_/g, ' ')
    ),
    value
  })
);

export const talentQualityFilterOptions = [
  { value: 'any', label: 'Any' },
  { value: '90', label: '90th percentile' },
  { value: '80', label: '80th percentile' },
  { value: '70', label: '70th percentile' }
];

export const growthMetricOptions = [
  { value: 'revenue_growth', label: 'Revenue' },
  { value: 'headcount_growth', label: 'Headcount' },
  { value: 'email_invoice_growth', label: 'Email Invoice' },
  { value: 'app_download_growth', label: 'App Downloads' },
  { value: 'app_dau_growth', label: 'App DAU' },
  { value: 'web_traffic_growth', label: 'Web MAU' },
  { value: 'sdk_integration_growth', label: 'SDK Integrations' },
  { value: 'funding_prediction_growth', label: 'Funding Prediction' },
  { value: 'github_ranking_growth', label: 'GitHub Ranking' },
  { value: 'github_star_growth', label: 'GitHub Stars' },
  { value: 'g2crowd_score_growth', label: 'G2 Crowd' },
  { value: 'open_jobs_growth', label: 'Job Openings' },
  { value: noneFilterValue, label: 'None' },
  { value: anyFilterValue, label: 'Any' }
];

export const peopleOrderOptions = [
  { value: 'default', label: 'Default' },
  { value: 'score', label: 'Score' },
  { value: 'current_position_date_from', label: 'Current Start Date' },
  { value: 'last_assigned_date', label: 'Last Assigned Date' }
];

export const fundingRoundOrderOptions = [
  { value: 'announced', label: 'Announced Date' },
  { value: 'created', label: 'Created Date' }
];

export const cryptoOrderOptions = [
  { value: 'github_ranking', label: 'GitHub Ranking' },
  { value: 'github_star_count_growth', label: 'GitHub Star Count Growth' }
];

export const foundingDateFilterOptions = [
  { value: 'any', label: 'Any' },
  { value: '1', label: 'Past year' },
  { value: '2', label: 'Past 2 years' },
  { value: '5', label: 'Past 5 years' },
  { value: '10', label: 'Past 10 years' },
  { value: '15', label: 'Past 15 years' },
  { value: '20', label: 'Past 20 years' }
];

export const flagTypeFilterOptions = [
  { value: EntityType.COMPANY, label: 'Company Traction' },
  { value: EntityType.PERSON, label: 'Person' },
  { value: EntityType.FUNDING_ROUND, label: 'Funding Round' }
];

export const flagTimeFilterOptions = [
  { value: 'thisWeek', label: 'This Week' },
  { value: 'lastWeek', label: 'Last Week' },
  { value: '30', label: 'Last 30 Days' }
];

export const currentPositionTenureFilterOptions = [
  { value: '90-0', label: '0-3 months' },
  { value: '180-90', label: '3-6 months' },
  { value: '365-180', label: '6-12 months' },
  { value: '545-365', label: '12-18 months' },
  { value: '730-545', label: '18-24 months' },
  { value: '1095-730', label: '2-3 years' },
  { value: '1460-1095', label: '3-4 years' },
  { value: '-1460', label: '4+ years' }
];

export const priorPositionEndDateFilterOptions = [
  { value: '90-0', label: '0-3 months' },
  { value: '180-90', label: '3-6 months' },
  { value: '365-180', label: '6-12 months' },
  { value: '545-365', label: '12-18 months' },
  { value: '730-545', label: '18-24 months' },
  { value: '1095-730', label: '2-3 years' },
  { value: '1460-1095', label: '3-4 years' },
  { value: '-1460', label: '4+ years' }
];

export const priorPositionTenureFilterOptions = [
  { value: '0-3', label: '0-3 months' },
  { value: '3-6', label: '3-6 months' },
  { value: '6-12', label: '6-12 months' },
  { value: '12-18', label: '12-18 months' },
  { value: '18-24', label: '18-24 years' },
  { value: '24-36', label: '2-3 years' },
  { value: '36-48', label: '3-4 years' },
  { value: '48-', label: '4+ years' }
];

export const careerTenureFilterOptions = [
  { value: '0-2', label: '0-2 years' },
  { value: '2-5', label: '2-5 years' },
  { value: '5-7', label: '5-7 years' },
  { value: '7-10', label: '7-10 years' },
  { value: '10-15', label: '10-15 years' },
  { value: '15-', label: '15+ years' }
];

export const classificationOptions = [
  { value: 'Regrettable', label: 'Regrettable' },
  { value: 'Unregrettable', label: 'Not Regrettable' },
  { value: 'Portfolio', label: 'Portfolio' }
];

export const UserRole = {
  DEACTIVATED: -1,
  ADMIN: 0,
  GENERAL: 1,
  TEAM: 2
};

export const Activity = {
  HOME: 'home',
  COMPANIES: 'companies',
  COMPANY: 'company',
  PEOPLE: 'people',
  FUNDING_ROUNDS: 'funding_rounds',
  VIEW_WOKEN_COMPANY: 'view_woken_company',
  VIEW_WOKEN_PERSON: 'view_woken_person',
  VIEW_WOKEN_FUNDING_ROUND: 'view_woken_funding_round',
  EXPAND_COMPANY: 'expand_company',
  EXPAND_PERSON: 'expand_person',
  EXPAND_FUNDING_ROUND: 'expand_funding_round',
  EXPAND_CRYPTO: 'expand_crypto',
  EXPAND_FLAG: 'expand_flag',
  ADD_ACTION: 'add_action',
  REMOVE_ACTION: 'remove_action',
  CHANGE_ACTION: 'change_action'
};

export const ActionDetailsType = {
  DAYS: 'days',
  CONTACT: 'contact',
  EVENT: 'event',
  INFO: 'info',
  CLASSIFICATION: 'classification'
};

export const regionOptions = [
  'San Francisco Bay Area',
  'New York City Area',
  'California',
  'United States',
  'Europe',
  'Israel',
  'Canada',
  'India',
  'China',
  'Sweden',
  'Denmark',
  'Norway',
  'Spain',
  'Belgium',
  'United Kingdom',
  'Paris, France',
  'London, United Kingdom',
  'Berlin, Germany',
  'Southeast Asia',
  'Latin America',
  'South Asia',
  'East Asia',
  'Oceania',
  'Africa',
  'Middle East',
  'Los Angeles Area',
  'Chicago Area',
  'Dallas/Fort Worth Area',
  'Philadelphia Area',
  'Houston Area',
  'Washington D.C. Area',
  'Miami Metropolitan Area',
  'Atlanta Area',
  'Boston Area',
  'Detroit Area',
  'Seattle Area',
  'Minneapolis-St. Paul Area',
  'Portland Metropolitan Area',
  'Las Vegas Metropolitan Area',
  'Austin Metropolitan Area',
  'Denver Metropolitan Area',
  'Madison Area',
  'San Diego Area',
  'Charlotte, NC Area',
  'Phoenix, AZ Area',
  'Salt Lake City Area',
  'Raleigh-Durham, NC Area',
  'Indianapolis Area',
  'Nashville Area',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
].map(r => ({ label: r, value: r }));

export const TeamViewType = {
  COMPANIES: 'companies',
  FUNDING_ROUNDS: 'fundingRounds',
  PEOPLE: 'people'
};

export const TeamViewEntityType = {
  FILTER: 'filter'
};

export const TEAM_VIEW_NONE = 'None';

export const ActionType = {
  ASSIGN: 'assign',
  SNOOZE: 'snooze',
  BLOCK: 'block',
  FLAG: 'flag',
  CLASSIFY: 'classify'
};

export const SpecialTeamId = {
  EVERYONE: 'Everyone',
  ME: 'Me'
};

export const SearchIndex = {
  COMPANY: 'company',
  PERSON: 'person',
  INDUSTRY: 'industry',
  LSVP_USER: 'lsvp_user',
  INVESTOR_NAME: 'investor_name',
  REGION: 'region'
};

export const sectorsIc = [
  { id: 'Consumer', name: 'Consumer' },
  { id: 'Crypto', name: 'Crypto' },
  { id: 'Enterprise', name: 'Enterprise' },
  { id: 'Fintech', name: 'Fintech' },
  { id: 'Gaming', name: 'Gaming' },
  { id: 'Healthcare', name: 'Healthcare' },
  { id: 'Other', name: 'Other' }
];

export const projectedRaiseDates = [
  { id: "Q3'23", name: "Q3'23" },
  { id: "Q4'23", name: "Q4'23" },
  { id: "H1'24", name: "H1'24" },
  { id: "H2'24", name: "H2'24" }
];

export const FundingRoundChartAction = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  CLEAR: 'CLEAR'
};

export const Stage = {
  DEALS: 'deals',
  COVERAGE: 'coverage',
  NO_COVERAGE: 'noCoverage',
  CLOSED_WON: 'closedWon',
  CLOSED_LOST: 'closedLost',
  IN_PROCESS: 'inProcess',
  NO_TERM_SHEET: 'noTermSheet'
};

export const StageLabel = {
  [Stage.DEALS]: 'Deals',
  [Stage.COVERAGE]: 'Coverage',
  [Stage.NO_COVERAGE]: 'No Coverage',
  [Stage.CLOSED_WON]: 'Closed Won',
  [Stage.CLOSED_LOST]: 'Closed Lost',
  [Stage.IN_PROCESS]: 'In Process',
  [Stage.NO_TERM_SHEET]: 'No Term Sheet'
};

export const Classification = {
    DEALS: 'deals',
    REGRETTABLE: 'regrettable',
    NOT_REGRETTABLE: 'notRegrettable',
    PORTFOLIO: 'portfolio',
    NOT_DISCUSSED: 'notDiscussed',
    MET: 'met',
    FOLLOWED_UP: 'followedUp',
    NOT_FOLLOWED_UP: 'notFollowedUp'
};

export const ClassificationLabel = {
  [Classification.DEALS]: 'Deals',
  [Classification.REGRETTABLE]: 'Regrettable',
  [Classification.NOT_REGRETTABLE]: 'Not Regrettable',
  [Classification.PORTFOLIO]: 'Portfolio',
  [Classification.NOT_DISCUSSED]: 'Not Discussed',
  [Classification.MET]: 'Met',
  [Classification.FOLLOWED_UP]: 'Followed Up',
  [Classification.NOT_FOLLOWED_UP]: 'Not Followed Up'
};

export const StageKey = _.invert(StageLabel);

export const ClassificationKey = _.invert(ClassificationLabel);

export const FlagState = {
  NEW: 'New',
  ACCEPTED: 'Accepted',
  TRACKING: 'Tracked',
  REJECTED: 'Rejected',
  PORTFOLIO: 'Portfolio',
  REACHED_OUT: 'Reached Out',
  MET: 'Meeting',
  FUNDING_EVENT: 'Funding Event'
};

export const FlagStateName = {
  [FlagState.NEW]: 'NEW',
  [FlagState.ACCEPTED]: 'ACCEPTED',
  [FlagState.TRACKING]: 'TRACKING',
  [FlagState.REJECTED]: 'REJECTED',
  [FlagState.PORTFOLIO]: 'PORTFOLIO',
  [FlagState.REACHED_OUT]: 'REACHED OUT',
  [FlagState.MET]: 'MEETING',
  [FlagState.FUNDING_EVENT]: 'FUNDING EVENT'
};

export const flagStateFilterOptions = [
  { value: FlagState.NEW, label: 'New' },
  { value: FlagState.ACCEPTED, label: 'Accepted' },
  { value: FlagState.REJECTED, label: 'Rejected' },
  { value: FlagState.PORTFOLIO, label: 'Portfolio' }
];

export const flagInteractionFilterOptions = [
  { value: FlagState.REACHED_OUT, label: 'Reached Out' }
];

export const flagMyFilterOptions = [
  { value: 'myTeams', label: 'My Teams' },
  { value: 'myFlags', label: 'My Flags' }
];

export const noFundingFilterOptions = [
  { value: '1', label: 'None in the last month' },
  { value: '6', label: 'None in the last 6 months' },
  { value: '9', label: 'None in the last 9 months' },
  { value: '12', label: 'None in the last 12 months' },
  { value: '18', label: 'None in the last 18 months' },
  { value: '24', label: 'None in the last 24 months' }
];

export const sectorIcFilterOptions = sectorsIc.map(({ id, name }) => ({ value: id, label: name }));

export const periodOptions = [
  { value: 'revenue_mom_growth', label: 'Revenue MoM Growth' },
  { value: 'revenue_qoq_growth', label: 'Revenue QoQ Growth' },
  { value: 'revenue_yoy_growth', label: 'Revenue YoY Growth' },
  { value: 'revenue_volume_mom_growth', label: 'Revenue Volume MoM Growth' },
  { value: 'revenue_volume_qoq_growth', label: 'Revenue Volume QoQ Growth' },
  { value: 'revenue_volume_yoy_growth', label: 'Revenue Volume YoY Growth' },
  { value: 'headcount_mom_growth', label: 'Headcount MoM Growth' },
  { value: 'headcount_qoq_growth', label: 'Headcount QoQ Growth' },
  { value: 'headcount_yoy_growth', label: 'Headcount YoY Growth' },
  { value: 'web_traffic_wow_growth', label: 'Web Traffic WoW Growth' },
  { value: 'web_traffic_mom_growth', label: 'Web Traffic MoM Growth' },
  { value: 'web_traffic_qoq_growth', label: 'Web Traffic QoQ Growth' },
  { value: 'web_traffic_yoy_growth', label: 'Web Traffic YoY Growth' },
  { value: 'app_dau_wow_growth', label: 'App DAU WoW Growth' },
  { value: 'app_dau_mom_growth', label: 'App DAU MoM Growth' },
  { value: 'app_dau_qoq_growth', label: 'App DAU QoQ Growth' },
  { value: 'app_dau_yoy_growth', label: 'App DAU YoY Growth' },
  { value: 'app_downloads_wow_growth', label: 'App Downloads WoW Growth' },
  { value: 'app_downloads_mom_growth', label: 'App Downloads MoM Growth' },
  { value: 'app_downloads_qoq_growth', label: 'App Downloads QoQ Growth' },
  { value: 'app_downloads_yoy_growth', label: 'App Downloads YoY Growth' },
  { value: 'email_invoice_mom_growth', label: 'Email Invoice MoM Growth' },
  { value: 'email_invoice_qoq_growth', label: 'Email Invoice QoQ Growth' },
  { value: 'email_invoice_yoy_growth', label: 'Email Invoice YoY Growth' }
];

export const PeriodShortName = {
  'app_dau_wow_growth': 'App DAU WoW',
  'app_dau_mom_growth': 'App DAU MoM',
  'app_dau_qoq_growth': 'App DAU QoQ',
  'app_dau_yoy_growth': 'App DAU YoY',
  'app_downloads_wow_growth': 'App Downloads WoW',
  'app_downloads_mom_growth': 'App Downloads MoM',
  'app_downloads_qoq_growth': 'App Downloads QoQ',
  'app_downloads_yoy_growth': 'App Downloads YoY',
  'email_invoice_mom_growth': 'Email Invoice MoM',
  'email_invoice_qoq_growth': 'Email Invoice QoQ',
  'email_invoice_yoy_growth': 'Email Invoice YoY',
  'headcount_mom_growth': 'Headcount MoM',
  'headcount_qoq_growth': 'Headcount QoQ',
  'headcount_yoy_growth': 'Headcount YoY',
  'revenue_mom_growth': 'Revenue MoM',
  'revenue_qoq_growth': 'Revenue QoQ',
  'revenue_yoy_growth': 'Revenue YoY',
  'revenue_volume_mom_growth': 'Revenue Volume MoM',
  'revenue_volume_qoq_growth': 'Revenue Volume QoQ',
  'revenue_volume_yoy_growth': 'Revenue Volume YoY',
  'web_traffic_wow_growth': 'Web Traffic WoW',
  'web_traffic_mom_growth': 'Web Traffic MoM',
  'web_traffic_qoq_growth': 'Web Traffic QoQ',
  'web_traffic_yoy_growth': 'Web Traffic YoY'
};

export const InteractionType = {
  MEETING: 0,
  CALL: 1,
  MESSAGE: 2,
  EMAIL: 3
};

export const Direction = {
  SENT: 0,
  RECEIVED: 1
};

export const Role = {
  FROM: 'from',
  TO: 'to',
  CC: 'cc'
};

export const InteractionName = {
  [InteractionType.MEETING]: 'Meeting',
  [InteractionType.CALL]: 'Call',
  [InteractionType.MESSAGE]: 'Message',
  [InteractionType.EMAIL]: 'Email'
};

export const ALL = 'all';

export const allFilterOption = { value: ALL, label: 'All' };

export const INTERACTION_EMAIL = 'atlas_interactions';

export const FLAG_REACH_OUT_MAX_DAYS = 30;
export const FLAG_MEET_MAX_DAYS = 90;

export const LandingType = {
  FLAG: 'flag'
};

export const PipelineOrder = {
  DEFAULT: 'default',
  GROWTH: 'growth',
  DATE_ADDED: 'date_added'
};

export const pipelineOrderOptions = [
  { label: 'Default', value: PipelineOrder.DEFAULT },
  { label: 'Growth', value: PipelineOrder.GROWTH },
  { label: 'Date Added', value: PipelineOrder.DATE_ADDED }
];

export const recencyOptions = [
  { value: '5', label: '5 days' },
  { value: '20', label: '20 days' },
  { value: 'any', label: 'Any' }
];

export const directionOptions = [
  { value: 'descending', label: 'Descending' },
  { value: 'ascending', label: 'Ascending' }
];

export const DestinationType = {
  TEAM: 'team',
  PERSON: 'person'
};

export const growthMetricOptionDrilldown = {
  'revenue_growth': [
    { value: 'revenue_growth', label: 'All Time' },
    { value: 'revenue_post_meeting_atlas', label: 'Since Last Meeting' },
    { value: 'revenue_1mo_atlas', label: 'Last 30 Days' },
    { value: 'revenue_3mo_atlas', label: 'Last 90 Days' },
    { value: 'revenue_1ye_atlas', label: 'Last 365 Days' },
    { value: 'revenue_volume_post_meeting_atlas', label: 'Volume Since Last Meeting' },
    { value: 'revenue_volume_1mo_atlas', label: 'Volume Last 30 Days' },
    { value: 'revenue_volume_3mo_atlas', label: 'Volume Last 90 Days' },
    { value: 'revenue_volume_1ye_atlas', label: 'Volume Last 365 Days' }
  ],
  'headcount_growth': [
    { value: 'headcount_growth', label: 'All Time' },
    { value: 'headcount_post_meeting_atlas', label: 'Since Last Meeting' },
    { value: 'headcount_1mo_atlas', label: 'Last 30 Days' },
    { value: 'headcount_3mo_atlas', label: 'Last 90 Days' },
    { value: 'headcount_1ye_atlas', label: 'Last 365 Days' }
  ],
  'email_invoice_growth': [
    { value: 'email_invoice_growth', label: 'All Time' },
    { value: 'email_invoice_post_meeting_atlas', label: 'Since Last Meeting' },
    { value: 'email_invoice_1mo_atlas', label: 'Last 30 Days' },
    { value: 'email_invoice_3mo_atlas', label: 'Last 90 Days' },
    { value: 'email_invoice_1ye_atlas', label: 'Last 365 Days' }
  ],
  'app_download_growth': [
    { value: 'app_download_growth', label: 'All Time' },
    { value: 'app_downloads_post_meeting_atlas', label: 'Since Last Meeting' },
    { value: 'app_downloads_1we_atlas', label: 'Last 7 Days' },
    { value: 'app_downloads_1mo_atlas', label: 'Last 30 Days' },
    { value: 'app_downloads_3mo_atlas', label: 'Last 90 Days' },
    { value: 'app_downloads_1ye_atlas', label: 'Last 365 Days' }
  ],
  'app_dau_growth': [
    { value: 'app_dau_growth', label: 'All Time' },
    { value: 'app_dau_post_meeting_atlas', label: 'Since Last Meeting' },
    { value: 'app_dau_1we_atlas', label: 'Last 7 Days' },
    { value: 'app_dau_1mo_atlas', label: 'Last 30 Days' },
    { value: 'app_dau_3mo_atlas', label: 'Last 90 Days' },
    { value: 'app_dau_1ye_atlas', label: 'Last 365 Days' }
  ],
  'web_traffic_growth': [
    { value: 'web_traffic_growth', label: 'All Time' },
    { value: 'web_traffic_post_meeting_atlas', label: 'Since Last Meeting' },
    { value: 'web_mau_1mo_atlas', label: 'Last 30 Days' },
    { value: 'web_mau_3mo_atlas', label: 'Last 90 Days' },
    { value: 'web_mau_1ye_atlas', label: 'Last 365 Days' }
  ]
};

const makeGrowthMetricMap = () => {
  const map = {};
  for (const [top, sub] of Object.entries(growthMetricOptionDrilldown)) {
    for (const { value } of sub) {
      map[value] = top;
    }
  }
  return map;
};

export const growthMetricMap = makeGrowthMetricMap();

export const growthMetricShortNames = {
  'revenue_growth': 'All Time Revenue',
  'headcount_growth': 'All Time Headcount',
  'email_invoice_growth': 'All Time E-Invoice',
  'app_download_growth': 'All Time App Down',
  'app_dau_growth': 'All Time App DAU',
  'web_traffic_growth': 'All Time Web MAU',
  'sdk_integration_growth': 'SDK Integrations',
  'funding_prediction_growth': 'Funding Prediction',
  'github_ranking_growth': 'GitHub Ranking',
  'github_star_growth': 'GitHub Stars',
  'g2crowd_score_growth': 'G2 Crowd',
  'open_jobs_growth': 'Job Openings',
  'revenue_post_meeting_atlas': 'Revenue Since Met',
  'revenue_1mo_atlas': 'Revenue 30 Days',
  'revenue_3mo_atlas': 'Revenue 90 Days',
  'revenue_1ye_atlas': 'Revenue 365 Days',
  'revenue_volume_post_meeting_atlas': 'Rev Vol Since Met',
  'revenue_volume_1mo_atlas': 'Rev Vol 30 Days',
  'revenue_volume_3mo_atlas': 'Rev Vol 90 Days',
  'revenue_volume_1ye_atlas': 'Rev Vol 365 Days',
  'headcount_post_meeting_atlas': 'Headcount Since Met',
  'headcount_1mo_atlas': 'Headcount 30 Days',
  'headcount_3mo_atlas': 'Headcount 90 Days',
  'headcount_1ye_atlas': 'Headcount 365 Days',
  'email_invoice_post_meeting_atlas': 'E-Invoice Since Met',
  'email_invoice_1mo_atlas': 'E-Invoice 30 Days',
  'email_invoice_3mo_atlas': 'E-Invoice 90 Days',
  'email_invoice_1ye_atlas': 'E-Invoice 365 Days',
  'app_downloads_post_meeting_atlas': 'App Down Since Met',
  'app_downloads_1we_atlas': 'App Down 7 Days',
  'app_downloads_1mo_atlas': 'App Down 30 Days',
  'app_downloads_3mo_atlas': 'App Down 90 Days',
  'app_downloads_1ye_atlas': 'App Down 365 Days',
  'app_dau_post_meeting_atlas': 'App DAU Since Met',
  'app_dau_1we_atlas': 'App DAU 7 Days',
  'app_dau_1mo_atlas': 'App DAU 30 Days',
  'app_dau_3mo_atlas': 'App DAU 90 Days',
  'app_dau_1ye_atlas': 'App DAU 365 Days',
  'web_traffic_post_meeting_atlas': 'Web MAU Since Met',
  'web_mau_1mo_atlas': 'Web MAU 30 Days',
  'web_mau_3mo_atlas': 'Web MAU 90 Days',
  'web_mau_1ye_atlas': 'Web MAU 365 Days',
  [noneFilterValue]: 'None',
  [anyFilterValue]: 'Any'
};
