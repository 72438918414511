import { FlagListAction } from '../../types/flag/list';

export const getFlagList = payload => ({
  type: FlagListAction.GET_FLAG_LIST,
  payload
});

export const setFlagList = ({ flags, count }) => ({
  type: FlagListAction.SET_FLAG_LIST,
  payload: { flags, count }
});

export const getMoreFlagList = payload => ({
  type: FlagListAction.GET_MORE_FLAG_LIST,
  payload
});

export const setMoreFlagList = ({ flags, message }) => ({
  type: FlagListAction.SET_MORE_FLAG_LIST,
  payload: { flags, message }
});

export const updateFlagList = flags => ({
  type: FlagListAction.UPDATE_FLAG_LIST,
  payload: { flags }
});

export const getFlagCount = payload => ({
  type: FlagListAction.GET_FLAG_COUNT,
  payload
});

export const setFlagCount = payload => ({
  type: FlagListAction.SET_FLAG_COUNT,
  payload
});

export const downloadFlagCSVAction = (downloadResolver, params = {}) => ({
  type: FlagListAction.DOWNLOAD_FLAG_CSV,
  payload: {
    downloadResolver,
    params
  }
});
