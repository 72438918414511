/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Popover,
  Input,
  Button
} from 'antd';
import { EntityType } from '../../Utils/Constants';
import { setCrmId } from '../../Store/actions/crm/id';
import styles from './AffinityId.module.scss';

export default ({ id, entityType, crmEntityId}) => {
  const [visible, setVisible] = useState();
  const [value, setValue] = useState();
  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(setCrmId({
      id,
      entityType,
      crmEntityId: value
    }));
    setVisible(false);
  };

  const popoverContent = (
    <div className={styles.popoverContent}>
      <Input
        className={styles.value}
        value={value}
        onChange={event => setValue(event.target.value)}
      />
      <div className={styles.buttons}>
        <Button 
          className={styles.cancel}
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button 
          type="primary"
          onClick={() => onSave()}
        >
          Save
        </Button>
      </div>
    </div>
  );

  const onVisibleChange = visible => {
    if (visible) { setValue(crmEntityId); }
    setVisible(visible);
  };

  return (
    <div className={styles.affinityId}>
      <div className={styles.label}>Affinity ID</div>
      <div className={styles.valueRow}>
        {crmEntityId ? (
          <a
            className={styles.link}
            href={`https://lsvp.affinity.co/${entityType === EntityType.COMPANY ? `companies/` : 'persons/'}${crmEntityId}`}
            target="affinity"
          >
            {crmEntityId}
          </a> 
        ) : (
          <div className={styles.na}>N/A</div>
        )}
        <Popover
          content={popoverContent}
          trigger="click"
          placement="topRight"
          visible={visible}
          onVisibleChange={onVisibleChange}
        >
          <a
            className={`${styles.link} ${styles.editLink}`}
            href="#"
          >
            Edit
          </a>
        </Popover>
      </div>
    </div>
  );
};