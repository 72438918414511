import React from 'react';
import {
  filterKeys,
  gitHubRankingFilterOptions,
  gitHubScoreFilterOptions,
  lastInteractionFilterOptions,
  statusFilterOptions,
  regionOptions,
  peopleTitleOptions,
  currentStatusFilterOptions,
  talentQualityFilterOptions,
  foundingDateFilterOptions,
  flagTypeFilterOptions,
  periodOptions,
  currentPositionTenureFilterOptions,
  priorPositionEndDateFilterOptions,
  priorPositionTenureFilterOptions,
  careerTenureFilterOptions,
  noFundingFilterOptions,
  sectorIcFilterOptions,
  investmentStageLabels,
  fundingRange,
  daysSinceAnnouncementRange,
  roundAmountRange,
  postMoneyRange,
  employeeCountRange,
  monthlyRevenueRange,
  appDauRange,
  webTrafficRange,
  scoreRange,
  periodRange,
  SearchIndex
} from '../../Utils/Constants';
import FilterSearchAPI from '../../Network/Apis/filterSearch';
import SelectControls from './SelectControls';
import CheckboxControls from './CheckboxControls';
import TextControls from './TextControls';
import RangeControls from './RangeControls';
import PositionControls from './PositionControls';
import PartnerInteractionControls from './PartnerInteractionControls';
import InteractionRelativeToPositionControls from './InteractionRelativeToPositionControls';
import GrowthMetricControls from './GrowthMetricControls';
import CompanyListApi from '../../Network/Apis/company/list';

const investorSearch = {
  searchFunction: FilterSearchAPI.searchByKeyword,
  params: { index: SearchIndex.INVESTOR_NAME }
};
const industrySearch = {
  searchFunction: FilterSearchAPI.searchByKeyword,
  params: { index: SearchIndex.INDUSTRY }
};
const userSearch = {
  searchFunction: FilterSearchAPI.searchByKeyword,
  params: { index: SearchIndex.LSVP_USER }
};
const companySearch = {
  searchFunction: CompanyListApi.getCompaniesList,
  dataAccessor: results => results.data.data.companies
};

const toInvestorOption = ({ label, value }) => ({
  label: value.startsWith('c-') ? `${label} (${value.slice(2)})` : label,
  value
});

const fromInvestorOption = ({ label, value }) => ({
  label: label.replace(` (${value.slice(2)})`, ''),
  value
});

const toCompanyOption = ({ label, value }) => ({
  label: `${label} (${value})`,
  value
});

const fromCompanyOption = ({ label, value }) => ({
  label: label.replace(` (${value})`, ''),
  value
});

export default ({
  filters,
  filterKey,
  staticOptions,
  visible,
  addToFilters
}) => ({
  [filterKeys.acquirer]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Acquirer"
      search={companySearch}
      toOption={toCompanyOption}
      fromOption={fromCompanyOption}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDau]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={appDauRange}
      label="App DAU"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDauWoW]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App DAU WoW Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDauMoM]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App DAU MoM Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDauQoQ]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App DAU QoQ Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDauYoY]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App DAU YoY Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDownloadsWoW]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App Donwloads WoW Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDownloadsMoM]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App Donwloads MoM Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDownloadsQoQ]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App Donwloads QoQ Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.appDownloadsYoY]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="App Donwloads YoY Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.careerTenure]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={careerTenureFilterOptions}
      label="Career Tenure"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.companyDescription]: (
    <TextControls
      filters={filters}
      filterKey={filterKey}
      label="Company Description"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.companyId]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Specific Company"
      search={companySearch}
      toOption={toCompanyOption}
      fromOption={fromCompanyOption}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.companyName]: (
    <TextControls
      filters={filters}
      filterKey={filterKey}
      label="Company Name"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.crmList]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={staticOptions}
      label="Affinity List"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.cryptoName]: (
    <TextControls
      filters={filters}
      filterKey={filterKey}
      label="Name"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.currentInvestor]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Current Investor"
      search={investorSearch}
      toOption={toInvestorOption}
      fromOption={fromInvestorOption}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.currentPosition]: (
    <PositionControls
      filters={filters}
      positionFilterKey={filterKey}
      seniorityFilter
      nonOperationalFilter
      label="Current Position"
      visible={visible}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.currentPositionAfterInteraction]: (
    <InteractionRelativeToPositionControls
      filters={filters}
      filterKey={filterKey}
      label="Current Position Started After Partner Interaction"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.currentPositionAfterMeeting]: (
    <InteractionRelativeToPositionControls
      filters={filters}
      filterKey={filterKey}
      label="Current Position Started After Partner Meeting"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.daysSinceAnnouncement]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={daysSinceAnnouncementRange}
      label="Days Since Announcement"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.education]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={staticOptions}
      label="Education"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.emailInvoiceMoM]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Email Invoice MoM Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.emailInvoiceQoQ]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Email Invoice QoQ Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.emailInvoiceYoY]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Email Invoice YoY Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.employeeCount]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={employeeCountRange}
      label="Employee Count"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.endDate]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={priorPositionEndDateFilterOptions}
      label="Prior Position End Date"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.entityType]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={flagTypeFilterOptions}
      label="Flag Type"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.flagId]: (
    <TextControls
      filters={filters}
      filterKey={filterKey}
      label="Flag ID"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.flagName]: (
    <TextControls
      filters={filters}
      filterKey={filterKey}
      label="Flag Name"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.flagOwner]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Owner"
      search={userSearch}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.foundingDate]: (
    <SelectControls
      filters={filters}
      filterKey={filterKey}
      staticOptions={foundingDateFilterOptions}
      label="Founding Date"
      orNone
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.funding]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={fundingRange}
      label="Funding"
      orNone
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.gitHubRanking]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={gitHubRankingFilterOptions}
      label="GitHub Ranking"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.gitHubScore]: (
    <SelectControls
      filters={filters}
      filterKey={filterKey}
      staticOptions={gitHubScoreFilterOptions}
      label="GitHub Rank"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.growth]: (
    <GrowthMetricControls
      filters={filters}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.headcountMoM]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Headcount MoM Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.headcountQoQ]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Headcount QoQ Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.headcountYoY]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Headcount YoY Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.hidePortfolio]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Hide Portfolio"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.highlights]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={staticOptions}
      label="Highlights"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.industry]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Industry"
      search={industrySearch}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.interactionAfterCurrentPosition]: (
    <InteractionRelativeToPositionControls
      filters={filters}
      filterKey={filterKey}
      label="Partner Interaction After Current Position Change"
      orNone
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.investor]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Investor"
      search={investorSearch}
      toOption={toInvestorOption}
      fromOption={fromInvestorOption}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.lastInteraction]: (
    <SelectControls
      mode="tags"
      filters={filters}
      filterKey={filterKey}
      staticOptions={lastInteractionFilterOptions}
      label="Last Interaction"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.leadInvestor]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Lead Investor"
      search={investorSearch}
      toOption={toInvestorOption}
      fromOption={fromInvestorOption}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.meetingAfterCurrentPosition]: (
    <InteractionRelativeToPositionControls
      filters={filters}
      filterKey={filterKey}
      label="Partner Meeting After Current Position Change"
      orNone
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.monthlyRevenue]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={monthlyRevenueRange}
      label="Monthly Revenue"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.noFunding]: (
    <SelectControls
      mode="tags"
      filters={filters}
      filterKey={filterKey}
      staticOptions={noFundingFilterOptions}
      label="No Recent Funding"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.nonNotable]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Show Non-Notable"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.notableAngel]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Notable Angel"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.otherExperience]: (
    <PositionControls
      filters={filters}
      positionFilterKey={filterKey}
      label="Other Experience"
      visible={visible}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.owner]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Owner"
      search={userSearch}
      orNone
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.partnerInteraction]: (
    <PartnerInteractionControls
      filters={filters}
      filterKey={filterKey}
      label="Partner Interaction"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.period]: (
    <SelectControls
      filters={filters}
      filterKey={filterKey}
      staticOptions={periodOptions}
      label="Order by Period Growth"
      anyKey="never"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.personName]: (
    <TextControls
      filters={filters}
      filterKey={filterKey}
      label="Name"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.postMoney]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={postMoneyRange}
      label="Post-Money"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.previousInvestor]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Previous Investor"
      search={investorSearch}
      toOption={toInvestorOption}
      fromOption={fromInvestorOption}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.priorPositionTenure]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={priorPositionTenureFilterOptions}
      label="Prior Position Tenure"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.prominentInvestor]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      label="Prominent Investor"
      search={investorSearch}
      toOption={toInvestorOption}
      fromOption={fromInvestorOption}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.priorPosition]: (
    <PositionControls
      filters={filters}
      positionFilterKey={filterKey}
      seniorityFilter
      nonOperationalFilter
      label="Prior Position"
      visible={visible}
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.region]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={regionOptions}
      label="Region"
      orNone
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.revenueMoM]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Revenue MoM Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.revenueQoQ]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Revenue QoQ Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.revenueYoY]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Revenue YoY Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.revenueVolumeMoM]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Revenue Volume MoM Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.revenueVolumeQoQ]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Revenue Volume QoQ Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.revenueVolumeYoY]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Revenue Volume YoY Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.roundAmount]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={roundAmountRange}
      label="Round Amount"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.roundName]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={investmentStageLabels}
      label="Round"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.score]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={scoreRange}
      label="Score"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.sector]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={staticOptions}
      label="Sector"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.sectorIc]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={sectorIcFilterOptions}
      label="Sector IC"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.showBlocked]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Show Blocked"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.showAllQuarters]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Show All Quarters"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.showExited]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Show Acquired/IPO/PE"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.showSnoozed]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Show Snoozed"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.stage]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={investmentStageLabels}
      label="Stage"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.startDate]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={currentPositionTenureFilterOptions}
      label="Current Position Tenure"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.status]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={statusFilterOptions}
      label="Status"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.stealth]: (
    <CheckboxControls
      filters={filters}
      filterKey={filterKey}
      label="Currently in Stealth"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.talentQuality]: (
    <SelectControls
      filters={filters}
      filterKey={filterKey}
      staticOptions={talentQualityFilterOptions}
      label="Talent Quality"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.team]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={staticOptions}
      label="Team"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.title]: (
    <SelectControls
      mode="multiple"
      filters={filters}
      filterKey={filterKey}
      staticOptions={peopleTitleOptions}
      label="Title"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.timeInCurrentStatus]: (
    <SelectControls
      filters={filters}
      filterKey={filterKey}
      staticOptions={currentStatusFilterOptions}
      label="Time in Current Status"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.valuation]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={postMoneyRange}
      label="Valuation"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.webTraffic]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={webTrafficRange}
      label="Web MAU"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.webTrafficWoW]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Web Traffic WoW Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.webTrafficMoM]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Web Traffic MoM Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.webTrafficQoQ]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Web Traffic QoQ Growth %"
      addToFilters={addToFilters}
    />
  ),
  [filterKeys.webTrafficYoY]: (
    <RangeControls
      filters={filters}
      filterKey={filterKey}
      range={periodRange}
      label="Web Traffic YoY Growth %"
      addToFilters={addToFilters}
    />
  )
}[filterKey]);
