import { LsvpUserAction } from '../../types/lsvp-user';

export const assignLSVPUsersToEntity = payload => ({
  type: LsvpUserAction.ASSIGN_LSVP_USERS_TO_ENTITY,
  payload
});

export const unassignLSVPUsersFromEntity = payload => ({
  type: LsvpUserAction.UNASSIGN_LSVP_USERS_FROM_ENTITY,
  payload
});

export const localAssignLSVPUsersToEntity = payload => ({
  type: LsvpUserAction.LOCAL_ASSIGN_LSVP_USERS_TO_ENTITY,
  payload
});

export const localUnassignLSVPUsersFromEntity = payload => ({
  type: LsvpUserAction.LOCAL_UNASSIGN_LSVP_USERS_FROM_ENTITY,
  payload
});
