import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/company/details';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CompanyDetailsAction } from '../../types/company/details';
import {
  updateCompanyOwnerAction,
  setCompanyLeaderships,
  setCompanyCompetitors,
  setCompanyFundingRounds,
  setCompanyTopRatedEmployees,
  setCompanyMoreTopRatedEmployees,
  setCompanyGraph,
  setCompetitorsGraph,
  setCompanyGraphTypes,
  localUpdateCompanySectorIcAction,
  localUpdateCompanyProjectedRaiseDateAction,
  localUpdateCompanyFinancialsAction,
  localUpdateCompanyNextStepAction,
  localUpdateCompanyStatusAction,
  localUpdateCompanyUrgentAction,
} from '../../actions/company/details';
import updateLoader from '../../actions/Loader';

export function* companyOwner({ payload }) {
  try {
    const { companyId } = payload;
    const response = yield call(API.getCompanyOwner, companyId);
    yield put(updateCompanyOwnerAction(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* changeCompanySector({ payload }) {
  try {
    const { sectorId, companyId } = payload;
    yield call(API.changeCompanySector, sectorId, companyId);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* fetchLeadership({ payload }) {
  try {
    yield put(updateLoader({ loadingLeaderships: true }));
    const { companyId } = payload;
    const res = yield call(API.leaderships, companyId);
    if (res?.data?.data)
      yield put(
        setCompanyLeaderships(
          res?.data?.data?.leadership,
          res?.data?.data?.count
        )
      );
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ loadingLeaderships: false }));
  }
}

function* fetchCompetitors({ payload }) {
  try {
    yield put(updateLoader({ loadingCompetitors: true }));
    const { companyId } = payload;
    const res = yield call(API.competitors, companyId);
    if (res?.data?.data)
      yield put(
        setCompanyCompetitors(
          res?.data?.data?.competitors,
          res?.data?.data?.count
        )
      );
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ loadingCompetitors: false }));
  }
}

function* addCompanyCompetitor({ payload: { companyId, competitorId } }) {
  try {
    yield call(API.addCompanyCompetitor, companyId, competitorId);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* removeCompanyCompetitor({ payload: { companyId, competitorId } }) {
  try {
    yield call(API.removeCompanyCompetitor, companyId, competitorId);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* fetchFundingRounds({ payload }) {
  try {
    yield put(updateLoader({ loadingFundingrounds: true }));
    const { companyId } = payload;
    const res = yield call(API.fundingRounds, companyId);
    if (res?.data?.data) yield put(setCompanyFundingRounds(res?.data?.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ loadingFundingrounds: false }));
  }
}

function* fetchTopRatedEmployees({ payload }) {
  try {
    yield put(updateLoader({ loadingTopRatedEmployees: true }));
    const res = yield call(API.topRatedEmployees, payload);
    if (res?.data?.data)
      yield put(
        setCompanyTopRatedEmployees(
          res?.data?.data?.people,
          res?.data?.data?.count
        )
      );
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ loadingTopRatedEmployees: false }));
  }
}

function* fetchMoreTopRatedEmployees({ payload }) {
  try {
    const res = yield call(API.topRatedEmployees, payload);
    if (res?.data?.data)
      yield put(setCompanyMoreTopRatedEmployees(res?.data?.data?.people));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* fetchCompanyGraph({ payload }) {
  try {
    const { companyId, graphParams } = payload;
    const res = yield call(API.graph, companyId, graphParams);

    if (res?.data?.data) {
      yield put(
        setCompanyGraph(graphParams.graphKey, res?.data?.data?.graphData)
      );
    }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* fetchCompetitorsGraph({ payload }) {
  try {
    const { competitorsIds, graphParams } = payload;
    const response = {};

    for (const id of competitorsIds) {
      const res = yield call(API.graph, id, graphParams);
      if (res?.data?.data) {
        response[id] = res?.data?.data?.graphData;
      }
    }

    yield put(setCompetitorsGraph(graphParams.graphKey, response));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* fetchCompanyGraphTypes({ payload }) {
  try {
    const { companyId, graphDimension } = payload;

    const response = yield call(API.graphTypes, companyId, graphDimension);
    yield put(setCompanyGraphTypes(response.data.data?.graphTypes));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* updateCompanySectorIc({ payload }) {
  try {
    const { companyId, newSectorIc } = payload;
    yield call(API.updateCompanySectorIc, companyId, newSectorIc);
    yield put(localUpdateCompanySectorIcAction(companyId, newSectorIc));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* updateCompanyProjectedRaiseDate({ payload }) {
  try {
    const { companyId, newProjectedRaiseDate } = payload;
    yield call(API.updateCompanyProjectedRaiseDate, companyId, newProjectedRaiseDate);
    yield put(localUpdateCompanyProjectedRaiseDateAction(companyId, newProjectedRaiseDate));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* updateCompanyFinancials({ payload }) {
  try {
    const { companyId, newFinancials } = payload;
    yield call(API.updateCompanyFinancials, companyId, newFinancials);
    yield put(localUpdateCompanyFinancialsAction(companyId, newFinancials));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* updateCompanyNextStep({ payload }) {
  try {
    const { companyId, newNextStep } = payload;
    yield call(API.updateCompanyNextStep, companyId, newNextStep);
    yield put(localUpdateCompanyNextStepAction(companyId, newNextStep));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* updateCompanyStatus({ payload }) {
  try {
    const { companyId, newStatus } = payload;
    yield put(updateLoader({ statusModalLoading: true }));
    yield call(API.updateCompanyStatus, companyId, newStatus);
    yield put(localUpdateCompanyStatusAction(companyId, newStatus));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ statusModalLoading: false }));
  }
}

export function* updateCompanyUrgent({ payload }) {
  try {
    const { companyId, newUrgent } = payload;
    yield call(API.updateCompanyUrgent, companyId, newUrgent);
    yield put(localUpdateCompanyUrgentAction(companyId, newUrgent));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* companyDetailsSaga() {
  yield takeLatest(
    CompanyDetailsAction.FETCH_COMPANY_OWNER,
    companyOwner
  );
  yield takeLatest(
    CompanyDetailsAction.CHANGE_COMPANY_SECTOR,
    changeCompanySector
  );
  yield takeLatest(
    CompanyDetailsAction.UPDATE_COMPANY_STATUS,
    updateCompanyStatus
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPANY_LEADERSHIP,
    fetchLeadership
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPANY_COMPETITORS,
    fetchCompetitors
  );
  yield takeLatest(
    CompanyDetailsAction.ADD_COMPANY_COMPETITOR,
    addCompanyCompetitor
  );
  yield takeLatest(
    CompanyDetailsAction.REMOVE_COMPANY_COMPETITOR,
    removeCompanyCompetitor
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPANY_FUNDING_ROUNDS,
    fetchFundingRounds
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPANY_TOP_RATED_EMPLOYEES,
    fetchTopRatedEmployees
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPANY_MORE_TOP_RATED_EMPLOYEES,
    fetchMoreTopRatedEmployees
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPANY_GRAPH,
    fetchCompanyGraph
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPETITORS_GRAPH,
    fetchCompetitorsGraph
  );
  yield takeLatest(
    CompanyDetailsAction.GET_COMPANY_GRAPH_TYPES,
    fetchCompanyGraphTypes
  );
  yield takeLatest(
    CompanyDetailsAction.UPDATE_COMPANY_SECTOR_IC,
    updateCompanySectorIc
  );
  yield takeLatest(
    CompanyDetailsAction.UPDATE_COMPANY_PROJECTED_RAISE_DATE,
    updateCompanyProjectedRaiseDate
  );
  yield takeLatest(
    CompanyDetailsAction.UPDATE_COMPANY_FINANCIALS,
    updateCompanyFinancials
  );
  yield takeLatest(
    CompanyDetailsAction.UPDATE_COMPANY_NEXT_STEP,
    updateCompanyNextStep
  );
  yield takeLatest(
    CompanyDetailsAction.UPDATE_COMPANY_URGENT,
    updateCompanyUrgent
  );
}
