const Numbers = {
  THOUSAND: Math.pow(10, 3),
  HUNDRED_THOUSAND: Math.pow(10, 5),
  MILLION: Math.pow(10, 6),
  BILLION: Math.pow(10, 9),
  TRILLION: Math.pow(10, 12)
};

const NumberAbbreviation = {
  THOUSAND: 'K',
  MILLION: 'M',
  BILLION: 'B',
  TRILLION: 'T'
};

const Units = Object.keys(NumberAbbreviation).reduce((reversed, key) => {
  reversed[NumberAbbreviation[key]] = key;
  return reversed;
}, {});

export function formatNumber({
  amount,
  currency = 'USD',
  abbreviated = true,
  appendCurrency = false,
  precision = 0,
  formatThousand = true
}) {
  const formatterOptions = appendCurrency
    ? {
        style: 'currency',
        currency: currency || 'USD',
        minimumFractionDigits: 0
      }
    : { minimumFractionDigits: 0 };

  const formatter = new Intl.NumberFormat(Intl.locale, formatterOptions);

  let formattedNumber;

  if (abbreviated) {
    const sign = amount < 0 ? -1 : 1;
    const absoluteAmount = Math.abs(amount);

    let abbreviatedAmount = absoluteAmount;
    let numberAbbreviation = '';

    if (absoluteAmount >= Numbers.TRILLION) {
      abbreviatedAmount /= Numbers.TRILLION;
      numberAbbreviation = NumberAbbreviation.TRILLION;
    } else if (absoluteAmount >= Numbers.BILLION) {
      abbreviatedAmount /= Numbers.BILLION;
      numberAbbreviation = NumberAbbreviation.BILLION;
    } else if (absoluteAmount >= Numbers.MILLION) {
      abbreviatedAmount /= Numbers.MILLION;
      numberAbbreviation = NumberAbbreviation.MILLION;
    } else if (
      (formatThousand && absoluteAmount >= Numbers.THOUSAND) ||
      absoluteAmount >= Numbers.HUNDRED_THOUSAND
    ) {
      abbreviatedAmount /= Numbers.THOUSAND;
      numberAbbreviation = NumberAbbreviation.THOUSAND;
    }

    formattedNumber = `${formatter.format(
      (Math.trunc(abbreviatedAmount * Math.pow(10, precision)) /
        Math.pow(10, precision)) *
        sign
    )}${numberAbbreviation}`;
  } else {
    formattedNumber = formatter.format(amount);
  }

  return formattedNumber;
}

export function handleNumberInput(input) {
  if (isNaN(input)) {
    input = input.replace(/\s+/g, '');
    const unit = input.slice(-1)?.toLowerCase();
    const value = parseFloat(input.slice(0, -1)?.replace(/,+/g, ''));
    return Object.keys(Units).includes(unit?.toUpperCase())
      ? value * Numbers[Units[unit?.toUpperCase()]]
      : value;
  }
  return input;
}
