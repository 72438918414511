import React from 'react';

export default function Education({ education }) {
  if (!education || !education.length) {
    return null;
  }

  return (
    <div className="education-wrapper">
      <h5 className="education-title">Education</h5>
      {education.map((education, index) => (
        <p className="education-master" key={index}>
          <span
            style={{
              display: 'block'
            }}
            className="education-university"
          >
            {education.schoolId || <i>No Name</i>}
          </span>
          {`${education.degree}`}
        </p>
      ))}
    </div>
  );
}
