import { combineReducers } from 'redux';
import lang from './Lang';
import loader from './Loader';
import snackbar from './Snackbar';
import companiesList from './company/list';
import pipelineList from './pipeline/list';
import moreFilters from './moreFilters';
import companyDetails from './company/details';
import companyProfile from './company/profile';
import fundingRoundsList from './funding/list';
import peopleList from './people/list';
import cryptoList from './crypto/list';
import flagList from './flag/list';
import login from './login';
import peopleDetails from './people/details';
import globalSearch from './filter/globalSearch';
import lsvpUsersList from './lsvp-user/list';
import crmEntity from './crm/entity';
import crmLists from './crm/list';
import teamViews from './team/teamViews';
import teamMembers from './team/teamMembers';
import present from './present';
import sectors from './sector/list';
import landing from './landing';
import autoFlags from './auto';
import triggers from './trigger';

export default combineReducers({
  lang,
  loader,
  snackbar,
  companiesList,
  pipelineList,
  companyProfile,
  moreFilters,
  companyDetails,
  fundingRoundsList,
  peopleList,
  cryptoList,
  flagList,
  login,
  peopleDetails,
  globalSearch,
  lsvpUsersList,
  crmEntity,
  crmLists,
  teamViews,
  teamMembers,
  present,
  sectors,
  landing,
  autoFlags,
  triggers
});
