import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Popover,
  Button,
  InputNumber,
  Radio,
  Select,
  Input,
  Spin
} from 'antd';
import { stopPropagation } from '../../Utils/Shared';
import {
  ActionType,
  EntityTypeCode,
  ActionDetailsType,
  SpecialTeamId,
  UserRole,
  Status,
  EntityType,
  DestinationType,
  classificationOptions
} from '../../Utils/Constants';
import {
  addAction,
  removeAction
} from '../../Store/actions/action/actions';
import { Activity } from '../../Utils/Constants';
import { logActivity } from '../../Store/actions/log/actions';
import {
  getActionDetailsDescription,
  isBaseAction
} from '../../Utils/Action'
import SearchableSelect from '../Shared/SearchableSelect';
import {
  assignLSVPUsersToEntity,
  unassignLSVPUsersFromEntity
} from '../../Store/actions/lsvp-user/actions';
import { getAllLSVPUsers } from '../../Store/actions/lsvp-user/list';
import { updateCompanyStatusAction } from '../../Store/actions/company/details';
import { updatePersonStatusAction } from '../../Store/actions/people/details';
import { createEntityNote } from '../../Store/actions/crm/entity';
import { SearchIndex } from '../../Utils/Constants';
import { graphInfo } from '../../Utils/Graph';
import showNotification from '../Shared/Notification';
import FilterSearchAPI from '../../Network/Apis/filterSearch';
import styles from './ActionPopover.module.scss';

const DAYS_DEFAULT = 90;
const DAYS_MIN = 1;
const DAYS_MAX = 365;
const MAX_GRAPHS = 2;

const ActionName = {
  [ActionType.ASSIGN]: 'Status',
  [ActionType.SNOOZE]: 'Snooze',
  [ActionType.BLOCK]: 'Block',
  [ActionType.FLAG]: 'Flag',
  [ActionType.CLASSIFY]: 'Classify'
};

export const Classification = {
  [Status.PROSPECT]: 'Regrettable',
  [Status.PORTFOLIO]: 'Portfolio',
  [Status.TRACKING]: 'Unregrettable',
  [Status.PASSED]: 'Unregrettable'
};

export const ClassificationDescription = {
  [Status.PROSPECT]: 'regrettable',
  [Status.PORTFOLIO]: 'portfolio',
  [Status.TRACKING]: 'not regrettable',
  [Status.PASSED]: 'not regrettable'
};

const graphMap = _.keyBy(graphInfo, 'key');
const userSearch = {
  searchFunction: FilterSearchAPI.searchByKeyword,
  params: { index: SearchIndex.LSVP_USER }
};

const getCurrentOwners = originalOwners => originalOwners ? originalOwners.map(({ id, firstName, lastName }) => ({ label: `${firstName} ${lastName}`, value: id })) : [];

const getCurrentStatus = originalStatus => originalStatus ?? Status.NONE;

const getActions = (actions, companyActions, entityType, actionType) => entityType === EntityType.FUNDING_ROUND && isBaseAction(actionType) ? companyActions : actions;

const findAction = (actions, companyActions, entityType, actionType, teamId) => getActions(actions, companyActions, entityType, actionType).find(
  action => !action.triggeredAt && !action.deactivatedAt && action.actionType === actionType && action.teamId === teamId
);

const getActionTeamId = (actionType, teamId) => actionType === ActionType.CLASSIFY ? SpecialTeamId.EVERYONE : teamId;

export default ({
  actions,
  entityId,
  entityType,
  teamIds,
  showSnoozed,
  showBlocked,
  team,
  originalOwners,
  originalStatus,
  flag,
  availableGraphs,
  companyId,
  companyActions,
  singleActionType,
  getCompanyFlag,
  getPersonFlag,
  getFundingRoundFlag,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onClick,
  children
}) => {
  const lsvpUser = useSelector(({ login }) => login.lsvpUser);
  const teamMembers = useSelector(({ teamMembers }) => teamMembers);

  const getAccessibleTeams = () => {
    const ids = teamIds.filter(id => id !== SpecialTeamId.ME);
    if (!ids) { return []; }
    if (lsvpUser.role === UserRole.ADMIN) { return ids; }
    if (lsvpUser.role === UserRole.TEAM) { return _.intersection(teamMembers[lsvpUser.id], ids); }
    return [];
  };

  const isAssignable = () => originalOwners && getAccessibleTeams().length;

  const getDefaultActionType = () => {
    if (singleActionType) { return singleActionType; }
    if (isAssignable()) { return ActionType.ASSIGN; }
    return ActionType.SNOOZE;
  };

  const lsvpUsers = useSelector(({ lsvpUsersList })  => lsvpUsersList.users);
  const [visible, setVisible] = useState(false);
  const [actionType, setActionType] = useState(getDefaultActionType());
  const [teamId, setTeamId] = useState(SpecialTeamId.ME);
  const [action, setAction] = useState();
  const [comment, setComment] = useState('');
  const [days, setDays] = useState(DAYS_DEFAULT);
  const [currentStatus, setCurrentStatus] = useState(getCurrentStatus(originalStatus));
  const [currentOwners, setCurrentOwners] = useState(getCurrentOwners(originalOwners));
  const [graphs, setGraphs] = useState([]);
  const [snoozeType, setSnoozeType] = useState(ActionDetailsType.DAYS);
  const [absoluteRevenue, setAbsoluteRevenue] = useState();
  const [absoluteHeadcount, setAbsoluteHeadcount] = useState();
  const [absoluteAppDau, setAbsoluteAppDau] = useState();
  const [relativeRevenue, setRelativeRevenue] = useState();
  const [relativeHeadcount, setRelativeHeadcount] = useState();
  const [relativeAppDau, setRelativeAppDau] = useState();
  const [classification, setClassification] = useState(classificationOptions[0].value);
  const [destinationType, setDestinationType] = useState(DestinationType.TEAM);
  const [person, setPerson] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const action = findAction(actions, companyActions, entityType, actionType, getActionTeamId(actionType, teamId));
    setAction(action);
    if (actionType === ActionType.FLAG) {
      if ((teamId !== SpecialTeamId.ME && teamId !== SpecialTeamId.EVERYONE) || person) {
        if (flag !== undefined) {
          setComment(flag);
        } else {
          switch (entityType) {
            case EntityType.COMPANY:
              if (getCompanyFlag) {
                getCompanyFlag(entityId);
              }
              break;
            case EntityType.PERSON:
              if (getPersonFlag) {
                getPersonFlag(entityId);
              }
              break;
            case EntityType.FUNDING_ROUND:
              if (getFundingRoundFlag) {
                getFundingRoundFlag(entityId);
              }
              break;
            default:
              break;
          }
        }
      }
    } else if (action) {
      if (action.actionType === actionType) {
        if (action.comment) { setComment(action.comment); }
        switch (action.actionType) {
          case ActionType.SNOOZE:
            setSnoozeType(action.details.type);
            switch (action.details.type) {
              case ActionDetailsType.DAYS:
                setDays(action.details.days);
                break;
              case ActionDetailsType.EVENT:
                setAbsoluteRevenue(action.details.absoluteRevenue);
                setAbsoluteHeadcount(action.details.absoluteHeadcount);
                setAbsoluteAppDau(action.details.absoluteAppDau);
                setRelativeRevenue(action.details.relativeRevenue);
                setRelativeHeadcount(action.details.relativeHeadcount);
                setRelativeAppDau(action.details.relativeAppDau);
                break;
              default:
                break;
            }
            break;
          case ActionType.CLASSIFY:
            setClassification(action.details.classification);
            break;
          default:
            break;
        }
      }
    }
  }, [actions, entityId, entityType, flag, actionType, teamId, person, companyActions, visible, getCompanyFlag, getPersonFlag, getFundingRoundFlag]);

  useEffect(() => {
    setCurrentOwners(getCurrentOwners(originalOwners));
  }, [originalOwners]);

  useEffect(() => {
    setCurrentStatus(getCurrentStatus(originalStatus));
  }, [originalStatus, entityType]);

  useEffect(() => {
    if (visible && lsvpUsers.length === 0) { dispatch(getAllLSVPUsers()); }
  }, [visible, lsvpUsers, dispatch]);

  const getBaseEntityId = () => entityType === EntityType.FUNDING_ROUND ? companyId : entityId;

  const getBaseEntityType = () => entityType === EntityType.FUNDING_ROUND ? EntityType.COMPANY : entityType;

  const getBaseEntityTypeCode = () => ({
    [EntityType.COMPANY]: EntityTypeCode.COMPANY,
    [EntityType.PERSON]: EntityTypeCode.PERSON
  }[getBaseEntityType()]);

  const getActionEntityId = () => isBaseAction(actionType) ? getBaseEntityId() : entityId;

  const getActionEntityType = () => isBaseAction(actionType) ? getBaseEntityType() : entityType;

  const getTeamId = () => getActionTeamId(actionType, teamId);

  const getPersonId = () => person?.[0]?.value;

  const getDestinationType = () => (actionType !== ActionType.FLAG && lsvpUser.role === UserRole.ADMIN && teamId === SpecialTeamId.ME  ?  DestinationType.PERSON : destinationType);

  const reset = () => {
    const actionType = getDefaultActionType();
    const teamId = getAccessibleTeams().length && team ? team : SpecialTeamId.ME;
    const action = findAction(actions, companyActions, entityType, actionType, getTeamId());
    setActionType(actionType);
    setTeamId(teamId);
    setAction(action);
    setComment('');
    setDays(action?.details?.days ?? DAYS_DEFAULT);
    setCurrentOwners(getCurrentOwners(originalOwners));
    setCurrentStatus(getCurrentStatus(originalStatus));
    setGraphs([]);
    setSnoozeType(ActionDetailsType.DAYS);
    setAbsoluteRevenue(undefined);
    setAbsoluteHeadcount(undefined);
    setAbsoluteAppDau(undefined);
    setRelativeRevenue(undefined);
    setRelativeHeadcount(undefined);
    setRelativeAppDau(undefined);
    setClassification(classificationOptions[0].value);
    setDestinationType(DestinationType.TEAM);
    setPerson();
  };

  const parseDays = value => {
    value = Number.parseInt(value, 10);
    return Number.isNaN(value) ? DAYS_MIN : Math.max(Math.min(value, DAYS_MAX), DAYS_MIN);
  };
  
  const parseEvent = value => {
    value = Number.parseInt(value, 10);
    return Number.isNaN(value) || value < 1 ? '' : value;
  };
  

  const getActionOptions = () => {
   const options = entityType === EntityType.FUNDING_ROUND ? [] : [
      { label: ActionName[ActionType.SNOOZE], value: ActionType.SNOOZE },
      { label: ActionName[ActionType.BLOCK], value: ActionType.BLOCK }
    ];
    if (isAssignable()) { options.unshift({ label: ActionName[ActionType.ASSIGN], value: ActionType.ASSIGN }); }
    if (getAccessibleTeams().length) { options.push({ label: ActionName[ActionType.FLAG], value: ActionType.FLAG }); }
    return options;
  };

  const getTeamIdOptions = () => [
    ...(actionType !== ActionType.FLAG ? [
      { label: SpecialTeamId.ME, value: SpecialTeamId.ME },
      { label: SpecialTeamId.EVERYONE, value: SpecialTeamId.EVERYONE }
    ] : []),
    ...getAccessibleTeams().map(team => ({ label: team, value: team }))
  ];

  const getTeamIdValue = () => actionType !== ActionType.FLAG || (teamId !== SpecialTeamId.ME && teamId !== SpecialTeamId.EVERYONE) ? teamId : undefined;

  const canSave = () => {
    if (actionType === ActionType.FLAG) { 
      switch (destinationType) {
        case DestinationType.TEAM:
          return teamId !== SpecialTeamId.ME && teamId !== SpecialTeamId.EVERYONE;
        case DestinationType.PERSON:
          return person;
        default:
          return false;
      }
    }
    if (!getTeamIdValue()) { return false; }
    if (lsvpUser.role === UserRole.ADMIN && teamId === SpecialTeamId.ME && !person) { return false; }
    return true;
  };
  
  const getStatusOptions = () => [
    { label: Status.PROSPECT, value: Status.PROSPECT },
    { label: Status.PORTFOLIO, value: Status.PORTFOLIO },
    { label: Status.TRACKING, value: Status.TRACKING },
    { label: Status.PASSED, value: Status.PASSED },
    { label: Status.NONE, value: Status.NONE }
  ];

  const getGraphOptions = () => availableGraphs ? (
    availableGraphs.filter(
      ({ name, points }) => graphMap[name] && points.length
    ).map(
      ({ name }) => ({ label: graphMap[name].title, value: name })
    )
  ) : [];

  const getGraphValue = () => graphs.map(graph => ({ label: graphMap[graph].title, value: graph }));

  const getSnoozeTypeOptions = () => {
   return [
      { label: 'For', value: ActionDetailsType.DAYS },
      { label: 'Until', value: ActionDetailsType.EVENT }
    ];
  };

  const getDetails = () => {
    switch (actionType) {
      case ActionType.ASSIGN:
        return {
          type: ActionDetailsType.CONTACT,
          daysWithoutContact: 30,
          daysWithContact: 90,
          source: 'assign',
          assignee: currentOwners.map(({ value }) => value).join(',')
        };
      case ActionType.SNOOZE:
        switch (snoozeType) {
          case ActionDetailsType.DAYS:
            return {
              type: ActionDetailsType.DAYS,
              days
            };
          case ActionDetailsType.EVENT:
            const details = {
              type: ActionDetailsType.EVENT
            };
            if (absoluteRevenue) { details.absoluteRevenue = absoluteRevenue; }
            if (absoluteHeadcount) { details.absoluteHeadcount = absoluteHeadcount; }
            if (absoluteAppDau) { details.absoluteAppDau = absoluteAppDau; }
            if (relativeRevenue) { details.relativeRevenue = relativeRevenue; }
            if (relativeHeadcount) { details.relativeHeadcount = relativeHeadcount; }
            if (relativeAppDau) { details.relativeAppDau = relativeAppDau; }
            return details;
          default:
            break;
        }
        break;
      case ActionType.FLAG:
        return {
          type: ActionDetailsType.INFO,
          graphs
        };
      case ActionType.CLASSIFY:
        return {
          type: ActionDetailsType.CLASSIFICATION,
          classification
        };
      default:
        break;
    }
  };

  const assign = () => {
    const originalIds = originalOwners.map(({ id }) => id);
    const currentIds = currentOwners.map(({ value }) => value);
    const addedUserIds = _.difference(currentIds, originalIds);
    const removedUserIds = _.difference(originalIds, currentIds);

    if (addedUserIds.length) {
      dispatch(assignLSVPUsersToEntity({
        entityId: getBaseEntityId(),
        entityType: getBaseEntityType(),
        addedUserIds,
        lsvpUsers
      }));
    }

    if (removedUserIds.length) {
      dispatch(unassignLSVPUsersFromEntity({
        entityId: getBaseEntityId(),
        entityType: getBaseEntityType(),
        removedUserIds
      }));
    }

    switch (getBaseEntityType()) {
      case EntityType.COMPANY:
        dispatch(updateCompanyStatusAction(getBaseEntityId(), currentStatus));
        break;
      case EntityType.PERSON:
        dispatch(updatePersonStatusAction(getBaseEntityId(), currentStatus));
        break;
      default:
        break;
    }
  };

  const hide = validate => {
    if (validate) {
      if (
        actionType === ActionType.SNOOZE
        &&
        snoozeType === ActionDetailsType.EVENT
        &&
        !absoluteRevenue && !absoluteHeadcount && !absoluteAppDau && !relativeRevenue && !relativeHeadcount && !relativeAppDau
      ) {
        showNotification('info', `Please specify at least one trigger`);
        return false;
      }
    }
    setVisible(false);
    return true;
  };

  const onGraphSelect = ({ value }) => {
    if (graphs.length < MAX_GRAPHS) {
      setGraphs([...graphs, value]);
    } else {
      showNotification('info', `Please select up to ${MAX_GRAPHS} graphs`);
    }
  };

  const onGraphDeselect = ({ value }) => setGraphs(graphs.filter(graph => graph !== value));

  const onSave = () => {
    const details = getDetails();

    if (actionType === ActionType.ASSIGN) {
      if (!currentOwners.length || currentStatus === Status.NONE) {
        if (entityType === EntityType.FUNDING_ROUND) {
          dispatch(removeAction({
            entityId,
            entityType: EntityType.FUNDING_ROUND,
            actionType: ActionType.BLOCK,
            teamId: getTeamId(),
            userId: lsvpUser.id
          }));

          dispatch(logActivity(Activity.REMOVE_ACTION, lsvpUser.id, {
            entityId,
            entityType: EntityType.FUNDING_ROUND,
            actionType: ActionType.BLOCK,
            teamId: getTeamId()
          }));

          dispatch(removeAction({
            entityId,
            entityType: EntityType.FUNDING_ROUND,
            actionType: ActionType.CLASSIFY,
            teamId: getTeamId(),
            userId: lsvpUser.id
          }));

          dispatch(logActivity(Activity.REMOVE_ACTION, lsvpUser.id, {
            entityId,
            entityType: EntityType.FUNDING_ROUND,
            actionType: ActionType.CLASSIFY,
            teamId: getTeamId()
          }));
        }

        dispatch(removeAction({
          entityId: getBaseEntityId(),
          entityType: getBaseEntityType(),
          actionType: ActionType.ASSIGN,
          teamId: getTeamId(),
          userId: lsvpUser.id
        }));

        dispatch(logActivity(Activity.REMOVE_ACTION, lsvpUser.id, {
          entityId: getBaseEntityId(),
          entityType: getBaseEntityType(),
          actionType: ActionType.ASSIGN,
          teamId: getTeamId()
        }));

        dispatch(removeAction({
          entityId: getBaseEntityId(),
          entityType: getBaseEntityType(),
          actionType: ActionType.SNOOZE,
          teamId: getTeamId(),
          userId: lsvpUser.id
        }));

        dispatch(logActivity(Activity.REMOVE_ACTION, lsvpUser.id, {
          entityId: getBaseEntityId(),
          entityType: getBaseEntityType(),
          actionType: ActionType.SNOOZE,
          teamId: getTeamId()
        }));
      } else {
        if (entityType === EntityType.FUNDING_ROUND) {
          const classification = Classification[currentStatus];
          if (classification) {
            dispatch(addAction({
              entityId,
              entityType,
              actionType: ActionType.CLASSIFY,
              teamId: getTeamId(),
              userId: lsvpUser.id,
              details: {
                type: 'classification',
                classification
              },
              comment,
              userName: `${lsvpUser.first_name} ${lsvpUser.last_name}`,
              showSnoozed,
              showBlocked,
              team
            }));

            dispatch(logActivity(Activity.ADD_ACTION, lsvpUser.id, {
              entityId,
              entityType: EntityType.FUNDING_ROUND,
              actionType: ActionType.CLASSIFY,
              teamId: getTeamId(),
              comment
            }));
          }
        }

        switch (currentStatus) {
          case Status.PROSPECT:
            if (entityType === EntityType.FUNDING_ROUND) {
              dispatch(addAction({
                entityId,
                entityType: EntityType.FUNDING_ROUND,
                actionType: ActionType.BLOCK,
                teamId: getTeamId(),
                userId: lsvpUser.id,
                comment,
                userName: `${lsvpUser.first_name} ${lsvpUser.last_name}`,
                showSnoozed,
                showBlocked,
                team,
                flag: true
              }));

              dispatch(logActivity(Activity.ADD_ACTION, lsvpUser.id, {
                entityId,
                entityType: EntityType.FUNDING_ROUND,
                actionType: ActionType.BLOCK,
                teamId: getTeamId(),
                comment
              }));
            }

            dispatch(addAction({
              entityId: getBaseEntityId(),
              entityType: getBaseEntityType(),
              actionType: ActionType.ASSIGN,
              teamId: getTeamId(),
              userId: lsvpUser.id,
              comment,
              details,
              userName: `${lsvpUser.first_name} ${lsvpUser.last_name}`,
              showSnoozed,
              showBlocked,
              team,
              graphs,
              flag: entityType !== EntityType.FUNDING_ROUND
            }));

            dispatch(logActivity(Activity.ADD_ACTION, lsvpUser.id, {
              entityId: getBaseEntityId(),
              entityType: getBaseEntityType(),
              actionType: ActionType.ASSIGN,
              teamId: getTeamId(),
              comment,
              details
            }));
            break;
          case Status.TRACKING:
            if (entityType === EntityType.FUNDING_ROUND) {
              dispatch(addAction({
                entityId,
                entityType: EntityType.FUNDING_ROUND,
                actionType: ActionType.BLOCK,
                teamId: getTeamId(),
                userId: lsvpUser.id,
                comment,
                userName: `${lsvpUser.first_name} ${lsvpUser.last_name}`,
                showSnoozed,
                showBlocked,
                team,
                flag: true
              }));

              dispatch(logActivity(Activity.ADD_ACTION, lsvpUser.id, {
                entityId,
                entityType: EntityType.FUNDING_ROUND,
                actionType: ActionType.BLOCK,
                teamId: getTeamId(),
                comment
              }));
            }

            dispatch(addAction({
              entityId: getBaseEntityId(),
              entityType: getBaseEntityType(),
              actionType: ActionType.SNOOZE,
              teamId: getTeamId(),
              userId: lsvpUser.id,
              comment,
              details: {
                type: ActionDetailsType.DAYS,
                days: 180
              },
              userName: `${lsvpUser.first_name} ${lsvpUser.last_name}`,
              showSnoozed,
              showBlocked,
              team,
              graphs,
              flag: entityType !== EntityType.FUNDING_ROUND
            }));

            dispatch(logActivity(Activity.ADD_ACTION, lsvpUser.id, {
              entityId: getBaseEntityId(),
              entityType: getBaseEntityType(),
              actionType: ActionType.SNOOZE,
              teamId: getTeamId(),
              comment,
              details
            }));
            break;
          default:
            break;
        }
      }
    } else {
      dispatch(addAction({
        entityId: getActionEntityId(),
        entityType: getActionEntityType(),
        actionType,
        teamId: getTeamId(),
        userId: lsvpUser.id,
        comment,
        details,
        userName: `${lsvpUser.first_name} ${lsvpUser.last_name}`,
        showSnoozed,
        showBlocked,
        team,
        graphs,
        flag: true,
        destinationType: getDestinationType(),
        personId: getPersonId()
      }));

      dispatch(logActivity(Activity.ADD_ACTION, lsvpUser.id, {
        entityId: getActionEntityId(),
        entityType: getActionEntityType(),
        actionType,
        teamId: getTeamId(),
        comment,
        details,
        destinationType: getDestinationType(),
        personId: getPersonId()
      }));
    }

    if (actionType === ActionType.ASSIGN) { assign(); }

    if (actionType !== ActionType.FLAG && process.env.REACT_APP_STAGE === 'production') {
      const silentString = actionType !== ActionType.ASSIGN ? '[silent] ' : '';
      const detailsString = details ? ` ${getActionDetailsDescription(details)}` : '';
      const commentString = comment ? ` "${comment}"` : '';
      dispatch(createEntityNote(
        getBaseEntityId(),
        getBaseEntityTypeCode(),
        `${silentString}Added ${actionType} by ${lsvpUser.email} for ${getTeamId()}${detailsString}${commentString}`,
        lsvpUser.id,
        []
      ));
    }
  };

  const renderTeamSelect = () => (
    <>
      <div className={styles.teamLabel}>Team</div>
      <Select
        className={styles.select}
        options={getTeamIdOptions()}
        value={getTeamIdValue()}
        onChange={setTeamId}
      />
    </>
  );

  const renderPersonSelect = () => (
    <>
      <div className={styles.personLabel}>Person</div>
      <SearchableSelect
        className={styles.select}
        items={person}
        search={userSearch}
        onChange={setPerson}
      />
    </>
  );

  const renderDestination = () =>  {
   const options = [
      { label: 'Team', value: DestinationType.TEAM },
      { label: 'Person', value: DestinationType.PERSON }
    ];
    return (
      <>
        <Radio.Group
          className={styles.destinationRadioGroup}
          options={options}
          value={destinationType}
          onChange={event => setDestinationType(event.target.value)}
        />
        {destinationType === DestinationType.TEAM ? (
          <Select
            className={styles.select}
            options={getTeamIdOptions()}
            value={getTeamIdValue()}
            onChange={setTeamId}
          />
        ) : null}
        {destinationType === DestinationType.PERSON ? (
          <SearchableSelect
            className={styles.select}
            items={person}
            search={userSearch}
            onChange={setPerson}
          />
        ) : null}
      </>
    );
  };

  const renderOwnerSelect = () => (
    <>
      <div className={styles.ownerLabel}>Owner</div>
      <SearchableSelect
        className={styles.select}
        mode="multiple"
        items={currentOwners}
        search={userSearch}
        onChange={setCurrentOwners}
      />
    </>
  );

  const renderClassifySelect = () => (
    <>
      <div className={styles.classificationLabel}>Classification</div>
      <Select
        className={styles.select}
        options={classificationOptions}
        value={classification}
        onChange={setClassification}
      />
    </>
  );

  const renderStatusSelect = () => (
    <>
      <div className={styles.statusLabel}>Status</div>
      <Select
        className={styles.select}
        options={getStatusOptions()}
        value={currentStatus}
        onChange={setCurrentStatus}
      />
    </>
  );

  const renderClassificationNote = () => (
    <div className={styles.classificationNote}>
      {
        ClassificationDescription[currentStatus] ? (
          `Funding round classified as ${ClassificationDescription[currentStatus]}`
        ) : (
          'Funding round not classified'
        )
      }
    </div>
  );

  const renderTopSelect = () => {
    switch (actionType) {
      case ActionType.ASSIGN:
        return (
          <>
            {renderStatusSelect()}
            {entityType === EntityType.FUNDING_ROUND ? renderClassificationNote() : null}
            {currentStatus === Status.PROSPECT || currentStatus === Status.TRACKING ? renderOwnerSelect() : null}
          </>
        );
      case ActionType.SNOOZE:
        return getAccessibleTeams().length ? (
          <>
            {renderTeamSelect()}
            {lsvpUser.role === UserRole.ADMIN && teamId === SpecialTeamId.ME ? (
              renderPersonSelect()
            ) : null}
          </>
        ) : null;
      case ActionType.BLOCK:
        return getAccessibleTeams().length ? (
          <>
            {renderTeamSelect()}
            {lsvpUser.role === UserRole.ADMIN && teamId === SpecialTeamId.ME ? (
              renderPersonSelect()
            ) : null}
          </>
        ) : null;
      case ActionType.FLAG:
        return renderDestination();
      case ActionType.CLASSIFY:
        return renderClassifySelect();
      default:
        return null
    }
  };

  const renderTop = () =>  {
    if (singleActionType) {
      return (
        <div className={styles.title}>{ActionName[singleActionType]}</div>
      );
    }
    const options = getActionOptions();
    if (options.length === 1) {
      return (
        <div className={styles.title}>{options[0].label}</div>
      );
    }
    return (
      <Radio.Group
        className={styles.topRadioGroup}
        options={options}
        value={actionType}
        optionType="button"
        onChange={event => setActionType(event.target.value)}
      />
    );
  };
  
  const renderPane = () => {
    const loading = (
      !action
      &&
      actionType === ActionType.FLAG
      &&
      teamId !== SpecialTeamId.ME
      &&
      teamId !== SpecialTeamId.EVERYONE
      &&
      flag === undefined
    );

    return (
      <Spin spinning={loading} delay={300}>
        {renderTopSelect()}

        <div className={styles.commentLabel}>Comment</div>

        <Input.TextArea
          className={styles.comment}
          rows={actionType === ActionType.FLAG ? 4 : 1}
          cols="40"
          value={comment}
          onScroll={stopPropagation}
          onChange={event => setComment(event.target.value)}
        />

        {actionType === ActionType.ASSIGN ? (
          <>
            {renderTeamSelect()}
            {lsvpUser.role === UserRole.ADMIN && teamId === SpecialTeamId.ME ? (
              renderPersonSelect()
            ) : null}
          </>
        ) : null}

        {actionType === ActionType.SNOOZE ? (
          <>
            <div className={styles.snoozeTypeRow}>
              {getBaseEntityType() === EntityType.COMPANY ? (
                <Select
                  className={styles.snoozeTypeSelect}
                  options={getSnoozeTypeOptions()}
                  value={snoozeType}
                  onChange={setSnoozeType}
                />
              ) : (
                <div className={styles.forLabel}>For</div>
              )}
              {snoozeType === ActionDetailsType.DAYS ? (
                <>
                  <InputNumber
                    className={styles.daysInput}
                    value={days}
                    min={DAYS_MIN}
                    max={DAYS_MAX}
                    parser={parseDays}
                    onChange={setDays}
                  />
                  <span className={styles.daysLabel}>days</span>
                </>
              ) : 'any of the following:' }
            </div>
            {snoozeType === ActionDetailsType.EVENT ? (
              <div className={styles.eventControls}>
                <div className={styles.eventRow}>
                  <div className={styles.eventColumn}>
                    <div>Revenue hits</div>
                    <InputNumber
                      className={styles.eventInput}
                      value={absoluteRevenue}
                      parser={parseEvent}
                      onChange={setAbsoluteRevenue}
                    />
                  </div>
                  <div className={styles.eventColumn}>
                    <div>Headcount hits</div>
                    <InputNumber
                      className={styles.eventInput}
                      value={absoluteHeadcount}
                      parser={parseEvent}
                      onChange={setAbsoluteHeadcount}
                    />
                  </div>
                  <div className={styles.eventColumn}>
                    <div>App DAU hits</div>
                    <InputNumber
                      className={styles.eventInput}
                      value={absoluteAppDau}
                      parser={parseEvent}
                      onChange={setAbsoluteAppDau}
                    />
                  </div>
                </div>
                <div className={styles.eventRow}>
                  <div className={styles.eventColumn}>
                    <div>Revenue up %</div>
                    <InputNumber
                      className={styles.eventInput}
                      value={relativeRevenue}
                      parser={parseEvent}
                      onChange={setRelativeRevenue}
                    />
                  </div>
                  <div className={styles.eventColumn}>
                    <div>Headcount up %</div>
                    <InputNumber
                      className={styles.eventInput}
                      value={relativeHeadcount}
                      parser={parseEvent}
                      onChange={setRelativeHeadcount}
                    />
                  </div>
                  <div className={styles.eventColumn}>
                    <div>App DAU up %</div>
                    <InputNumber
                      className={styles.eventInput}
                      value={relativeAppDau}
                      parser={parseEvent}
                      onChange={setRelativeAppDau}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {actionType === ActionType.FLAG && getBaseEntityType() === EntityType.COMPANY ? (
          <>
            <div className={styles.graphLabel}>Graphs</div>
            <Select
              className={styles.select}
              mode="multiple"
              options={getGraphOptions()}
              value={getGraphValue()}
              labelInValue={true}
              onSelect={onGraphSelect}
              onDeselect={onGraphDeselect}
            />
          </>
        ) : null}

        <div className={styles.buttons}>
          <Button 
            className={styles.cancel}
            onClick={() => hide()}
          >
            Cancel
          </Button>
          <Button 
            type="primary"
            disabled={!canSave()}
            onClick={() => {if (hide(true)) { onSave(); } }}
          >
            Save
          </Button>
        </div>
      </Spin>
    );
  };

  const renderContent = () => (
    <div className={styles.content}>
      {renderTop()}
      {renderPane()}
    </div>
  );

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={renderContent()}
      arrowPointAtCenter
      visible={visible}
      onVisibleChange={visible => {
        if (visible) { reset(); }
        setVisible(visible);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onClick={onClick}
    >
      {children}
    </Popover>
  )
};
