import React, { useState } from 'react';
import { swapTags, getUserIdsFromTags, renderUserSuggestion } from './utils';
import { MentionsInput, Mention } from 'react-mentions';
import styles from './AddNote.module.scss';

export default function AddNotes({
  entityId,
  entityType,
  createNoteAction,
  currentUser,
  getLsvpUsers,
  disableAddNoteBtn
}) {
  const [content, setContent] = useState('');

  const users = getLsvpUsers();

  const createNote = () => {
    const creatorId = currentUser?.id;
    const usersMentioned = getUserIdsFromTags(content);
    const textContent = swapTags(content);
    if (creatorId && textContent?.length > 0) {
      createNoteAction(
        entityId,
        entityType,
        textContent,
        creatorId,
        usersMentioned
      );
    }
    setContent('');
  };

  return (
    <div className={styles.addNote}>
      <div>
        <MentionsInput
          value={content}
          markup="@*__id__*"
          placeholder="Add a note"
          onChange={(_event, newValue, _newPlainTextValue, _mentions) => {
            setContent(newValue);
          }}
          allowSpaceInQuery
          className="add-note-input"
        >
          <Mention
            trigger="@"
            displayTransform={(id, display) => `@${display}`}
            data={users}
            renderSuggestion={renderUserSuggestion}
          />
        </MentionsInput>
      </div>
      <div className="mt-2 d-flex justify-content-end align-items-center">
        <button
          className={styles.addNoteButton}
          onClick={createNote}
          disabled={disableAddNoteBtn ? true : false}
        >
          Add Note
        </button>
      </div>
    </div>
  );
}
