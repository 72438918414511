import { axiosInstance } from '../index';

const getCryptoList = async params => {
  return await axiosInstance.get('crypto', { params, handlerEnabled: true });
};

const getCryptoCount = async params => {
  return await axiosInstance.get('crypto/count', {
    params,
    handlerEnabled: true
  });
};

const downloadCryptoList = async (params = {}) => {
  return await axiosInstance.get(`crypto/csv`, {
    params,
    handlerEnabled: true
  });
};


export default {
  getCryptoList, 
  getCryptoCount,
  downloadCryptoList
};
