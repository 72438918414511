import { axiosInstance } from '../index';

const getLanding = async ({ token }) => {
  return await axiosInstance.get(`landing/${token}`, { handlerEnabled: true });
};

const getFlag = async ({ id, token }) => {
  return await axiosInstance.get(`landing/flags/${id}`, {
    params: {
      token,
    },
    handlerEnabled: true,
  });
};

const addCrmInteraction = async params => {
  return await axiosInstance.post(`landing/crm/interaction`, params);
};

const addFlagAction = async (params) => {
  return await axiosInstance.post(`landing/flags/action`, params);
};

export default {
  getLanding,
  getFlag,
  addCrmInteraction,
  addFlagAction
};
