import { notification } from 'antd';
import styles from './Notification.module.scss';

export default function showNotification(type, title, message) {
  notification[type]({
    message: title,
    description: message,
    placement: 'bottomRight',
    className: styles.notification
  });
}
