import { CrmListAction } from '../../types/crm/list';

export const INITIAL_STATE = {
  lists: [],
  allLists: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CrmListAction.SET_AFFINITY_LISTS:
      return { ...state, lists: action.payload.lists };
    case CrmListAction.SET_ALL_AFFINITY_LISTS:
      return { ...state, allLists: action.payload.lists };
    default:
      return state;
  }
};
