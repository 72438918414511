import { axiosInstance } from '../index';

const overrideFundingRoundCoverage = async (id, value) => {
  return axiosInstance.post(`funding/${id}/coverage`, {
    value
  });
};

const overrideFundingRoundStage = async (id, value) => {
  return axiosInstance.post(`funding/${id}/stage`, {
    value
  });
};

export default {
  overrideFundingRoundCoverage, 
  overrideFundingRoundStage
};