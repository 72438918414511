// Service to check authentication for user and to signOut
import Amplify, { Auth as amplifyAuth } from 'aws-amplify';
import store from '../Store';
import {
  login as loginAction,
  logout as logoutAction
} from '../Store/actions/login';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { LocalStorageKeys } from './LocalStorage';
import PATH_NAME from '../Routes/PathNames';
import LsvpUserAPI from '../Network/Apis/lsvp-user/details';

const identityProvider = 'Idaptive-lightspeed-dev';

export const websiteStageDomains = {
  local: 'http://localhost:3001',
  develop: 'https://atlas.test.lsvp.com',
  staging: 'https://atlas.staging.lsvp.com',
  production: 'https://atlas.lsvp.com'
};

const awsCognitoDomains = {
  local: 'photon-test.auth.us-west-2.amazoncognito.com',
  develop: 'photon-test.auth.us-west-2.amazoncognito.com',
  staging: 'photon-test.auth.us-west-2.amazoncognito.com',
  production: 'photon-test.auth.us-west-2.amazoncognito.com'
};

const signInUrl = `https://${
  awsCognitoDomains[process.env.REACT_APP_STAGE]
}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${
  websiteStageDomains[process.env.REACT_APP_STAGE] + PATH_NAME.SIGN_IN
}/&response_type=TOKEN&client_id=4vj5og15d4tlbg41pscoui3u0c&state=VBIR8wRK3ixtHJ8PSHjRi9ipvDyFT4UE&scope=email%20openid`;

const awsconfig = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_nTcKeXi6p',
    userPoolWebClientId: '4vj5og15d4tlbg41pscoui3u0c',
    mandatorySignIn: true,
    redirectSignIn:
      websiteStageDomains[process.env.REACT_APP_STAGE] + PATH_NAME.SIGN_IN,
    redirectSignOut:
      websiteStageDomains[process.env.REACT_APP_STAGE] + PATH_NAME.SIGN_IN
  }
};

const awsauth = {
  domain: awsCognitoDomains[process.env.REACT_APP_STAGE],
  scope: ['email', 'openid'],
  redirectSignIn:
    websiteStageDomains[process.env.REACT_APP_STAGE] + PATH_NAME.SIGN_IN,
  redirectSignOut:
    websiteStageDomains[process.env.REACT_APP_STAGE] + PATH_NAME.SIGN_IN,
  responseType: 'token'
};

Amplify.configure(awsconfig);
amplifyAuth.configure({ oauth: awsauth });

function extractUserEmail(currentUser) {
  const username = currentUser?.username;
  const regex = new RegExp(identityProvider.toLowerCase() + '_(.*)', 'g');
  return regex.exec(username)?.[1];
}

export async function authenticateUser() {
  try {
    const user = await amplifyAuth.currentAuthenticatedUser();
    localStorage.setItem(
      LocalStorageKeys.TOKEN,
      user?.signInUserSession?.idToken?.jwtToken
    );
    try {
      const currentUserEmail = extractUserEmail(user);
      const lsvpUser = (await LsvpUserAPI.getLsvpUser(currentUserEmail))?.data
        ?.data[0];

      store.dispatch(loginAction(user, lsvpUser));
    } catch (e) {
      console.log(e);
    }
    return user;
  } catch (e) {
    localStorage.removeItem(LocalStorageKeys.TOKEN);
    store.dispatch(logoutAction());
    window.location.href = signInUrl;
  }
}

export const Auth = {
  signOut() {
    localStorage.removeItem(LocalStorageKeys.TOKEN);
  },
  isAuth() {
    return localStorage.getItem(LocalStorageKeys.TOKEN);
  }
};

const isUrlOfSameDomain = url =>
  url.match(
    new RegExp(`${websiteStageDomains[process.env.REACT_APP_STAGE]}`, 'g')
  );

export const getRedirectPath = (deleteAfterUse = true) => {
  let url = localStorage.getItem(LocalStorageKeys.REDIRECT_URL);
  if (!url) return PATH_NAME.HOME;

  const domain = websiteStageDomains[process.env.REACT_APP_STAGE];

  url = sanitizeUrl(url);

  if (!isUrlOfSameDomain(url)) {
    url = domain;
  }

  if (deleteAfterUse) {
    localStorage.removeItem(LocalStorageKeys.REDIRECT_URL);
  }

  let path = url.substring(domain.length);

  if (!path) path = PATH_NAME.HOME;

  return path;
};
