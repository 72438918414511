import { call, put, takeLatest } from 'redux-saga/effects';
import { CrmListAction } from '../../types/crm/list';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { EntityTypeCode } from '../../../Utils/Constants';
import API from '../../../Network/Apis/crm/list';
import {
  setAffinityLists,
  setAllAffinityLists,
  getAffinityLists
} from '../../actions/crm/list';

function* getLists() {
  try {
    const response = yield call(API.getAffinityLists);
    yield put(setAffinityLists(response.data.data.map(list => ({
      label: list.name,
      value: list.listId,
      details: list.details
    }))));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* getAllLists() {
  try {
    const response = yield call(API.getAllAffinityLists);
    yield put(setAllAffinityLists(response.data.data.filter(({ type }) => type === EntityTypeCode.COMPANY).map(({ name, id }) => ({
      label: name,
      value: id.toString()
    }))));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* addList({ payload }) {
  try {
    yield call(API.addAffinityList, payload);
    yield put(getAffinityLists());
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* addCompany({ payload }) {
  try {
    yield call(API.addAffinityListCompany, payload);
    if (payload.postAction) { yield put(payload.postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function* deleteCompany({ payload }) {
  try {
    yield call(API.deleteAffinityListCompany, payload);
    if (payload.postAction) { yield put(payload.postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* crmListSaga() {
  yield takeLatest(CrmListAction.GET_AFFINITY_LISTS, getLists);
  yield takeLatest(CrmListAction.GET_ALL_AFFINITY_LISTS, getAllLists);
  yield takeLatest(CrmListAction.ADD_AFFINITY_LIST, addList);
  yield takeLatest(CrmListAction.ADD_AFFINITY_LIST_COMPANY, addCompany);
  yield takeLatest(CrmListAction.DELETE_AFFINITY_LIST_COMPANY, deleteCompany);
}
