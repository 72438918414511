export const PATH_NAME = {
  HOME: '/',
  COMPANIES: '/companies',
  PIPELINE: '/pipeline',
  PEOPLE: '/people',
  FUNDING_ROUNDS: '/fundingRounds',
  COMPANY_PROFILE: '/companies/:id',
  CRYPTO: '/crypto',
  FLAGS: '/flags',
  SIGN_IN: '/sign_in',
  ERROR: '/error',
  LANDING: '/landing'
};

export default PATH_NAME;
