import { axiosInstance } from '../index';

const getCompaniesList = async (params, signal) => await axiosInstance.post('companies/list', params, { signal, handlerEnabled: true });

const downloadCompaniesList = async params => await axiosInstance.post('companies/csv', params, { handlerEnabled: true });

export default {
  getCompaniesList,
  downloadCompaniesList
};
