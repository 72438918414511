import React from 'react';
import { Avatar } from 'antd';
import { formatNumber } from '../../../Utils/NumberFormatter';
import {
  parseLink,
  stopPropagation
} from '../../../Utils/Shared';
import LinkedInIcon from '../../../Assets/images/LinkedIn_Logo.svg';
import styles from './EmployeeSummary.module.scss';

export default ({
  photoUrl,
  employeeName = '',
  jobTitle = '',
  socialMediaUrls,
  rank,
  onClick
 }) => (
  <div className={styles.employeeSummary} onClick={onClick}>
    <Avatar size={32} shape="circle" src={photoUrl} alt="avatar-img" />
    <div className={styles.nameAndTitle}>
      <div className={styles.name}>{employeeName}</div>
      <div className={styles.title}>{jobTitle}</div>
    </div>
    <div className={styles.rate}>{formatNumber({ amount: rank, precision: 1 })}</div>
    {socialMediaUrls?.linkedin ? (
      <a
        className={styles.linkedin}
        href={parseLink(socialMediaUrls.linkedin)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={stopPropagation}
      >
        <img src={LinkedInIcon} alt="LinkedInIcon" />
      </a>
    ) : null}
  </div>
);
