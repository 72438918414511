import { call, select, takeEvery, put } from 'redux-saga/effects';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { setFundingRoundsList}  from '../../actions/funding/list';
import { FundingRoundsCompanySummary } from '../../types/funding/companySummary';
import API from '../../../Network/Apis/company/summary';

export function* roundCompanySummary({ payload }) {
  try {
    const response = yield call(
      API.getCompanySummary,
      payload.companyId,
      payload.params
    );
    const fundingRoundsList = yield select(state => state.fundingRoundsList);
    const { companySummary, message } = response.data.data;
    yield put(
      setFundingRoundsList({
        ...fundingRoundsList,
        message,
        fundingRounds: addCompanySummaryToRound(
          fundingRoundsList?.fundingRounds,
          companySummary
        )
      })
    );
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

function addCompanySummaryToRound(fundingRounds, summary) {
  return fundingRounds.map(fundingRound =>
    fundingRound.companyId === summary.id
      ? { ...fundingRound, summary }
      : fundingRound
  );
}

export function* fundingRoundCompanySummarySaga() {
  yield takeEvery(
    FundingRoundsCompanySummary.GET_ROUND_COMPANY_SUMMARY,
    roundCompanySummary
  );
}
