export const CryptoListAction = {
  GET_CRYPTO_LIST: 'GET_CRYPTO_LIST',
  SET_CRYPTO_LIST: 'SET_CRYPTO_LIST',
  GET_MORE_CRYPTO_LIST: 'GET_MORE_CRYPTO_LIST',
  SET_MORE_CRYPTO_LIST: 'SET_MORE_CRYPTO_LIST',
  UPDATE_CRYPTO_LIST: 'UPDATE_CRYPTO_LIST',
  GET_CRYPTO_COUNT: 'GET_CRYPTO_COUNT',
  SET_CRYPTO_COUNT: 'SET_CRYPTO_COUNT',
  DOWNLOAD_CRYPTO_CSV: 'DOWNLOAD_CRYPTO_CSV'
};
