import { call, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/log';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { LogAction } from '../../types/log';

export function* logActivity({ payload }) {
  try {
    yield call(API.logActivity, payload);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* logSaga() {
  yield takeLatest(
    LogAction.LOG_ACTIVITY,
    logActivity
  );
}
