import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Input,
  Checkbox,
  Select,
  Button
} from 'antd';
import SearchableSelect from '../Shared/SearchableSelect';
import { stopPropagation } from '../../Utils/Shared';
import {
  TEAM_VIEW_NONE,
  SpecialTeamId,
  peopleSeniorityOptions
} from '../../Utils/Constants';
import CompanyListApi from '../../Network/Apis/company/list';
import { useDeepCompareEffect } from '../../Utils/UseDeepCompareEffect';
import {
  getTeamOptions,
  getTeamViewOptions,
  getTeamViewLabel
} from '../../Utils/TeamView';
import styles from './PositionControls.module.scss';

const COMPANY_DELIMITER = ' @ ';
const LIST_DELIMITER = ' ~ ';
const VIEW_LABEL = 'Company in View';
const PORTFOLIO_LABEL = 'Portfolio Company';
const companySearch = {
  searchFunction: CompanyListApi.getCompaniesList,
  dataAccessor: results => results.data.data.companies
};
const Type = {
  SPECIFIC: 'SPECIFIC',
  VIEW: 'VIEW',
  PORTFOLIO: 'PORTFOLIO'
};
const getTypeOptions = () => [
  { label: 'Specific Company', value: Type.SPECIFIC },
  { label: 'Company in View', value: Type.VIEW },
  { label: 'Portfolio Company', value: Type.PORTFOLIO }
];

export default ({
  filters,
  positionFilterKey,
  seniorityFilter,
  nonOperationalFilter,
  label,
  visible,
  addToFilters
}) => {
  const teamViews = useSelector(state => state.teamViews.companies);
  const lsvpUser = useSelector(state => state.login.lsvpUser);

  const [filter, setFilter] = useState(filters[positionFilterKey]?.[0]?.value ? [{
    label: filters[positionFilterKey][0].label,
    value: filters[positionFilterKey][0].value
  }] : []);

  const getValue = () => {
    try {
      const value = JSON.parse(filter[0]?.value);
      if (!value.type) { value.type = Type.SPECIFIC; }
      return value;
    } catch (e) {
      return { type: Type.SPECIFIC};
    }
  };

  const getLabel = () => {
    const label = {};
    const { type, company, title, seniority, nonOperational } = getValue();
    switch (type) {
      case Type.SPECIFIC:
        if (company) {
          const l = filter[0].label;
          label.company = l.includes(COMPANY_DELIMITER) ? l.split(COMPANY_DELIMITER)?.[1] : l;
        }
        break;
      case Type.VIEW:
        break;
      case Type.PORTFOLIO:
        break;
      default:
        break;
    }
    if (title) { label.title = title; }
    if (seniority) { label.seniority = seniority.map(value => peopleSeniorityOptions.find(option => option.value === value)?.label).join(LIST_DELIMITER); }
    if (nonOperational) { label.nonOperational = 'Include non-operational'; }
    return label;
  };

  const isEmptyValue = value => {
    return value.type === Type.SPECIFIC && !value.company && !value.seniority && !value.title;
  };

  const makeValue = ({
    type,
    company,
    team,
    view,
    title,
    seniority,
    nonOperational
  }) =>  {
    if (isEmptyValue({ type, company, title, seniority })) { return undefined; }
    const value = { type };
    switch (type) {
      case Type.SPECIFIC:
        if (company?.length) { value.company = company; }
        break;
      case Type.VIEW:
        value.team = team;
        value.view = view;
        break;
      case Type.PORTFOLIO:
        break;
      default:
        break;
    }
    if (title?.length) { value.title = title; }
    if (seniority?.length) { value.seniority = seniority; }
    if (nonOperational) { value.nonOperational = true; }
    return JSON.stringify(value);
  };

  const makeLabel = ({
    type,
    company,
    title,
    seniority,
    nonOperational
  }) => {
    let label = '';
    if (seniority) { label += seniority; }
    if (title && nonOperational) {
      label += label ? ` (${title}${LIST_DELIMITER}${nonOperational})` : `${title}${LIST_DELIMITER}${nonOperational}`;
    } else if (title) {
      label += label ? ` (${title})` : title;
    } else if (nonOperational) {
      label += label ? ` (${nonOperational})` : nonOperational;
    }
    switch (type) {
      case Type.SPECIFIC:
        if (company) {
          if (label) { label += COMPANY_DELIMITER; }
          label += company;
        } 
        break;
      case Type.VIEW:
        if (label) { label += COMPANY_DELIMITER; }
        label += VIEW_LABEL;
        break;
      case Type.PORTFOLIO:
        if (label) { label += COMPANY_DELIMITER; }
        label += PORTFOLIO_LABEL;
        break;
      default:
        break;
    }
    return label;
  };

  const onTitleChange = title => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({
      type: currentValue.type,
      company: currentLabel.company,
      title,
      seniority: currentLabel.seniority,
      nonOperational: currentLabel.nonOperational
    });
    const value = makeValue({
      type: currentValue.type,
      company: currentValue.company,
      team: currentValue.team,
      view: currentValue.view,
      title,
      seniority: currentValue.seniority,
      nonOperational: currentValue.nonOperational
    });
    if (label && value) {
      setFilter([{ label, value }]);
    } else {
      setFilter([]);
    }
  };

  const onNonOperationalChange = nonOperational => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({
      type: currentValue.type,
      company: currentLabel.company,
      title: currentLabel.title,
      seniority: currentLabel.seniority,
      nonOperational: nonOperational ? 'Include non-operational' : ''
    });
    const value = makeValue({
      type: currentValue.type,
      company: currentValue.company,
      team: currentValue.team,
      view: currentValue.view,
      title: currentValue.title,
      seniority: currentValue.seniority,
      nonOperational
    });
    if (label && value) {
      setFilter([{ label, value }]);
    } else {
      setFilter([]);
    }
  }

  useDeepCompareEffect(() => {
    const value = getValue();
    if (isEmptyValue(value) && value.nonOperational) { onNonOperationalChange(false); }
  }, [filter]);

  useDeepCompareEffect(() => {
    setFilter(filters[positionFilterKey]?.[0]?.value ? [{
      label: filters[positionFilterKey][0].label,
      value: filters[positionFilterKey][0].value
    }] : []);
  }, [filters]);

  useEffect(() => {
    if (visible) {
      setFilter(filters[positionFilterKey]?.[0]?.value ? [{
        label: filters[positionFilterKey][0].label,
        value: filters[positionFilterKey][0].value
      }] : []);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible]);

  const toCompanyOption = ({ label, value }) => ({
    label: `${label} (${value})`,
    value
  });

  const fromCompanyOption = ({ label, value }) => ({
    label: label.replace(` (${value})`, ''),
    value
  });

  const getSettings = () => {
    const label = getLabel();
    const value = getValue();
    const settings = {
      type: value.type,
      companyItems: [],
      team: value.team,
      view: value.view,
      seniorityItems: [],
      title: value.title,
      nonOperational: !!value.nonOperational,
      nonOperationalDisabled: false
    };

    if (value.type === Type.SPECIFIC && label.company && value.company) {
      const labels = label.company.split(LIST_DELIMITER);
      settings.companyItems = value.company.map((value, i) => ({ label: labels[i], value }));
    }

    if (value.seniority) {
      settings.seniorityItems = value.seniority.map(value => ({ label: peopleSeniorityOptions.find(option => option.value === value)?.label, value }));
    }

    if (isEmptyValue(value)) { settings.nonOperationalDisabled = true; }

    return settings;
  };

  const canApply = () => filter[0]?.value !== filters[positionFilterKey]?.[0]?.value;

  const onTypeChange = type => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({
      type,
      title: currentLabel.title,
      seniority: currentLabel.seniority,
      nonOperational: currentLabel.nonOperational
    });
    const value = makeValue({
      type,
      team: SpecialTeamId.ME,
      view: TEAM_VIEW_NONE,
      title: currentValue.title,
      seniority: currentValue.seniority,
      nonOperational: currentValue.nonOperational
    });
    if (label && value) {
      setFilter([{ label, value }]);
    } else {
      setFilter([]);
    }
  };

  const onCompanyChange = company => {
    const options = company.length ? company.map(c => fromCompanyOption(c)) : undefined;
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({
      type: Type.SPECIFIC,
      company: options?.map(({ label }) => label)?.join(LIST_DELIMITER),
      title: currentLabel.title,
      seniority: currentLabel.seniority,
      nonOperational: currentLabel.nonOperational
    });
    const value = makeValue({
      type: Type.SPECIFIC,
      company: options?.map(({ value }) => value),
      team: currentValue.team,
      view: currentValue.view,
      title: currentValue.title,
      seniority: currentValue.seniority,
      nonOperational: currentValue.nonOperational
    });
    if (label && value) {
      setFilter([{ label, value }]);
    } else {
      setFilter([]);
    }
  };

  const onTeamChange = team => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({
      type: currentValue.type,
      company: currentLabel.company,
      title: currentLabel.title,
      seniority: currentLabel.seniority,
      nonOperational: currentLabel.nonOperational
    });
    const value = makeValue({
      type: currentValue.type,
      company: currentValue.company,
      team,
      view: TEAM_VIEW_NONE,
      title: currentValue.title,
      seniority: currentValue.seniority,
      nonOperational: currentValue.nonOperational
    });
    if (label && value) {
      setFilter([{ label, value }]);
    } else {
      setFilter([]);
    }
  };

  const onViewChange = view => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({
      type: currentValue.type,
      company: currentLabel.company,
      title: currentLabel.title,
      seniority: currentLabel.seniority,
      nonOperational: currentLabel.nonOperational
    });
    const value = makeValue({
      type: currentValue.type,
      company: currentValue.company,
      team: currentValue.team,
      view,
      title: currentValue.title,
      seniority: currentValue.seniority,
      nonOperational: currentValue.nonOperational
    });
    if (label && value) {
      setFilter([{ label, value }]);
    } else {
      setFilter([]);
    }
  };

  const onSeniorityChange = seniority => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({
      type: currentValue.type,
      company: currentLabel.company,
      title: currentLabel.title,
      seniority: seniority.map(({ label }) => label).join(LIST_DELIMITER),
      nonOperational: currentLabel.nonOperational
    });
    const value = makeValue({
      type: currentValue.type,
      company: currentValue.company,
      team: currentValue.team,
      view: currentValue.view,
      title: currentValue.title,
      seniority: seniority.map(({ value }) => value),
      nonOperational: currentValue.nonOperational
    });
    if (label && value) {
      setFilter([{ label, value }]);
    } else {
      setFilter([]);
    }
  };

  const onApply = () => addToFilters({ [positionFilterKey]: filter });

  const settings = getSettings();

  return (
    <div
      className={styles.positionControls}
      onScroll={stopPropagation}
    >
      <div className={styles.heading}>{label}</div>
      <Select
        className={styles.select}
        options={getTypeOptions()}
        value={settings.type}
        onChange={onTypeChange}
      />
      {settings.type === Type.SPECIFIC ? (
        <>
          <div className={styles.companyLabel}>Company</div>
          <SearchableSelect
            className={styles.selector}
            mode="multiple"
            items={settings.companyItems}
            search={companySearch}
            toOption={toCompanyOption}
            onChange={onCompanyChange}
          />
        </>
      ) : null}
      {settings.type === Type.VIEW ? (
        <>
          <div className={styles.teamLabel}>Team:</div>
          <Select
            className={styles.selector}
            options={getTeamOptions(teamViews)}
            value={{ label: settings.team, value: settings.team }}
            labelInValue={true}
            onChange={({ value }) => onTeamChange(value)}
          />
          <div className={styles.viewLabel}>View:</div>
          <SearchableSelect
            className={styles.selector}
            items={[{ label: getTeamViewLabel(settings.team, lsvpUser, settings.view), value: settings.view }]}
            staticOptions={getTeamViewOptions(teamViews, settings.team, lsvpUser)}
            onChange={([{ value }]) => onViewChange(value)}
          />
        </>
      ) : null}
      {seniorityFilter ? (
        <>
          <div className={styles.seniorityLabel}>Seniority</div>
          <SearchableSelect
            mode="multiple"
            items={settings.seniorityItems}
            staticOptions={peopleSeniorityOptions}
            onChange={onSeniorityChange}
          />
        </>
      ) : null}
      <div className={styles.titleLabel}>Title</div>
      <Input
        className={styles.title}
        value={settings.title}
        placeholder="Search"
        onChange={event => onTitleChange(event.target.value)}
      />
      {nonOperationalFilter ? (
        <Checkbox
          className={styles.nonOperationalCheckbox}
          checked={settings.nonOperational}
          disabled={settings.nonOperationalDisabled}
          onChange={e => onNonOperationalChange(e.target.checked)}
        >
          Include non-operational
        </Checkbox>
      ) : null}
      <div className={styles.buttons}>
        <Button 
          type="primary"
          disabled={!canApply()}
          onClick={onApply}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
