import { axiosInstance } from './index';

const searchByKeyword = async (params, signal) => {
  return await axiosInstance.get('search', {
    params,
    signal,
    handlerEnabled: true
  });
};

const searchByKeywordLanding = async (params, signal) => {
  return await axiosInstance.get('search-landing', {
    params,
    signal,
    handlerEnabled: true
  });
};

export default {
  searchByKeyword,
  searchByKeywordLanding
};
