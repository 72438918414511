import { axiosInstance } from '../index';

export const fetchCompanyPeople = async companyId => {
  return axiosInstance.get(`crm/companies/${companyId}/people`, {
    handlerEnabled: true
  });
};

export const addCompanyPerson = async (companyId, email, domain) => {
  return axiosInstance.post(`crm/companies/${companyId}/person`, {
    email,
    domain
  });
};

export default {
  fetchCompanyPeople,
  addCompanyPerson
};

