import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/people/list';
import * as Actions from '../../actions/people/list';
import updateLoader from '../../actions/Loader';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { PeopleListAction } from '../../types/people/list';

export function* peopleList({ payload }) {
  try {
    yield put(updateLoader({ listLoader: true }));
    const response = yield call(API.getPeopleList, payload);
    yield put(Actions.setPeopleList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ listLoader: false }));
  }
}

export function* morePeopleList({ payload }) {
  try {
    const response = yield call(API.getPeopleList, payload);
    yield put(Actions.setMorePeopleList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* downloadPeopleList({ payload }) {
  try {
    yield put(updateLoader({ csvLoader: true }));
    const { downloadResolver, params } = payload;
    const response = yield call(API.downloadPeopleList, params);
    downloadResolver(response?.data?.data);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ csvLoader: false }));
  }
}

export function* peopleListSaga() {
  yield takeLatest(PeopleListAction.GET_PEOPLE_LIST, peopleList);
  yield takeLatest(PeopleListAction.GET_MORE_PEOPLE_LIST, morePeopleList);
  yield takeLatest(PeopleListAction.DOWNLOAD_PEOPLE_CSV, downloadPeopleList);
}
