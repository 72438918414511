import { FundingRoundOverride } from '../../types/funding/override';

export const overrideFundingRoundCoverage = payload => ({
  type: FundingRoundOverride.OVERRIDE_FUNDING_ROUND_COVERAGE,
  payload
});

export const overrideFundingRoundStage = payload => ({
  type: FundingRoundOverride.OVERRIDE_FUNDING_ROUND_STAGE,
  payload
});

export const localOverrideFundingRoundCoverage = payload => ({
  type: FundingRoundOverride.LOCAL_OVERRIDE_FUNDING_ROUND_COVERAGE,
  payload
});

export const localOverrideFundingRoundStage = payload => ({
  type: FundingRoundOverride.LOCAL_OVERRIDE_FUNDING_ROUND_STAGE,
  payload
});
