import { axiosInstance } from '../index';

const createPresentation = async ({presentationId, entityIds}) => {
  const body = { presentationId, entityIds };
  return await axiosInstance.post(`presentation`, body);
};

const getPresentation = async ({presentationId}) => {
  return await axiosInstance.get(`presentation/${presentationId}`, { handlerEnabled: true });
};

export default {
  createPresentation,
  getPresentation
};
