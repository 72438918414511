import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/crm/entity';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CrmEntityAction } from '../../types/crm/entity';
import {
  setEntityNotes,
  getEntityNotes,
  setEntityInteractions,
  setMoreEntityInteractions
} from '../../actions/crm/entity';
import updateLoader from '../../actions/Loader';

export function* fetchEntityNotes({ payload }) {
  try {
    yield put(updateLoader({ notesLoader: true }));
    const { entityId, entityType } = payload;
    const response = yield call(API.fetchEntityNotes, entityId, entityType);
    yield put(setEntityNotes({ id: entityId, notes: response.data.data }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ notesLoader: false }));
  }
}

export function* createEntityNote({ payload }) {
  try {
    yield put(updateLoader({ notesLoader: true }));
    const { entityId, entityType, content, creatorId, mentionList } = payload;
    const response = yield call(
      API.createEntityNote,
      entityId,
      entityType,
      content,
      creatorId,
      mentionList
    );
    if (response?.status === 201)
      yield put(getEntityNotes(entityId, entityType));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ notesLoader: false }));
  }
}

export function* updateEntityNote({ payload }) {
  try {
    yield put(updateLoader({ notesLoader: true }));
    const { entityId, entityType, noteId, content } = payload;

    const response = yield call(API.updatehEntityNote, noteId, content);
    if (response?.status === 201)
      yield put(getEntityNotes(entityId, entityType));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ notesLoader: false }));
  }
}

export function* deleteEntityNote({ payload }) {
  try {
    yield put(updateLoader({ notesLoader: true }));
    const { entityId, entityType, noteId } = payload;

    const response = yield call(API.deleteEntityNote, noteId);
    if (response?.status === 201)
      yield put(getEntityNotes(entityId, entityType));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ notesLoader: false }));
  }
}

export function* getEntityInteractions({ payload }) {
  try {
    yield put(updateLoader({ interactionLoader: true }));
    const response = yield call(API.getEntityInteractions, payload);
    yield put(setEntityInteractions({ id: payload.companyId, interactions: response.data.data }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ interactionLoader: false }));
  }
}

export function* getMoreEntityInteractions({ payload }) {
  try {
    const response = yield call(API.getEntityInteractions, payload);
    yield put(setMoreEntityInteractions({ id: payload.companyId, interactions: response.data.data }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* crmEntitySaga() {
  yield takeLatest(CrmEntityAction.GET_ENTITY_NOTES, fetchEntityNotes);
  yield takeLatest(CrmEntityAction.CREATE_ENTITY_NOTE, createEntityNote);
  yield takeLatest(CrmEntityAction.UPDATE_ENTITY_NOTE, updateEntityNote);
  yield takeLatest(CrmEntityAction.DELETE_ENTITY_NOTE, deleteEntityNote);
  yield takeLatest(CrmEntityAction.GET_ENTITY_INTERACTIONS, getEntityInteractions);
  yield takeLatest(CrmEntityAction.GET_MORE_ENTITY_INTERACTIONS, getMoreEntityInteractions);
}
