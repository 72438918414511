import { call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../../../Network/Apis/company/flag';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CompanyFlagAction } from '../../types/company/flag';
import { GlobalSearchAction } from '../../types/filter/globalSearch';
import { getCompaniesListResponse } from '../../actions/company/list';
import { setCompaniesSearchList } from '../../actions/filter/globalSearch';

const addFlagToCompany = (companiesList, companyFlag, message) => ({
  ...companiesList,
  message,
  companies: companiesList.companies.map(company => company.id === companyFlag.company.id ? {
    ...company, flag: companyFlag
  } : company)
});

function* setCompanyFlag(companyFlag, message) {
  const companiesList = yield select(state => state.companiesList);
  const updatedCompaniesList = addFlagToCompany(
    companiesList,
    companyFlag,
    message
  );
  yield put(getCompaniesListResponse(updatedCompaniesList));
}

function* setGlobalSearchCompanyFlag(companyFlag, message) {
  const companiesState = yield select(state => state.globalSearch.companiesState);
  const updatedCompaniesList = addFlagToCompany(
    companiesState,
    companyFlag,
    message
  );
  yield put(setCompaniesSearchList(updatedCompaniesList));
}

export function* companyFlag({ type, payload }) {
  try {
    const response = yield call( API.getCompanyFlag, payload.companyId);
    const { companyFlag, message } = response.data.data;
    switch (type) {
      case CompanyFlagAction.GET_COMPANY_FLAG:
        yield setCompanyFlag(companyFlag, message);
        break;
      case GlobalSearchAction.GET_SEARCH_COMPANY_FLAG:
        yield setGlobalSearchCompanyFlag(companyFlag, message);
        break;
      default:
        dispatchSnackbarError(`Unexpected action type: ${type}`);
        break;
    }
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* companyFlagSaga() {
  yield takeEvery(
    [
      CompanyFlagAction.GET_COMPANY_FLAG,
      GlobalSearchAction.GET_SEARCH_COMPANY_FLAG
    ],
    companyFlag
  );
}
