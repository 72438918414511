import * as types from '../types';

const INITIAL_STATE = {
  listLoader: false,
  globalSearchCompaniesLoader: false,
  globalSearchPeopleLoader: false,
  csvLoader: false,
  peopleProfileLoader: false,
  notesLoader: false,
  interactionLoader: false,
  // company profile loaders
  loadingLeaderships: false,
  loadingCompetitors: false,
  loadingFundingrounds: false,
  loadingTopRatedEmployees: false,
  statusModalLoading: false,
  global: false
};

const loader = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_LOADER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default loader;
