import React from 'react';
import LoadingBars from '../../Assets/images/LoadingBars.svg';
import './CsvLoadingBar.scss';

function CsvLoadingBar() {
  return (
    <>
      {
        <div className="loading-bar-wrapper">
          <span>Downloading…</span>
          <img src={LoadingBars} alt="Loading Bars" />
          <span>
            May take more
            <br />
            than 30 seconds
          </span>
        </div>
      }
    </>
  );
}

export default CsvLoadingBar;
