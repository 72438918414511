import _ from 'lodash';
import { axiosInstance } from '../index';

const addAction = async ({
  entityId,
  entityType,
  actionType,
  teamId,
  userId,
  comment,
  details,
  graphs,
  flag,
  destinationType,
  personId
}) => {
  const body = {
    entityId,
    entityType,
    actionType,
    teamId,
    userId,
    graphs,
    flag,
    destinationType,
    personId
  };
  if (!_.isEmpty(comment)) {
    body.comment = comment;
  }
  if (!_.isEmpty(details)) {
    body.details = details;
  }
  return await axiosInstance.post(`action`, body);
};

const removeAction = async params => {
  return await axiosInstance.delete(`action`, { data: params });
};

const changeAction = async ({
  entityId,
  entityType,
  actionType,
  teamId,
  userId,
  comment,
  details
}) => {
  const body = {
    entityId,
    entityType,
    actionType,
    teamId,
    userId
  };
  if (!_.isEmpty(comment)) {
    body.comment = comment;
  }
  if (!_.isEmpty(details)) {
    body.details = details;
  }
  return await axiosInstance.put(`action`, body);
};

export default {
  addAction,
  removeAction,
  changeAction
};
