import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/lsvp-user/actions';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { LsvpUserAction } from '../../types/lsvp-user';
import { localAssignLSVPUsersToEntity, localUnassignLSVPUsersFromEntity } from '../../actions/lsvp-user/actions';

export function* assignLSVPUsersToEntity({ payload }) {
  try {
    yield call(API.assignLSVPUsersToEntity, payload);
    yield put(localAssignLSVPUsersToEntity(payload));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}
export function* unassignLSVPUsersFromEntity({ payload }) {
  try {
    yield call(API.unassignLSVPUsersFromEntity, payload);
    yield put(localUnassignLSVPUsersFromEntity(payload));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* lsvpUserActionsSaga() {
  yield takeLatest(
    LsvpUserAction.ASSIGN_LSVP_USERS_TO_ENTITY,
    assignLSVPUsersToEntity
  );
  yield takeLatest(
    LsvpUserAction.UNASSIGN_LSVP_USERS_FROM_ENTITY,
    unassignLSVPUsersFromEntity
  );
}
