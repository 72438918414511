import { AutoFlagAction } from '../../types/auto';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AutoFlagAction.SET_AUTO_FLAGS:
      return action.payload.autoFlags;
    default:
      return state;
  }
};
