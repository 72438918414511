import { PresentAction } from '../../types/present';

const INITIAL_STATE = {
  presentationId: null,
  entityIds: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PresentAction.SET_PRESENTATION:
      return {
        ...state, 
        presentationId: action.payload.presentationId ,
        entityIds: action.payload.entityIds 
      };
    default:
      return state;
  }
};
