import { CompanyProfileAction } from '../../types/company/profile';

export const getCompanyProfileRequest = payload => ({
  type: CompanyProfileAction.GET_COMPANY_PROFILE_REQUEST,
  payload
});

export const getCompanyProfileResponse = payload => ({
  type: CompanyProfileAction.GET_COMPANY_PROFILE_RECEIVE,
  payload
});
