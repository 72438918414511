import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import _ from 'lodash';
import { formatNumber } from '../../Utils/NumberFormatter';
import { noneFilterValue } from '../../Utils/Constants';
import Range from './Range';
import styles from './RangeControls.module.scss';

export default ({
  filters,
  filterKey,
  range,
  label,
  orNone,
  addToFilters
}) => {
  const getValues = () => {
    const mm = filters[filterKey]?.filter(({ value }) => value !== noneFilterValue)?.[0]?.value.split('-');
    if (!mm) { return { min: 'any', max: 'any', none: false }; }
    const none = filters[filterKey]?.find(({ value }) => value === noneFilterValue);
    return { min: mm[0], max: mm[1], none: !!none };
  };

  const [min, setMin] = useState(getValues().min);
  const [max, setMax] = useState(getValues().max);
  const [none, setNone] = useState(getValues().none);

  useEffect(() => {
    const values = getValues();
    setMin(values.min);
    setMax(values.max);
    setNone(values.none);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters]);

  const isAny = value => !value || value === 'any';

  const getFilter = (lo, hi, no) => {
    if (isAny(lo) && isAny(hi)) { return []; }
    const filter = [{
      label: `${isAny(lo) ? 'Any' : formatNumber({ amount: lo })}-${isAny(hi) ? 'Any' : formatNumber({ amount: hi })}`,
      value: `${isAny(lo) ? '' : lo}-${isAny(hi) ? '' : hi}`
    }];
    if (no) { filter.push({ label: 'None', value: noneFilterValue}); }
    return filter;
  };

  const save = (lo, hi, no) => addToFilters({ [filterKey]: getFilter(lo, hi, no) });

  useEffect(() => {
    if (_.isEqual(getFilter(min, max, none), filters[filterKey])) { return; }
    const timeout = setTimeout(() => save(min, max, none), 1000);
    return () => clearTimeout(timeout);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [min, max, none]);

  const onChangeNone = e => setNone(e.target.checked);

  return (
    <div className={styles.rangeControls}>
      <div className={styles.heading}>{label}</div>
      <Range
        min={min}
        max={max}
        range={range}
        onChangeMin={setMin}
        onChangeMax={setMax}
      />
      {orNone ? (
        <Checkbox
          className={styles.noneCheckbox}
          checked={none}
          disabled={!filters[filterKey]?.length}
          onChange={onChangeNone}
        >
          {`Include no ${label.toLowerCase()}`}
        </Checkbox>
      ) : null}
    </div>
  );
};
