import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Summary from './Summary';
import tableStyles from '../../Shared/Table.module.scss';

export default ({ person, team, options }) => {
  if (person.summary) {
    return (
      <Summary person={person} team={team} options={options} />
    );
  } else {
    return (
      <div className={tableStyles.expandedRowSpinner}>
        <Spin indicator={<LoadingOutlined spin />} />
      </div>
    );
  }
};
