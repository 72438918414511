import { PipelineListAction } from '../../types/pipeline/list';
import { CompanyDetailsAction } from '../../types/company/details';
import { COMPANIES_INITIAL_STATE } from '../company/list';

export default (state = COMPANIES_INITIAL_STATE, action) => {
  switch (action.type) {
    case PipelineListAction.GET_PIPELINE_LIST_RECEIVE:
      return {
        ...state,
        ...action.payload
      };

    case PipelineListAction.GET_MORE_PIPELINE_LIST_REQUEST:
      return {
        ...state,
        loadingMore: true
      };

    case PipelineListAction.GET_MORE_PIPELINE_LIST_RECEIVE:
      return {
        ...state,
        companies: [...state.companies, ...(action.payload?.companies || [])],
        loadingMore: false
      };

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_SECTOR_IC:
      return {
        ...state,
        companies: state.companies.map(company => company.id === action.payload.companyId ? { ...company, sectorIc: action.payload.newSectorIc } : company)
      };

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_PROJECTED_RAISE_DATE:
      return {
        ...state,
        companies: state.companies.map(company => company.id === action.payload.companyId ? { ...company, projectedRaiseDate: action.payload.newProjectedRaiseDate } : company)
      };

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_FINANCIALS:
      return {
        ...state,
        companies: state.companies.map(company => company.id === action.payload.companyId ? { ...company, financials: action.payload.newFinancials } : company)
      };

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_NEXT_STEP:
      return {
        ...state,
        companies: state.companies.map(company => company.id === action.payload.companyId ? { ...company, nextStep: action.payload.newNextStep } : company)
      };

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_STATUS:
      return {
        ...state,
        companies: state.companies.map(company => company.id === action.payload.companyId ? { ...company, status: action.payload.newStatus } : company)
      };

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_URGENT:
      return {
        ...state,
        companies: state.companies.map(company => company.id === action.payload.companyId ? { ...company, urgent: action.payload.newUrgent } : company)
      };

    default:
      return state;
  }
};
