import { TeamMemberAction } from '../../types/team/teamMembers';

export const getTeamMembers = payload => ({
  type: TeamMemberAction.GET_TEAM_MEMBERS,
  payload
});

export const setTeamMembers = payload => ({
  type: TeamMemberAction.SET_TEAM_MEMBERS,
  payload
});

