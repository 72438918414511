import React from 'react';
import { Tooltip } from 'antd';
import {
  roundDecimal,
  hasValue
} from '../../../Utils/Shared';
import {
  anyFilterValue,
  growthMetricOptions
} from '../../../Utils/Constants';

import sharedStyles from '../../Shared/Shared.module.scss';

export default ({ company, growth }) => {
  let result = hasValue(company.growth) ? roundDecimal(company.growth) : <strong>N/A</strong>;
  let tooltip;

  if (growth?.[0]?.value === anyFilterValue) {
    const option = growthMetricOptions.find(({ value }) => company[value] === company.max_growth)
    if (option) {
      tooltip = option.label;
    }
  }

  if (tooltip) {
    result =  (
      <Tooltip
        placement="top"
        overlayClassName={sharedStyles.tooltip}
        title={
          <span>{tooltip}</span>
        }
      >
        {result}
      </Tooltip>
    );
  }

  return result;
};
