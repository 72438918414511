import React from 'react';
import SearchableSelect from '../Shared/SearchableSelect';
import { stopPropagation } from '../../Utils/Shared';
import FilterSearchAPI from '../../Network/Apis/filterSearch';
import {
  partnerInteractionTimeOptions,
  partnerInteractionTypeOptions,
  SearchIndex
} from '../../Utils/Constants';
import styles from './PartnerInteractionControls.module.scss';

const FIELD_DELIMITER = '; ';
const LIST_DELIMITER = ', ';

const userSearch = {
  searchFunction: FilterSearchAPI.searchByKeyword,
  params: { index: SearchIndex.LSVP_USER }
};

export default ({ filters, filterKey, label, addToFilters }) => {
  const getValue = () => {
    try {
      return JSON.parse(filters[filterKey]?.[0]?.value);
    } catch (e) {
      return {};
    }
  };

  const getLabel = () => {
    const label = {};
    const { partner, time, interaction } = getValue();
    if (partner) {
      const l = filters[filterKey][0].label;
      label.partner = l.includes(FIELD_DELIMITER) ? l.split(FIELD_DELIMITER)?.[0] : l;
    }
    if (time) {
      label.time = time.map(value => partnerInteractionTimeOptions.find(option => option.value === value)?.label).join(LIST_DELIMITER);
    }
    if (interaction) {
      label.interaction = interaction.map(value => partnerInteractionTypeOptions.find(option => option.value === value)?.label).join(LIST_DELIMITER);
    }
    return label;
  };

  const makeValue = ({ partner, time, interaction }) =>  {
    if (!partner && !time && !interaction) { return undefined; }
    const value = {};
    if (partner?.length) { value.partner = partner; }
    if (time?.length) { value.time = time; }
    if (interaction?.length) { value.interaction = interaction; }
    return JSON.stringify(value);
  };

  const makeLabel = ({ partner, time, interaction }) => {
    const labels = [];
    if (partner) { labels.push(partner); }
    if (time) { labels.push(time); }
    if (interaction) { labels.push(interaction); }
    return labels.join(FIELD_DELIMITER);
  };

  const onPartnerChange = partner => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({ partner: partner.map(({ label }) => label).join(LIST_DELIMITER), time: currentLabel.time, interaction: currentLabel.interaction });
    const value = makeValue({ partner: partner.map(({ value }) => value), time: currentValue.time, interaction: currentValue.interaction });
    if (label && value) {
      addToFilters({ [filterKey]: [{ label, value }] });
    } else {
      addToFilters({ [filterKey]: [] });
    }
  };

  const onTimeChange = time => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({ partner: currentLabel.partner, time: time.map(({ label }) => label).join(LIST_DELIMITER), interaction: currentLabel.interaction });
    const value = makeValue({ partner: currentValue.partner, time: time.map(({ value }) => value), interaction: currentValue.interaction });
    if (label && value) {
      addToFilters({ [filterKey]: [{ label, value }] });
    } else {
      addToFilters({ [filterKey]: [] });
    }
  };

  const onInteractionChange = interaction => {
    const currentLabel = getLabel();
    const currentValue = getValue();
    const label = makeLabel({ partner: currentLabel.partner, time: currentLabel.time, interaction: interaction.map(({ label }) => label).join(LIST_DELIMITER) });
    const value = makeValue({ partner: currentValue.partner, time: currentValue.time, interaction: interaction.map(({ value }) => value) });
    if (label && value) {
      addToFilters({ [filterKey]: [{ label, value }] });
    } else {
      addToFilters({ [filterKey]: [] });
    }
  };

  const getItems = () => {
    const items = { partner: [], time: [], interaction: [] };
    const label = getLabel();
    const value = getValue();
    if (label.partner && value.partner) {
      const labels = label.partner.split(LIST_DELIMITER);
      items.partner = value.partner.map((value, i) => ({ label: labels[i], value }));
    }
    if (label.time && value.time) {
      items.time = value.time.map(value => ({ label: partnerInteractionTimeOptions.find(option => option.value === value)?.label, value }));
    }
    if (label.interaction && value.interaction) {
      items.interaction = value.interaction.map(value => ({ label: partnerInteractionTypeOptions.find(option => option.value === value)?.label, value }));
    }
    return items;
  };

  const items = getItems();

  return (
    <div
      className={styles.partnerInteractionControls}
      onScroll={stopPropagation}
    >
      <div className={styles.heading}>{label}</div>
      <div className={styles.label}>Partner</div>
      <SearchableSelect
        mode="multiple"
        items={items.partner}
        search={userSearch}
        onChange={onPartnerChange}
      />
      <div className={styles.label}>Time</div>
      <SearchableSelect
        mode="multiple"
        items={items.time}
        staticOptions={partnerInteractionTimeOptions}
        onChange={onTimeChange}
      />
      <div className={styles.label}>Interaction</div>
      <SearchableSelect
        mode="multiple"
        items={items.interaction}
        staticOptions={partnerInteractionTypeOptions}
        onChange={onInteractionChange}
      />
    </div>
  );
};
