const continents = new Set([
  'Europe',
  'Asia',
  'Oceania',
  'Africa',
  'South America'
]);

const countries = new Set([
  'United States'
]);

const states = new Set([
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]);

export const narrow = regions => {
  const places = [continents, countries, states];
  while (regions.length > 1) {
    const before = regions.length;
    for (const place of places) {
      regions = regions.filter(region => !place.has(region));
      if (regions.length < before) { continue; }
    }
    break;
  }
  return regions.slice(0, 3);
};