import { axiosInstance } from '../index';

const getFlagList = async params => {
  return await axiosInstance.get('flags', { params, handlerEnabled: true });
};

const getFlagCount = async params => {
  return await axiosInstance.get('flags/count', {
    params,
    handlerEnabled: true
  });
};

const downloadFlagList = async (params = {}) => {
  return await axiosInstance.get(`flags/csv`, {
    params,
    handlerEnabled: true
  });
};


export default {
  getFlagList, 
  getFlagCount,
  downloadFlagList
};
