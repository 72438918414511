import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import moment from 'moment';
import {
  renderLocation,
  formatDate
} from '../../../Utils/Shared';
import CompanyInformation from '../../CompanyProfile/CompanyInfo/CompanyInformation';
import CompanySocialLinks from '../../CompanyProfile/CompanyInfo/CompanySocialLinks';
import EmployeeSummary from '../../CompanyProfile/EmployeeSummary/EmployeeSummary';
import MiniAreaChart from '../../Shared/MiniAreaChart';
import { formatMoney } from '../../../Utils/MoneyFormatter';
import { formatNumber } from '../../../Utils/NumberFormatter';
import { graphInfo } from '../../../Utils/Graph';
import NoteItem from '../../Shared/Notes/NoteItem';
import ReplyNoteItem from '../../Shared/Notes/ReplyNoteItem';
import { getAllLSVPUsers } from '../../../Store/actions/lsvp-user/list';
import LastInteractionDate from '../../Shared/LastInteractionDate';
import AffinityId from '../../Shared/AffinityId';
import { EntityType } from '../../../Utils/Constants';
import styles from './CompanyExpandedRow.module.scss';
import tableStyles from '../../Shared/Table.module.scss';

export default ({ company }) => {
  const [notes, setNotes] = useState([]);
  const [replyNotes, setReplyNotes] = useState([]);
  const users = useSelector(state => state.lsvpUsersList.users);
  const dispatch = useDispatch();
  const allNotes = company.summary?.notes;

  useEffect(() => {
    if (allNotes) {
      setNotes(allNotes.filter(note => !note.parentId).slice(0, 2));
      setReplyNotes(allNotes.filter(note => note.parentId));
    }
  }, [allNotes]);

  useEffect(() => {
    if (!users.length) {
      dispatch(getAllLSVPUsers());
    }
  }, [users, dispatch]);

  const mapUsersToAcceptableFormat = () => {
    return (
      users && users.map(user => {
        return {
          id: user.crmUserId,
          display: `${user.firstName} ${user.lastName}`
        };
      })
    );
  };

  const renderInvestors = investors => investors.map(({ name, isNotableInvestor }, index) => (
    <span
      key={index}
      className={isNotableInvestor ? styles.notable : ''}
    >
      {name}{index < investors.length - 1 ? ', ' : ''}
    </span>
  ));

  const renderFundingRounds = () => company.summary.fundingRounds.slice(0, 3).map(({ announced, name, roundAmountDollar, investors, postMoneyValuationDollar }) => {
    const investorList = [];
    const leadInvestors = investors.filter(({ isLeadInvestor }) => isLeadInvestor);
    const notableInvestors = investors.filter(({ isLeadInvestor, isNotableInvestor}) => !isLeadInvestor && isNotableInvestor);
    if (leadInvestors.length) {
      investorList.push(
        <div>
          <span>Lead Investor(s): </span>
          {renderInvestors(leadInvestors)}
        </div>
      );
    } else {
      investorList.push(
        <div>No Lead Investors</div>
      );
    }
    if (notableInvestors.length) {
      investorList.push(
        <div>
          <span>Notable Participating: </span>
          {renderInvestors(notableInvestors)}
        </div>
      );
    }
    const postMoney = postMoneyValuationDollar ? (
      <div>Post-Money: {formatMoney({ amount: postMoneyValuationDollar, precision: 1 })}</div>
    ) : null;
    return (
      <>
        <div>{`${formatDate(announced, 'MMM YYYY')} ${name} ${roundAmountDollar ? formatMoney({ amount: roundAmountDollar, precision: 1 }) : ''}`}</div>
        {investorList}
        {postMoney}
      </>
    );
  });

  const getGraphPoints = () => {
    const points = {};
    if (company.summary.graphs) {
      for (const graph of company.summary.graphs) {
        if (graph.points?.length) {
          points[graph.name] = graph.points;
        }
      }
    }
    return points;
  };

  const renderIndustries = () => company.industries?.map(industry => (
    <div>{industry}</div>
  ));

  const renderNotes = () => notes.map(note => (
    <>
      <NoteItem
        noteId={note?.id}
        noteCharacterName={note?.creatorName || 'Anonymous'}
        noteDate={moment(note?.createdAt).format('MMM D, YYYY')}
        noteContent={note?.content}
        getLsvpUsers={mapUsersToAcceptableFormat}
        readonly
        small
      />
      {replyNotes
        .filter(note => note.parent_id === note.id)
        .map(replyNote => {
          return (
            <ReplyNoteItem
              replyNoteCharacterName={replyNote?.creatorName || 'Anonymous'}
              replyNoteDate={moment(replyNote?.createdAt).format('MMM D, YYYY')}
              replyNoteContent={replyNote?.content}
              small
            />
          );
        })}
    </>
  ));

  const renderInteraction = key => (
    company.summary[key] ? (
      <>
        <LastInteractionDate date={company.summary[key].date} />
        {company.summary[key].people?.length ? (
          <span>{`with ${company.summary[key].people.join(', ')}`}</span>
        ) : null}
      </>
    ) : null
  );

  const renderGraph = ({ key, title, color, points }) => (
    <MiniAreaChart
      key={key}
      title={title}
      height={100}
      points={points}
      borderColor={color}
      format={v => formatNumber({ amount: v, precision: 1 })}
    />
  );

  const renderGraphs = () => {
    const points = getGraphPoints();
    const graphs = graphInfo.map(
      graph => ({ ...graph, points: points[graph.key] })
    ).filter(
      ({ points }) => points
    ).sort(({ key: k1 }, { key: k2 }) => {
      const v1 = company.summary.growthScores[k1] ?? 0;
      const v2 = company.summary.growthScores[k2] ?? 0;
      if (v1 > v2) { return -1; }
      if (v1 < v2) { return 1; }
      return 0;
    });

    const numInColumnOne = Math.round(graphs.length / 2);
    const columns = [];
    if (numInColumnOne > 0) {
      columns.push(
        <div key="columnOne">
          {graphs.slice(0, numInColumnOne).map(renderGraph)}
        </div>
      )
    }
    if (graphs.length - numInColumnOne > 0) {
      columns.push(
        <div key="columnTwo">
          {graphs.slice(numInColumnOne).map(renderGraph)}
        </div>
      )
    }
    return columns;
  };

  if (company.summary) {
    return (
      <div className={styles.companyExpandedRow}>
        <div className={styles.columnOne}>
          <CompanySocialLinks
            socialMediaData={company.summary.socialMediaUrls}
            companyWebSiteLink={company.summary.websiteUrl}
            verticalMargin
            smallIcons
          />
          <CompanyInformation
            title="HQ"
            content={renderLocation([
              company.summary.country,
              company.summary.state,
              company.summary.city
            ])}
          />
          <CompanyInformation
            title="Founded On"
            dateFormat="YYYY"
            content={company.summary.foundedOn}
          />
          <CompanyInformation
            className={styles.description}
            title="Description"
            content={company.summary.description}
            viewMore
            multiline
          />
          <CompanyInformation
            title="Total Funding"
            content={company.totalFunding ? formatMoney({ amount: company.totalFunding, precision: 1 }) : 'N/A'}
          />
          <CompanyInformation
            title="Last Email"
            content={renderInteraction('lastEmail')}
            multiline
          />
          <CompanyInformation
            title="Last Meeting"
            content={renderInteraction('lastMeeting')}
            multiline
          />
          <CompanyInformation
            title="Upcoming Meeting"
            content={renderInteraction('upcomingMeeting')}
            multiline
          />
        </div>
        <div className={styles.columnTwo}>
          <CompanyInformation
            title="Funding Rounds"
            className={styles.fundingRound}
            content={renderFundingRounds()}
          />
          <CompanyInformation
            title="Industries"
            className={styles.industry}
            content={renderIndustries()}
          />
        </div>
        <div className={styles.columnThree}>
          {renderGraphs()}
        </div>
        <div className={styles.columnFour}>
          <CompanyInformation
            title="Leadership"
            content={company.summary.leadership.map(leadershipItem => (
              <EmployeeSummary
                photoUrl={leadershipItem.photo_url}
                employeeName={leadershipItem.name}
                jobTitle={leadershipItem.current_position}
                socialMediaUrls={leadershipItem.social_media_links}
                rank={leadershipItem.rank}
              />
            ))}
            className={styles.leadership}
          />
          <CompanyInformation
            title="Notes"
            className={styles.notes}
            content={renderNotes()}
          />
          <AffinityId
            id={company.id}
            entityType={EntityType.COMPANY}
            crmEntityId={company.summary.crmEntityId}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={tableStyles.expandedRowSpinner}>
        <Spin indicator={<LoadingOutlined spin />} />
      </div>
    );
  }
};
