import { axiosInstance } from '../index';

const getTeamViews = async params => {
  return await axiosInstance.get('teams/views', { params, handlerEnabled: true });
};

const saveTeamView = async body => {
  return await axiosInstance.put(`teams/views`, body);
};

const addTeamView = async body => {
  return await axiosInstance.post(`teams/views`, body);
};

const deleteTeamView = async body => {
  return await axiosInstance.delete('teams/views', { data: body });
};

export default {
  getTeamViews,
  saveTeamView,
  addTeamView,
  deleteTeamView
};
