import { LandingAction } from '../../types/landing';

export const getLanding = payload => ({
  type: LandingAction.GET_LANDING,
  payload
});

export const setLanding = payload => ({
  type: LandingAction.SET_LANDING,
  payload
});

export const getLandingFlag = payload => ({
  type: LandingAction.GET_LANDING_FLAG,
  payload
});

export const setLandingFlag = payload => ({
  type: LandingAction.SET_LANDING_FLAG,
  payload
});

export const addLandingCrmInteraction = payload => ({
  type: LandingAction.ADD_LANDING_CRM_INTERACTION,
  payload
});

export const addLandingFlagAction = payload => ({
  type: LandingAction.ADD_LANDING_FLAG_ACTION,
  payload
});