import { CrmListEntryAction } from '../../types/crm/listEntry';
import { all, call, select, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/crm/listEntry';
import { dispatchSnackbarError } from '../../../Utils/Shared';

export function* addEntityToLists({ payload: { entityId, crmEntityId, listsToAddTo } }) {
  try {
    const crmUserId = yield select(state => state.login.lsvpUser.crm_user_id);

    yield all(
      listsToAddTo.map(listId =>
        call(API.addEntityToList, {
          entityId,
          crmEntityId,
          listId,
          crmUserId
        })
      )
    );
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* removeEntryFromLists({ payload: { listsToRemoveFrom } }) {
  try {
    yield all(
      listsToRemoveFrom.map(({ listId, listEntryId }) =>
        call(API.removeEntryFromList, { listId, listEntryId })
      )
    );
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* crmListEntrySaga() {
  yield takeLatest(
    CrmListEntryAction.ADD_CRM_ENTITY_TO_LISTS,
    addEntityToLists
  );
  yield takeLatest(
    CrmListEntryAction.REMOVE_CRM_ENTITY_FROM_LISTS,
    removeEntryFromLists
  );
}
