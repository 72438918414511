import { call, takeEvery, put } from 'redux-saga/effects';
import API from '../../../Network/Apis/flag/action';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { FlagActionAction } from '../../types/flag/action';

export function* addFlagAction({ payload }) {
  try {
    const { filters, postAction, ...rest } = payload;
    yield call(API.addFlagAction, rest);
    if (postAction) { yield put(postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* rerouteFlag({ payload }) {
  try {
    const { filters, postAction, ...rest } = payload;
    yield call(API.rerouteFlag, rest);
    if (postAction) { yield put(postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* unflag({ payload }) {
  try {
    const { filters, postAction, ...rest } = payload;
    yield call(API.unflag, rest);
    if (postAction) { yield put(postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* flagActionSaga() {
  yield takeEvery(FlagActionAction.ADD_FLAG_ACTION, addFlagAction);
  yield takeEvery(FlagActionAction.REROUTE_FLAG, rerouteFlag);
  yield takeEvery(FlagActionAction.UNFLAG, unflag);
}


