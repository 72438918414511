import { call, put, takeEvery, select } from 'redux-saga/effects';
import API from '../../../Network/Apis/company/summary';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CompanySummaryAction } from '../../types/company/summary';
import { GlobalSearchAction } from '../../types/filter/globalSearch';
import { getCompaniesListResponse } from '../../actions/company/list';
import { setCompaniesSearchList } from '../../actions/filter/globalSearch';

const addSummaryToCompany = (companiesList, summary, message) => ({
  ...companiesList,
  message,
  companies: companiesList.companies.map(company => company.id === summary.id ? {
    ...company, summary
  } : company)
});

function* setCompanySummary(summary, message) {
  const companiesList = yield select(state => state.companiesList);
  const updatedCompaniesList = addSummaryToCompany(
    companiesList,
    summary,
    message
  );
  yield put(getCompaniesListResponse(updatedCompaniesList));
}

function* setGlobalSearchCompanySummary(summary, message) {
  const companiesState = yield select( state => state.globalSearch.companiesState);
  const updatedCompaniesList = addSummaryToCompany(
    companiesState,
    summary,
    message
  );
  yield put(setCompaniesSearchList(updatedCompaniesList));
}

export function* companySummary({ type, payload }) {
  try {
    const response = yield call(
      API.getCompanySummary,
      payload.companyId,
      payload.params
    );
    const { companySummary, message } = response.data.data;
    switch (type) {
      case CompanySummaryAction.GET_COMPANY_SUMMARY_REQUEST:
        yield setCompanySummary(companySummary, message);
        break;
      case GlobalSearchAction.GET_SEARCH_COMPANY_SUMMARY:
        yield setGlobalSearchCompanySummary(companySummary, message);
        break;
      default:
        dispatchSnackbarError(`Unexpected action type: ${type}`);
        break;
    }
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* companySummarySaga() {
  yield takeEvery(
    [
      CompanySummaryAction.GET_COMPANY_SUMMARY_REQUEST,
      GlobalSearchAction.GET_SEARCH_COMPANY_SUMMARY
    ],
    companySummary
  );
}
