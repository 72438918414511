import { FundingRoundsList } from '../../types';

export const getFundingRoundsList = filters => ({
  type: FundingRoundsList.GET_FUNDING_ROUNDS_LIST,
  payload: { ...filters }
});

export const setFundingRoundsList = payload => ({
  type: FundingRoundsList.SET_FUNDING_ROUNDS_LIST,
  payload
});

export const getMoreFundingRoundsList = filters => ({
  type: FundingRoundsList.GET_MORE_FUNDING_ROUNDS_LIST,
  payload: { ...filters }
});

export const setMoreFundingRoundsList = ({
  fundingRounds,
  sectors,
  message
}) => ({
  type: FundingRoundsList.SET_MORE_FUNDING_ROUNDS_LIST,
  payload: { fundingRounds, sectors, message }
});

export const updateFundingRoundsList = fundingRounds => ({
  type: FundingRoundsList.UPDATE_FUNDING_ROUNDS_LIST,
  payload: { fundingRounds }
});

export const getFundingRoundsCharts = payload => ({
  type: FundingRoundsList.GET_FUNDING_ROUNDS_CHARTS,
  payload
});

export const setFundingRoundsCharts = payload => ({
  type: FundingRoundsList.SET_FUNDING_ROUNDS_CHARTS,
  payload
});

export const downloadFundingRoundsCSVAction = (downloadResolver, params = {}) => ({
  type: FundingRoundsList.DOWNLOAD_FUNDING_ROUNDS_CSV,
  payload: {
    downloadResolver,
    params
  }
});

export const setFundingRoundsChartsNeedReloading = payload => ({
  type: FundingRoundsList.SET_FUNDING_ROUNDS_CHARTS_NEED_RELOADING,
  payload
});
