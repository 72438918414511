import { axiosInstance } from '../index';

const addEntityToList = async params => {
  return await axiosInstance.post('crm/list-entry', {
    ...params
  });
};

const removeEntryFromList = async body => {
  return await axiosInstance.delete('crm/list-entry', { data: body });
};

export default { addEntityToList, removeEntryFromList };
