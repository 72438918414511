import { TeamViewAction } from '../../types/team/teamViews';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TeamViewAction.SET_TEAM_VIEWS:
      return action.payload.viewType ? {
        ...state, [action.payload.viewType]: action.payload.teamViews
      } : action.payload.teamViews;
    default:
      return state;
  }
};
