import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import _ from 'lodash';
import styles from './SearchableSelect.module.scss';

export default ({
  className,
  mode,
  items,
  search,
  staticOptions,
  combo,
  anyKey = 'any',
  extras = [],
  disabled,
  noUserId = false,
  toOption = _.identity,
  fromOption = _.identity,
  toOptions = _.identity,
  onChange,
  onKeywordChange
}) => {
  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [options, setOptions] = useState(staticOptions ?? []);
  const lsvpUser = useSelector(state => state.login.lsvpUser);
  const controller = useRef();

  useEffect(() => {
    const getResults = async () => {
      if (controller.current) { controller.current.abort(); }
      controller.current = new AbortController();
      const params = {
        ...search.params,
        [search.keyword ?? 'keyword']: keyword,
        limit: 100,
      };
      if (!noUserId) { params.userId = lsvpUser.id; }
      const results = await search.searchFunction(params, controller.current.signal);
      setResults(search.dataAccessor ? search.dataAccessor(results) : results.data.data);
    };

    if (search) {
      if (keyword) {
        const timeout = setTimeout(() => getResults(), 200);
        return () => clearTimeout(timeout);
      } else {
        setResults([]);
      }
    }
  }, [keyword, search, lsvpUser, noUserId]);

  useEffect(() => {
    if (search) {
      setOptions(toOptions(keyword ? results.map(({ name, id }) => toOption({ label: name, value: id })) : []));
    }
  }, [search, keyword, results, toOption, toOptions]);

  useEffect(() => setOptions(staticOptions), [staticOptions]);

  useEffect(() => {
    if (onKeywordChange) { onKeywordChange(keyword); }
  }, [keyword, onKeywordChange]);

  const customOptions = () => toOptions !== _.identity;

  const renderOptions = () => {
    if (!customOptions()) { return undefined; }
    return options?.map(option => option.label && option.value ? (
      <Select.Option label={option.label} value={option.value}>
        {option.label}
      </Select.Option>
    ) : option);
  };

  const onSelect = item => {
    setKeyword('');
    item = fromOption(item);
    switch (mode) {
      case 'multiple':
        onChange([...items, item]);
        break;
      case 'tags':
        if (combo || options.find(option => option.value === item.value)) {
          onChange([item]);
        }
        else {
          onChange([]);
        }
        break;
      default:
        onChange(item.value === anyKey ? [] : [item, ...extras]);
        break;
    }
  };

  const onDeselect = item => {
    setKeyword('');
    onChange(items.filter(({ value }) => value !== item.value));
  };

  const onDropdownVisibleChange = open => {
    if (!open) {
      setKeyword('');
    }
  };

  const renderSelect = () => (
    <Select
      className={`${styles.searchableSelect} ${className}`}
      mode={mode}
      options={customOptions() ? undefined : options}
      value={items}
      searchValue={keyword}
      filterOption={!search}
      optionFilterProp="label"
      labelInValue={true}
      placeholder="Search"
      showSearch
      disabled={disabled}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onSearch={setKeyword}
      onDropdownVisibleChange={onDropdownVisibleChange}
    >
      {renderOptions()}
    </Select>
  );

  return renderSelect();
};
