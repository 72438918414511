import { PeopleDetailsAction } from '../../types/people/details';

export const setLastInActivatedPeopleAction = (id, record, index) => ({
  type: PeopleDetailsAction.SET_LAST_INACTIVE_PEOPLE,
  payload: {
    lastInActivatedPersonId: id,
    lastInActivatedPersonRecord: record,
    lastInActivatedPersonRecordIndex: index
  }
});

export const updatePersonStatusAction = (id, newStatus) => ({
  type: PeopleDetailsAction.UPDATE_PERSON_STATUS,
  payload: {
    id,
    newStatus
  }
});

export const getPeopleProfile = id => ({
  type: PeopleDetailsAction.GET_PEOPLE_PROFILE,
  payload: { id }
});

export const setPeopleProfile = ({ personProfile, message }) => ({
  type: PeopleDetailsAction.SET_PEOPLE_PROFILE,
  payload: { personProfile, message }
});

export const changePersonSector = (sectorId, personId) => ({
  type: PeopleDetailsAction.CHANGE_PERSON_SECTOR,
  payload: {
    sectorId,
    personId
  }
});

export const localUpdatePersonStatusAction = (personId, newStatus) => ({
  type: PeopleDetailsAction.LOCAL_UPDATE_PERSON_STATUS,
  payload: {
    personId,
    newStatus
  }
});