import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from '../../Utils/UseDeepCompareEffect';
import { getAllLSVPUsers } from '../../Store/actions/lsvp-user/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { EntityType } from '../../Utils/Constants';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  assignLSVPUsersToEntity,
  unassignLSVPUsersFromEntity
} from '../../Store/actions/lsvp-user/actions';
import showNotification from '../Shared/Notification';
import PlusIcon from '../../Assets/images/plus.png';
import './AddOwnersModal.scss';
import sharedStyles from '../Shared/Shared.module.scss';

export default ({
  entityId,
  entityType = EntityType.COMPANY,
  currentOwners = [],
  isCompanyInfo
}) => {
  const lsvpUsers = useSelector(state => state.lsvpUsersList.users);
  const [searchTerm, setSearchTerm] = useState('');
  const [visible, setVisible] = useState(false);
  const [ownersToAddTo, setOwnersToAddTo] = useState([]);
  const [ownersToRemoveFrom, setOwnersToRemoveFrom] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    if (visible && lsvpUsers?.length === 0) {
      dispatch(getAllLSVPUsers());
    }
  }, [dispatch, visible, lsvpUsers]);

  useDeepCompareEffect(() => {
    setFilteredUsers(
      lsvpUsers.filter(user =>
        `${user.firstName} ${user.lastName} ${user.email}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [dispatch, searchTerm, lsvpUsers]);

  const showModal = () => setVisible(!visible);
  const handleOk = () => setVisible(!visible);
  const handleCancel = () => setVisible(!visible);

  const onUserChecked = user => {
    if (ownersToRemoveFrom.find(owner => owner.id === user.id)) {
      setOwnersToRemoveFrom(
        ownersToRemoveFrom.filter(owner => owner.id !== user.id)
      );
    } else if (!currentOwners.find(owner => owner.id === user.id)) {
      setOwnersToAddTo([...ownersToAddTo, user]);
    }
  };

  const onUserUnchecked = user => {
    if (ownersToAddTo.find(owner => owner.id === user.id)) {
      setOwnersToAddTo(ownersToAddTo.filter(owner => owner.id !== user.id));
    } else if (currentOwners.find(owner => owner.id === user.id)) {
      setOwnersToRemoveFrom([...ownersToRemoveFrom, user]);
    }
  };

  const onCheckboxChanged = (checked, user) =>
    checked ? onUserChecked(user) : onUserUnchecked(user);

  const applyChanges = () => {
    if (ownersToAddTo.length) {
      dispatch(assignLSVPUsersToEntity({
        entityId,
        entityType,
        addedUserIds: ownersToAddTo.map(owner => owner.id),
        lsvpUsers
      }));
    }

    if (ownersToRemoveFrom.length) {
      dispatch(unassignLSVPUsersFromEntity({
        entityId,
        entityType,
        removedUserIds: ownersToRemoveFrom.map(owner => owner.id)
      }));
    }

    setOwnersToAddTo([]);
    setOwnersToRemoveFrom([]);

    handleCancel();
    showNotification('info', 'Modifications submitted!');
  };

  return (
    <>
      {isCompanyInfo ? (
        <span className="add-owners" onClick={showModal}>
          <span className="modal-button">Edit Owner(s)</span>
        </span>
      ) : (
        <Tooltip
          placement="top"
          overlayClassName={sharedStyles.tooltip}
          title={<span>Assign or remove an Affinity owner to the company</span>}
        >
          <span className="add-owners" onClick={showModal}>
            <img src={PlusIcon} alt="add-icon" />
          </span>
        </Tooltip>
      )}
      <Modal
        className={'addOwner-modal'}
        bodyStyle={{
          padding: 0
        }}
        closeIcon={
          <FontAwesomeIcon icon={faTimes} className="modal-close-icon" />
        }
        title={
          <ModalHeader
            updateSearchTerm={setSearchTerm}
            title={'Assign Owner'}
            visible={visible}
          />
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <>
          {lsvpUsers.length ? (
            filteredUsers.length ? (
              <div className="add-owners-modal-content">
                {filteredUsers.map((user, index) => (
                  <Checkbox
                    key={index}
                    defaultChecked={currentOwners.find(
                      owner => owner.id === user.id
                    )}
                    onChange={e => onCheckboxChanged(e.target.checked, user)}
                  >
                    {user.firstName} {user.lastName} ({user.email})
                  </Checkbox>
                ))}
              </div>
            ) : (
              <div className="no-users-wrapper">
                <p>No users of the name "{searchTerm}"</p>
              </div>
            )
          ) : (
            <div className="spinner-wrapper">
              <Spin indicator={<LoadingOutlined spin />} />
            </div>
          )}
        </>
        <div className="owners-apply-btn-wrapper">
          <button
            className="owners-apply-btn"
            onClick={applyChanges}
            disabled={!ownersToAddTo.length && !ownersToRemoveFrom.length}
          >
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
};
