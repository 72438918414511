import { useRef, useEffect } from 'react';
import _ from 'lodash';
const deepCompareEquals = (currentState, prevState) => {
  return _.isEqual(currentState, prevState);
};

export const useDeepCompareMemoize = (
  value,
  comparator = deepCompareEquals
) => {
  const ref = useRef();

  if (!comparator(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const useDeepCompareEffect = (
  callback,
  dependencies,
  useDeepCompare = useDeepCompareMemoize
) => {
  useEffect(callback, useDeepCompare(dependencies));
};
