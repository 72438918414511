import { companiesListSaga } from './company/list';
import { companyDetailsSaga } from './company/details';
import { companyProfileSaga } from './company/profile';
import { companySummarySaga } from './company/summary';
import { companyFlagSaga } from './company/flag';
import { fundingRoundsListSaga } from './funding/list';
import { peopleListSaga } from './people/list';
import { all, call } from 'redux-saga/effects';
import { personSummarySaga } from './people/summary';
import { personFlagSaga } from './people/flag';
import { fundingRoundCompanySummarySaga } from './funding/companySummary';
import { fundingRoundFlagSaga } from './funding/flag';
import { fundingRoundOverrideSaga } from './funding/override';
import { peopleDetailsSaga } from './people/details';
import { pipelineListSaga } from './pipeline/list';
import { pipelineSummarySaga } from './pipeline/summary';
import { cryptoListSaga } from './crypto/list';
import { cryptoSummarySaga } from './crypto/summary';
import { flagListSaga } from './flag/list';
import { flagSummarySaga } from './flag/summary';
import { flagActionSaga } from './flag/action';
import { globalSearchSaga } from './filters/globalSearch';
import { crmListEntrySaga } from './crm/listEntry';
import { crmListSaga } from './crm/list';
import { crmEntitySaga } from './crm/entity';
import { crmIdSaga } from './crm/id';
import { crmCompanySaga } from './crm/company';
import { crmInteractionSaga } from './crm/interaction';
import { lsvpUserSaga } from './lsvp-user/list';
import { lsvpUserActionsSaga } from './lsvp-user/actions';
import { logSaga } from './log/actions';
import { teamViewsSaga } from './team/teamViews';
import { teamMembersSaga } from './team/teamMembers';
import { presentSaga } from './present/actions';
import { actionSaga } from './action/actions';
import { sectorSaga } from './sector/list';
import { landingSaga } from './landing';
import { autoFlagSaga } from './auto';
import { triggerSaga } from './trigger';
export function* watchSagas() {
  yield all([
    call(companiesListSaga),
    call(pipelineListSaga),
    call(pipelineSummarySaga),
    call(companyDetailsSaga),
    call(companyProfileSaga),
    call(companySummarySaga),
    call(companyFlagSaga),
    call(fundingRoundsListSaga),
    call(fundingRoundCompanySummarySaga),
    call(fundingRoundFlagSaga),
    call(fundingRoundOverrideSaga),
    call(peopleListSaga),
    call(cryptoListSaga),
    call(cryptoSummarySaga),
    call(flagListSaga),
    call(flagSummarySaga),
    call(flagActionSaga),
    call(personSummarySaga),
    call(personFlagSaga),
    call(peopleDetailsSaga),
    call(globalSearchSaga),
    call(crmListEntrySaga),
    call(lsvpUserSaga),
    call(lsvpUserActionsSaga),
    call(crmListSaga),
    call(crmEntitySaga),
    call(crmIdSaga),
    call(crmCompanySaga),
    call(crmInteractionSaga),
    call(logSaga),
    call(teamViewsSaga),
    call(teamMembersSaga),
    call(presentSaga),
    call(actionSaga),
    call(sectorSaga),
    call(landingSaga),
    call(autoFlagSaga),
    call(triggerSaga)
  ]);
}
