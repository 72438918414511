import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal,
  Spin,
  Radio
} from 'antd';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './AffinityStatusModal.scss';
import {
  EntityType,
  Status
} from '../../Utils/Constants';
import { updateCompanyStatusAction } from '../../Store/actions/company/details';
import { updatePersonStatusAction } from '../../Store/actions/people/details';
import { usePrevious } from '../../Utils/UsePrevious';

export default ({
  status,
  visible,
  entityType,
  entityId,
  onClose,
  onChange
}) => {
  const loading = useSelector(({ loader })  => loader.statusModalLoading);
  const dispatch = useDispatch();
  const prevLoading = usePrevious(loading);

  // eslint-disable-next-line
  const [value, setValue] = useState(status);

  useEffect(() => {
    setValue(status);
  }, [status]);

  useEffect(() => {
    if (visible && !loading && prevLoading) {
      onClose();
      if (onChange) {
        onChange();
      }
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loading, prevLoading]);

  return (
    <>
      <Modal
        className={'affinity-status-modal'}
        title="Edit Status"
        visible={visible}
        onCancel={onClose}
        footer={null}
        closeIcon={
          <FontAwesomeIcon icon={faTimes} className="modal-close-icon" />
        }
        bodyStyle={{
          overflowX: 'auto',
          padding: '15px 24px 15px 24px'
        }}
      >
        <Spin spinning={loading} delay={300}>
          <Radio.Group
            value={value}
            onChange={e => {
              const status = e.target.value;
              setValue(status);
              if (entityType === EntityType.COMPANY) {
                dispatch(updateCompanyStatusAction(entityId, status));
              } else if (entityType === EntityType.PERSON) {
                dispatch(updatePersonStatusAction(entityId, status));
              }
            }}
          >
            {Object.values(Status).map((item, index) => (
              <Radio value={item} key={index}>
                {item}
              </Radio>
            ))}
          </Radio.Group>
        </Spin>
      </Modal>
    </>
  );
};
