import React from 'react';
import { Avatar } from 'antd';
import { narrow } from '../../../Utils/Region';
import styles from './PersonNameColumn.module.scss';

export const PersonNameColumn = ({
  name,
  photoUrl,
  regions
}) => (
  <div className={styles.personNameColumn}>
    {photoUrl ? (
      <Avatar
        className={styles.avatar}
        size={32}
        shape="circle"
        src={photoUrl}
        alt="avatar-img"
      />
    ) : (
      <Avatar className={styles.avatar} size={32} shape="circle" alt="avatar-img">
        {name && name[0] ? name[0] : ''}
      </Avatar>
    )}
    <div>
      <div className={styles.name}>{name}</div>
      <div className={styles.regions}>{narrow(regions).join(', ')}</div>
    </div>
  </div>
);
