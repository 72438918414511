import React from 'react';
import { Checkbox } from 'antd';
import styles from './CheckboxControls.module.scss';

export default ({ filters, filterKey, label, addToFilters }) => {
  const isIncluded = () => filters[filterKey]?.length;

  const onChange = () => addToFilters({ [filterKey]: isIncluded() ? [] : [{ label, value: true }] });

  return (
    <div className={styles.checkboxControls}>
      <div className={styles.heading}>{label}</div>
      <Checkbox
        checked={isIncluded()}
        onChange={onChange}
      >
        {label}
      </Checkbox>
    </div>
  );
};
