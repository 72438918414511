import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatDate, getDifferenceBetweenDates } from '../../../Utils/Shared';
import Highlights from '../Highlights';

export default ({ experiences, team }) => {
  if (!experiences || !experiences.length) {
    return null;
  }

  return (
    <div className="experience-wrapper">
      <h5 className="experience-title">Experience</h5>
      <div className="experience-items">
        {experiences.map(({ positionStartDate, positionEndDate, companyName, position, companyId, highlights }, index) => {
          const start = positionStartDate.slice(0, 10);
          const end = positionEndDate?.slice(0, 10);
          const { years, months } = getDifferenceBetweenDates(
            formatDate(start),
            formatDate(!end || moment(end).isAfter(Date()) ? Date() : end)
          );
          const from = formatDate(start, 'MMM YYYY');
          const to = !end || moment(end).isAfter(Date()) ? (
            'Present'
          ) : (
            formatDate(end, 'MMM YYYY')
          );
          const company = companyName ? (
            companyId ? (
              <Link to={`/companies/${companyId}${team ? `?team=${team}` : ''}`} target="_blank">
                {companyName}
              </Link>
            ) : (
              companyName
            )
          ) : (
            <i>No name</i>
          );

          return (
            <div className="experience-item" key={index}>
              {<h5>{company}</h5>}
              <p>
                <div>{position}, <span>{` ${years}y ${months}m`}</span></div>
                <div>{`${from} - ${to}`}</div>
                <Highlights highlights={highlights} />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
