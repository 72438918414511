import { LogAction } from '../../types/log';

export const logActivity = (
  activity,
  userId,
  details
) => ({
  type: LogAction.LOG_ACTIVITY,
  payload: { activity, userId, details }
});
