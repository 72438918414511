import { LsvpUserAction } from '../../types/lsvp-user';

export const getAllLSVPUsers = () => ({
  type: LsvpUserAction.GET_ALL_LSVP_USERS
});

export const setAllLSVPUsers = ({ users }) => ({
  type: LsvpUserAction.SET_ALL_LSVP_USERS,
  payload: { users }
});
