import React from 'react';
import { Divider } from 'antd';
import GlobalSearchResultsHead from './GlobalSearchResultsHead';
import GlobalSearchCompaniesTable from './GlobalSearchCompaniesTable';
import GlobalSearchPeopleTable from './GlobalSearchPeopleTable';
import styles from './GlobalSearchResults.module.scss';

export default ({ keyword, onClose }) => (
  <div className={styles.globalSearchResults}>
    <GlobalSearchResultsHead searchResult={keyword} onClose={onClose} />
    <GlobalSearchCompaniesTable keyword={keyword} />
    <Divider />
    <GlobalSearchPeopleTable keyword={keyword} />
  </div>
);
