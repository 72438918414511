import { axiosInstance } from '../index';
const getCompanySummary = async (id, params) => {
  return await axiosInstance.get(`companies/${id}/summary`, {
    handlerEnabled: true,
    params
  });
};

export default {
  getCompanySummary
};
