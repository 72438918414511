import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/present';
import { setPresentation } from '../../actions/present/actions';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { PresentAction } from '../../types/present';
import updateLoader from '../../actions/Loader';

export function* createPresentation({payload}) {
  try {
    yield put(updateLoader({ global: true }));
    yield call(API.createPresentation, payload);
    yield put(setPresentation(payload.presentationId, payload.entityIds));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ global: false }));
  }
}

export function* getPresentation({payload}) {
  try {
    const response = yield call(API.getPresentation, payload);
    yield put(setPresentation(payload.presentationId, response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* presentSaga() {
  yield takeLatest(
    PresentAction.CREATE_PRESENTATION,
    createPresentation
  );
  yield takeLatest(
    PresentAction.GET_PRESENTATION,
    getPresentation
  );
}
