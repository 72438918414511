import { CryptoListAction } from '../../types/crypto/list';

export const CRYPTO_INITIAL_STATE = {
  cryptos: [],
  message: '',
  count: 0,
  loadingMore: false
};

export default (state = CRYPTO_INITIAL_STATE, action) => {
  switch (action.type) {
    case CryptoListAction.SET_CRYPTO_LIST:
      return {
        ...state,
        ...action.payload
      };

    case CryptoListAction.GET_MORE_CRYPTO_LIST:
      return {
        ...state,
        loadingMore: true
      };

    case CryptoListAction.SET_MORE_CRYPTO_LIST:
      return {
        ...state,
        cryptos: [...state.cryptos, ...(action.payload?.cryptos || [])],
        loadingMore: false
      };

    case CryptoListAction.UPDATE_CRYPTO_LIST:
      return {
        ...state,
        ...action.payload
      };

    case CryptoListAction.SET_CRYPTO_COUNT:
      return {
        ...state,
        count: action.payload?.count
      };

    default:
      return state;
  }
};
