import { PeopleDetailsAction } from '../../types/people/details';

const INITIAL_STATE = {
  lastInActivatedPersonId: undefined,
  lastInActivatedPersonRecord: undefined,
  lastInActivatedPersonRecordIndex: undefined,
  personProfile: undefined
};

const peopleDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PeopleDetailsAction.SET_LAST_INACTIVE_PEOPLE:
      return { ...state, ...action.payload };
    case PeopleDetailsAction.SET_PEOPLE_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default peopleDetails;
