import { axiosInstance } from '../index';
const getCryptoSummary = async (id, params) => {
  return await axiosInstance.get(`crypto/${id}/summary`, {
    handlerEnabled: true,
    params
  });
};

export default {
  getCryptoSummary
};
