import { GlobalSearchAction } from '../../types/filter/globalSearch';

export const getCompaniesSearchList = ({ keyword, ...filters }) => ({
  type: GlobalSearchAction.GET_COMPANIES_SEARCH_LIST,
  payload: { keyword, ...filters }
});

export const setCompaniesSearchList = ({ companies, message }) => ({
  type: GlobalSearchAction.SET_COMPANIES_SEARCH_LIST,
  payload: { companies, message }
});

export const getMoreCompaniesSearchList = ({ keyword, ...filters }) => ({
  type: GlobalSearchAction.GET_MORE_COMPANIES_SEARCH_LIST,
  payload: { keyword, ...filters }
});

export const setMoreCompaniesSearchList = ({ companies, message }) => ({
  type: GlobalSearchAction.SET_MORE_COMPANIES_SEARCH_LIST,
  payload: { companies, message }
});

export const getSearchCompanySummary = (companyId, params) => ({
  type: GlobalSearchAction.GET_SEARCH_COMPANY_SUMMARY,
  payload: { companyId, params }
});

export const getSearchCompanyFlag = (companyId) => ({
  type: GlobalSearchAction.GET_SEARCH_COMPANY_FLAG,
  payload: { companyId }
});

export const getPeopleSearchList = ({ keyword, ...filters }) => ({
  type: GlobalSearchAction.GET_PEOPLE_SEARCH_LIST,
  payload: { keyword, ...filters }
});

export const setPeopleSearchList = ({ people, message }) => ({
  type: GlobalSearchAction.SET_PEOPLE_SEARCH_LIST,
  payload: { people, message }
});

export const getMorePeopleSearchList = ({ keyword, ...filters }) => ({
  type: GlobalSearchAction.GET_MORE_PEOPLE_SEARCH_LIST,
  payload: { keyword, ...filters }
});

export const setMorePeopleSearchList = ({ people, message }) => ({
  type: GlobalSearchAction.SET_MORE_PEOPLE_SEARCH_LIST,
  payload: { people, message }
});

export const getSearchPersonSummary = personId => ({
  type: GlobalSearchAction.GET_SEARCH_PERSON_SUMMARY,
  payload: { personId }
});

export const getSearchPersonFlag = personId => ({
  type: GlobalSearchAction.GET_SEARCH_PERSON_FLAG,
  payload: { personId }
});
