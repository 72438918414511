import { LandingAction } from '../../types/landing';

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LandingAction.SET_LANDING:
      return action.payload;
    case LandingAction.SET_LANDING_FLAG:
      return {
        ...state,
        flag: action.payload
      };
    default:
      return state;
  }
};
