import React from 'react';
import LinkedIn from '../../../Assets/images/LinkedIn Icon.png';
import Affinity from '../../../Assets/images/Affinity.png';
import Twitter from '../../../Assets/images/Twitter Icon.png';
import Facebook from '../../../Assets/images/Facebook Icon.png';
import Crunchbase from '../../../Assets/images/Crunchbase.png';
import {
  sanitizeLink,
  getSocialMediaLinks
} from '../../../Utils/Shared';

export default function About({ person, options }) {
  const {
    email,
    seniority,
    currentPositionFunction,
    summary
  } = person;

  const {
    socialMediaUrls,
    crmEntityId,
    regions
  } = summary;

  if (
    !(
      email
      ||
      socialMediaUrls
      ||
      regions
      ||
      seniority
      ||
      currentPositionFunction
      ||
      (options?.includeAffinityLink && crmEntityId)
    )
  ) {
    return null;
  }

  const {
    linkedin: linkedInLink,
    twitter: twitterLink,
    facebook: facebookLink,
    cb: crunchbaseLink
  } = getSocialMediaLinks(socialMediaUrls);

  return (
    <div className="about-wrapper">
      <h5 className="about-title">About</h5>
      <a href={`mailto:${email}`} className="email-link">
        {email}
      </a>
      <p className="about-region">{regions[0]}</p>
      <div className="social-wrapper">
        {options?.includeLinkedInLink && linkedInLink ? (
          <a
            className="social-icon"
            href={sanitizeLink(linkedInLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
        ) : null}
        {options?.includeAffinityLink && crmEntityId ? (
          <a
            className="social-icon"
            href={`https://lsvp.affinity.co/persons/${crmEntityId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Affinity} alt="Affinity" />
          </a>
        ) : null}
        {twitterLink ? (
          <a
            className="social-icon"
            href={sanitizeLink(twitterLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Twitter} alt="Twitter" />
          </a>
        ) : null}
        {facebookLink ? (
          <a
            className="social-icon"
            href={sanitizeLink(facebookLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Facebook} alt="Facebook" />
          </a>
        ) : null}
        {crunchbaseLink ? (
          <a
            className="social-icon"
            href={sanitizeLink(crunchbaseLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Crunchbase} alt="Crunchbase" />
          </a>
        ) : null}
      </div>
      <p className="about-seniority">{seniority}</p>
      <p className="about-position">{currentPositionFunction}</p>
    </div>
  );
}
