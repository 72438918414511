import { call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../../../Network/Apis/funding/flag';
import { setFundingRoundsList } from '../../actions/funding/list';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { FundingRoundFlagAction } from '../../types/funding/flag';

const addFlagToFundingRound = (fundingRounds, fundingRoundFlag) => fundingRounds.map(fundingRound =>
  fundingRound.id === fundingRoundFlag.fundingRound.id
    ? { ...fundingRound, flag: fundingRoundFlag }
    : fundingRound
);

function* setFundingRoundFlag(fundingRoundFlag, message) {
  const fundingRoundsList = yield select(state => state.fundingRoundsList);
  yield put(
    setFundingRoundsList({
      ...fundingRoundsList,
      message,
      fundingRounds: addFlagToFundingRound(fundingRoundsList?.fundingRounds, fundingRoundFlag)
    })
  );
}

export function* fundingRoundFlag({ payload }) {
  try {
    const response = yield call( API.getFundingRoundFlag, payload.fundingRoundId);
    const { fundingRoundFlag, message } = response.data.data;
    yield setFundingRoundFlag(fundingRoundFlag, message);
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* fundingRoundFlagSaga() {
  yield takeEvery(
    FundingRoundFlagAction.GET_FUNDING_ROUND_FLAG,
    fundingRoundFlag
  );
}
