import { LOGIN, LOGOUT } from '../types';

const INITIAL_STATE = {
  currentUser: undefined,
  lsvpUser: undefined
};

const login = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, currentUser: action.user, lsvpUser: action.lsvpUser };

    case LOGOUT:
      return { ...state, currentUser: undefined, lsvpUser: undefined };

    default:
      return state;
  }
};

export default login;
