import { axiosInstance } from '../index';

const addFlagAction = async (params) => {
  return await axiosInstance.post('flags/action', params);
};

const rerouteFlag = async (params) => {
  return await axiosInstance.put('flags/route', params);
};

const unflag = async ({ flagId, removeMessage }) => {
  return await axiosInstance.delete(`flags/${flagId}`, { data: { removeMessage } });
};

export default {
  addFlagAction,
  rerouteFlag,
  unflag
};
