import {
  ActionType,
  SpecialTeamId
} from '../../../Utils/Constants';

export const localAddAction = ({
  state,
  type,
  key,
  params,
  idKey = 'id',
  actionsKey = 'actions'
}) => {
  const {
    entityId,
    entityType,
    actionType,
    teamId,
    userId,
    comment,
    details,
    userName,
    showSnoozed,
    showBlocked,
    team
  } = params;

  if (entityType !== type) { return state; }

  if (
    (
      ((actionType === ActionType.SNOOZE || actionType === ActionType.ASSIGN) && !showSnoozed)
      ||
      (actionType === ActionType.BLOCK && !showBlocked)
    )
    &&
    (
      teamId === SpecialTeamId.EVERYONE
      ||
      teamId === SpecialTeamId.ME 
      ||
      teamId === team
    )
  ) {
    return {
      ...state,
      [key]: state[key].filter(entity => entity[idKey] !== entityId)
    };
  }

  if (actionType === ActionType.CLASSIFY) {
    state = {
      ...state,
      [key]: state[key].map(
        entity => entity[idKey] === entityId ? (
          {
            ...entity,
            classification: details.classification
          }
        ) : (
          entity
        )
      ) 
    };
  }

  return {
    ...state,
    [key]: state[key].map(
      entity => entity[idKey] === entityId ? (
        {
          ...entity,
          [actionsKey]: [
            ...(entity[actionsKey] ?? []),
            {
              actionType,
              teamId,
              userId,
              comment,
              details,
              userName,
              updatedAt: new Date().toISOString(),
              createdAt: new Date().toISOString(),
              triggeredAt: null
            }
          ]
        }
      ) : (
        entity
      )
    ) 
  };
};

export const localRemoveAction = ({
  state,
  type,
  key,
  params,
  idKey = 'id',
  actionsKey = 'actions'
}) => {
  const {
    entityId,
    entityType,
    actionType,
    teamId
  } = params;

  if (entityType !==  type) { return state; }

  if (actionType === ActionType.CLASSIFY) {
    state = {
      ...state,
      [key]: state[key].map(
        entity => entity[idKey] === entityId ? (
          {
            ...entity,
            classification: null
          }
        ) : (
          entity
        )
      ) 
    };
  }

  return {
    ...state,
    [key]: state[key].map(
      entity => entity[idKey] === entityId ? (
        {
          ...entity,
          [actionsKey]: entity[actionsKey].map(action => (
            (
              action.actionType === actionType
              &&
              action.teamId === teamId
            ) ? (
              {
                ...action,
                deactivatedAt: new Date().toISOString()
              }
            ) : action
          ))
        }
      ) : (
        entity
      )
    ) 
  };
};

export const localChangeAction = ({
  state,
  type,
  key,
  params,
  idKey = 'id',
  actionsKey = 'actions'
}) => {
  const {
    entityId,
    entityType,
    actionType,
    teamId,
    comment,
    details,
  } = params;

  if (entityType !==  type) { return state; }

  if (actionType === ActionType.CLASSIFY) {
    state = {
      ...state,
      [key]: state[key].map(
        entity => entity[idKey] === entityId ? (
          {
            ...entity,
            classification: details.classification
          }
        ) : (
          entity
        )
      ) 
    };
  }

  return {
    ...state,
    [key]: state[key].map(
      entity => entity[idKey] === entityId ? (
        {
          ...entity,
          [actionsKey]: entity[actionsKey].map(action => (
            (
              action.actionType === actionType
              &&
              action.teamId === teamId
            ) ? (
              {
                ...action,
                comment,
                details
              }
            ) : action
          ))
        }
      ) : (
        entity
      )
    ) 
  };
}