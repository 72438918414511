import moment from 'moment';
import { FlagListAction } from '../../types/flag/list';
import {
  localAddAction,
  localRemoveAction
} from '../shared/action';
import { isBaseAction } from '../../../Utils/Action';
import { LsvpUserAction } from '../../types/lsvp-user';
import { CompanyDetailsAction } from '../../types/company/details';
import { PeopleDetailsAction } from '../../types/people/details';
import { CrmCompanyAction } from '../../types/crm/company';
import { FlagActionAction } from '../../types/flag/action';
import {
  Status,
  FlagState,
  EntityType,
  InteractionType,
  filterKeys
} from '../../../Utils/Constants';
import { CrmInteractionAction } from '../../types/crm/interaction';

export const FLAG_INITIAL_STATE = {
  flags: [],
  message: '',
  count: 0,
  loadingMore: false
};

const addCompanyPerson = (people, person) => {
  if (!people) { return [ person ]; }
  if (people.find(({ id }) => id === person.id)) { return people; }
  return [...people, person];
};

const getStatus = state => ({
  [FlagState.ACCEPTED]: Status.PROSPECT,
  [FlagState.TRACKING]: Status.TRACKING,
  [FlagState.REJECTED]: Status.PASSED
}[state]);

const getInteractionField = interaction => ({
  [InteractionType.MEETING]: 'met',
  [InteractionType.CALL]: 'called',
  [InteractionType.MESSAGE]: 'messaged',
  [InteractionType.EMAIL]: 'emailed'
}[interaction]);

const getState = interaction => ({
  [InteractionType.MEETING]: FlagState.MET,
  [InteractionType.CALL]: FlagState.MET,
  [InteractionType.MESSAGE]: FlagState.REACHED_OUT,
  [InteractionType.EMAIL]: FlagState.REACHED_OUT
}[interaction]);

const filter = (flags, filters) => {
  const flagState = filters[filterKeys.flagState]?.[0]?.value;
  if (!flagState) { return flags; }
  const flagInteraction = filters[filterKeys.flagInteraction]?.[0]?.value;
  return flags.filter(({ states, currentState }) => {
    if (flagInteraction) {
      if (flagInteraction === FlagState.REACHED_OUT && currentState !== FlagState.REACHED_OUT && currentState !== FlagState.MET) { return false; }
      if (flagInteraction === FlagState.MET && currentState !== FlagState.MET) { return false; }
      return states.find(({ state }) => state === flagState);
    }
    return currentState === flagState;
  });
};

export default (state = FLAG_INITIAL_STATE, action) => {
  switch (action.type) {
    case FlagListAction.SET_FLAG_LIST:
      return {
        ...state,
        ...action.payload
      };

    case FlagListAction.GET_MORE_FLAG_LIST:
      return {
        ...state,
        loadingMore: true
      };

    case FlagListAction.SET_MORE_FLAG_LIST:
      return {
        ...state,
        flags: [...state.flags, ...(action.payload?.flags || [])],
        loadingMore: false
      };

    case FlagListAction.UPDATE_FLAG_LIST:
      return {
        ...state,
        ...action.payload
      };

    case FlagListAction.SET_FLAG_COUNT:
      return {
        ...state,
        count: action.payload?.count
      };

    case FlagListAction.LOCAL_ADD_ACTION:
      state =  localAddAction({
        state,
        type: action.payload.entityType,
        key: 'flags',
        params: action.payload,
        idKey: 'entityId'
      });

      if (isBaseAction(action.payload.actionType) && action.payload.entityType === EntityType.COMPANY) {
        state = localAddAction({
          state,
          type: EntityType.COMPANY,
          key: 'flags',
          params: { ...action.payload, showSnoozed: true, showBlocked: true },
          idKey: 'companyId',
          actionsKey: 'companyActions'
        });
      }

      return state;

    case FlagListAction.LOCAL_REMOVE_ACTION:
      state =  localRemoveAction({
        state,
        type: action.payload.entityType,
        key: 'flags',
        params: action.payload,
        idKey: 'entityId'
      });

      if (isBaseAction(action.payload.actionType) && action.payload.entityType === EntityType.COMPANY) {
        state = localRemoveAction({
          state,
          type: EntityType.COMPANY,
          key: 'flags',
          params: action.payload,
          idKey: 'companyId',
          actionsKey: 'companyActions'
        });
      }

      return state;

    case LsvpUserAction.LOCAL_ASSIGN_LSVP_USERS_TO_ENTITY: {
      const { entityId, addedUserIds, lsvpUsers } = action.payload;
      return {
        ...state,
        flags: state.flags.map(flag => 
          flag.entityId === entityId || flag.companyId === entityId ? {
            ...flag,
            owners: [...flag.owners, ...lsvpUsers.filter(user => addedUserIds.includes(user.id))]
          } : flag
        )
      };
    }

    case LsvpUserAction.LOCAL_UNASSIGN_LSVP_USERS_FROM_ENTITY: {
      const { entityId, removedUserIds } = action.payload;
      return {
        ...state,
        flags: state.flags.map(flag => 
          flag.entityId === entityId || flag.companyId === entityId ? {
            ...flag,
            owners: flag.owners.filter(owner => !removedUserIds.includes(owner.id))
          } : flag
        )
      };
    }

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_STATUS:
      return {
        ...state,
        flags: state.flags.map(flag => flag.entityId === action.payload.companyId || flag.companyId === action.payload.companyId ? { ...flag, status: action.payload.newStatus } : flag)
      };

    case PeopleDetailsAction.LOCAL_UPDATE_PERSON_STATUS:
      return {
        ...state,
        flags: state.flags.map(flag => flag.entityId === action.payload.personId ? { ...flag, status: action.payload.newStatus } : flag)
      };

    case CrmCompanyAction.SET_COMPANY_PEOPLE:
      return {
        ...state,
        flags: state.flags.map(flag => flag.crmEntityId === action.payload.companyId ? { ...flag, people: action.payload.people } : flag)
      };

    case CrmCompanyAction.LOCAL_ADD_COMPANY_PERSON:
      return {
        ...state,
        flags: state.flags.map(flag => flag.crmEntityId === action.payload.companyId ? { ...flag, people: addCompanyPerson(flag.people, action.payload.person) } : flag)
      };

    case FlagActionAction.LOCAL_ADD_FLAG_ACTION:
      return {
        ...state,
        flags: filter(state.flags.map(flag => flag.flagId === action.payload.flagId ? {
          ...flag,
          states: [
            ...flag.states,
            { state: action.payload.state, date: moment().format('YYYY-MM-DD'), subject: action.payload.userId },
            ...(action.payload.extras ? action.payload.extras.map(({ state }) => ({ state, date: moment().format('YYYY-MM-DD'), subject: action.payload.userId })) : [])
          ],
          currentState: action.payload.extras ? {
            state: action.payload.extras[action.payload.extras.length - 1].state, date: moment().format('YYYY-MM-DD'), subject: action.payload.userId
          } : {
            state: action.payload.state, date: moment().format('YYYY-MM-DD'), subject: action.payload.userId
          },
          owners: action.payload.owners ? action.payload.owners.map((id, i) => ({ id, name: action.payload.names[i] })) : flag.owners  ,
          status: getStatus(action.payload.state)
        } : flag), action.payload.filters)
      };

    case CrmInteractionAction.LOCAL_ADD_CRM_INTERACTION: {
      const currentState = { state: getState(action.payload.interaction), date: moment().format('YYYY-MM-DD') };
      return {
        ...state,
        flags: filter(state.flags.map(flag => (flag.flagId === action.payload.flagId ? {
          ...flag,
          [getInteractionField(action.payload.interaction)]: {
            date: action.payload.date.format('YYYY-MM-DD'),
            name: action.payload.names[0],
            userId: action.payload.internalIds[0]
          },
          states: [...flag.states, currentState],
          currentState,
        } : flag)), action.payload.filters)
      };
    }

    case FlagActionAction.LOCAL_REROUTE_FLAG:
      return {
        ...state,
        flags: state.flags.map(flag => flag.flagId === action.payload.flagId ? { ...flag, team: action.payload.newTeamId } : flag)
      };

    default:
      return state;
  }
};
