/* eslint-disable @typescript-eslint/camelcase */
import { FundingRoundsList } from '../../types/funding';
import { Action } from '../../types/action';
import { CrmIdAction } from '../../types/crm/id';
import { EntityType } from '../../../Utils/Constants';
import {
  localAddAction,
  localRemoveAction,
  localChangeAction
} from '../shared/action';
import { FundingRoundOverride } from '../../types/funding/override';
import { isBaseAction } from '../../../Utils/Action';
import { CompanyDetailsAction } from '../../types/company/details';
import { LsvpUserAction } from '../../types/lsvp-user';

const INITIAL_STATE = {
  fundingRounds: [],
  count: 0,
  message: '',
  loadingMore: false,
  sectors: [],
  charts: null,
  chartsNeedReloading: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FundingRoundsList.SET_FUNDING_ROUNDS_LIST:
      return {
        ...state,
        ...action.payload
      };

    case FundingRoundsList.GET_MORE_FUNDING_ROUNDS_LIST:
      return {
        ...state,
        loadingMore: true
      };

    case FundingRoundsList.SET_MORE_FUNDING_ROUNDS_LIST:
      return {
        ...state,
        fundingRounds: [
          ...state.fundingRounds,
          ...(action.payload?.fundingRounds || [])
        ],
        loadingMore: false
      };

    case FundingRoundsList.UPDATE_FUNDING_ROUNDS_LIST:
      return {
        ...state,
        ...action.payload
      };

    case FundingRoundsList.SET_FUNDING_ROUNDS_CHARTS:
      return {
        ...state,
        charts: action.payload?.charts
      };

    case Action.LOCAL_ADD_ACTION: 
      state = localAddAction({
        state,
        type: EntityType.FUNDING_ROUND,
        key: 'fundingRounds',
        params: action.payload,
        actionsKey: 'fundingRoundActions'
      });

      if (isBaseAction(action.payload.actionType) && action.payload.entityType === EntityType.COMPANY) {
        state = localAddAction({
          state,
          type: EntityType.COMPANY,
          key: 'fundingRounds',
          params: { ...action.payload, showSnoozed: true, showBlocked: true },
          idKey: 'companyId',
          actionsKey: 'companyActions'
        });
      }

      return state;

    case Action.LOCAL_REMOVE_ACTION:
      state = localRemoveAction({
        state,
        type: EntityType.FUNDING_ROUND,
        key: 'fundingRounds',
        params: action.payload,
        actionsKey: 'fundingRoundActions'
      });

      if (isBaseAction(action.payload.actionType) && action.payload.entityType === EntityType.COMPANY) {
        state = localRemoveAction({
          state,
          type: EntityType.COMPANY,
          key: 'fundingRounds',
          params: action.payload,
          idKey: 'companyId',
          actionsKey: 'companyActions'
        });
      }

      return state;

    case Action.LOCAL_CHANGE_ACTION:
      state = localChangeAction({
        state,
        type: EntityType.FUNDING_ROUND,
        key: 'fundingRounds',
        params: action.payload,
        actionsKey: 'fundingRoundActions'
      });

      if (isBaseAction(action.payload.actionType) && action.payload.entityType === EntityType.COMPANY) {
        state = localChangeAction({
          state,
          type: EntityType.COMPANY,
          key: 'fundingRounds',
          params: action.payload,
          idKey: 'companyId',
          actionsKey: 'companyActions'
        });
      }

      return state;

    case CrmIdAction.LOCAL_UPDATE_CRM_ID: {
      const { id, entityType, crmEntityId } = action.payload;
      if (entityType !== EntityType.COMPANY) { return state; }
      return {
        ...state,
        fundingRounds: state.fundingRounds.map(fundingRound => fundingRound.companyId === id ? {
          ...fundingRound,
          crmEntityId,
          summary: fundingRound.summary ? { ...fundingRound.summary, crmEntityId } : undefined
        } : fundingRound)
      };
    }

    case FundingRoundOverride.LOCAL_OVERRIDE_FUNDING_ROUND_COVERAGE: {
      const { id, value } = action.payload;
      return {
        ...state,
        fundingRounds: state.fundingRounds.map(fundingRound => fundingRound.id === id ? {
          ...fundingRound,
          coverageOverride: value,
        } : fundingRound)
      };
    }

    case FundingRoundOverride.LOCAL_OVERRIDE_FUNDING_ROUND_STAGE: {
      const { id, value } = action.payload;
      return {
        ...state,
        fundingRounds: state.fundingRounds.map(fundingRound => fundingRound.id === id ? {
          ...fundingRound,
          stage_override: value,
        } : fundingRound)
      };
    }

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_STATUS:
      return {
        ...state,
        fundingRounds: state.fundingRounds.map(fundingRound => fundingRound.companyId === action.payload.companyId ? { ...fundingRound, status: action.payload.newStatus } : fundingRound)
      };

    case LsvpUserAction.LOCAL_ASSIGN_LSVP_USERS_TO_ENTITY: {
      const { entityId, entityType, addedUserIds, lsvpUsers } = action.payload;
      if (entityType !== EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        fundingRounds: state.fundingRounds.map(fundingRound => 
          fundingRound.companyId === entityId ? {
            ...fundingRound,
            owners: [...fundingRound.owners, ...lsvpUsers.filter(user => addedUserIds.includes(user.id))]
          } : fundingRound
        )
      };
    }

    case LsvpUserAction.LOCAL_UNASSIGN_LSVP_USERS_FROM_ENTITY: {
      const { entityId, entityType, removedUserIds } = action.payload;
      if (entityType !== EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        fundingRounds: state.fundingRounds.map(fundingRound => 
          fundingRound.companyId === entityId ? {
            ...fundingRound,
            owners: fundingRound.owners.filter(owner => !removedUserIds.includes(owner.id))
          } : fundingRound
        )
      };
    }

    case FundingRoundsList.SET_FUNDING_ROUNDS_CHARTS_NEED_RELOADING:
      return {
        ...state,
        chartsNeedReloading: action.payload.chartsNeedReloading
      };

    default:
      return state;
  }
};
