import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/funding/override';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { FundingRoundOverride } from '../../types/funding/override';
import {
  localOverrideFundingRoundCoverage,
  localOverrideFundingRoundStage
} from '../../actions/funding/override';
import { setFundingRoundsChartsNeedReloading } from '../../actions/funding/list';

export function* overrideFundingRoundCoverage({ payload }) {
  try {
    const { id, value } = payload;
    yield call(API.overrideFundingRoundCoverage, id, value);
    yield put(localOverrideFundingRoundCoverage(payload));
    yield put(setFundingRoundsChartsNeedReloading({ chartsNeedReloading: true }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* overrideFundingRoundStage({ payload }) {
  try {
    const { id, value } = payload;
    yield call(API.overrideFundingRoundStage, id, value);
    yield put(localOverrideFundingRoundStage(payload));
    yield put(setFundingRoundsChartsNeedReloading({ chartsNeedReloading: true }));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* fundingRoundOverrideSaga() {
  yield takeLatest(
    FundingRoundOverride.OVERRIDE_FUNDING_ROUND_COVERAGE,
    overrideFundingRoundCoverage
  );
  yield takeLatest(
    FundingRoundOverride.OVERRIDE_FUNDING_ROUND_STAGE,
    overrideFundingRoundStage
  );
}
