import { CompanyProfileAction } from '../../types/company/profile';
import { Action } from '../../types/action';
import { EntityType } from '../../../Utils/Constants';
import { LsvpUserAction } from '../../types/lsvp-user';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CompanyProfileAction.GET_COMPANY_PROFILE_RECEIVE: {
      return {
        ...state,
        ...action.payload
      };
    }

    case Action.LOCAL_ADD_ACTION: {
      const {
        entityId,
        entityType,
        actionType,
        teamId,
        userId,
        comment,
        details,
        userName
      } = action.payload;

      if (entityId !== state.companyDetails?.id || entityType !==  EntityType.COMPANY) {
        return state;
      }

      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          actions: [
            ...(state.companyDetails.actions ?? []),
            {
              actionType,
              teamId,
              userId,
              comment,
              details,
              userName,
              updatedAt: new Date().toISOString(),
              createdAt: new Date().toISOString(),
              triggeredAt: null
            }
          ]
        }
      };
    }

    case Action.LOCAL_REMOVE_ACTION: {
      const {
        entityId,
        entityType,
        actionType,
        teamId
      } = action.payload;

      if (entityId !== state.companyDetails?.id || entityType !==  EntityType.COMPANY) {
        return state;
      }

      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          actions: state.companyDetails.actions.map(action => (
            (
              action.actionType === actionType
              &&
              action.teamId === teamId
            ) ? (
              {
                ...action,
                deactivatedAt: new Date().toISOString()
              }
            ) : action
          ))
        }
      };
    }

    case Action.LOCAL_CHANGE_ACTION: {
      const {
        entityId,
        entityType,
        actionType,
        teamId,
        comment,
        details,
      } = action.payload;

      if (entityId !== state.companyDetails?.id || entityType !==  EntityType.COMPANY) {
        return state;
      }

      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          actions: state.companyDetails.actions.map(action => (
            (
              action.actionType === actionType
              &&
              action.teamId === teamId
            ) ? (
              {
                ...action,
                comment,
                details
              }
            ) : action
          ))
        }
      };
    }

    case LsvpUserAction.LOCAL_ASSIGN_LSVP_USERS_TO_ENTITY: {
      const { entityId, entityType, addedUserIds, lsvpUsers } = action.payload;
      if (entityId !== state.companyDetails?.id || entityType !==  EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          owners: [...state.companyDetails.owners, ...lsvpUsers.filter(user => addedUserIds.includes(user.id))]
        }
      };
    }

    case LsvpUserAction.LOCAL_UNASSIGN_LSVP_USERS_FROM_ENTITY: {
      const { entityId, entityType, removedUserIds } = action.payload;
      if (entityId !== state.companyDetails?.id || entityType !==  EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          owners: state.companyDetails.owners.filter(owner => !removedUserIds.includes(owner.id))
        }
      };
    }

    default:
      return state;
  }
};
