import { CrmListAction } from '../../types/crm/list';

export const getAffinityLists = () => ({
  type: CrmListAction.GET_AFFINITY_LISTS,
  payload: {}
});

export const setAffinityLists = lists => ({
  type: CrmListAction.SET_AFFINITY_LISTS,
  payload: { lists }
});

export const getAllAffinityLists = () => ({
  type: CrmListAction.GET_ALL_AFFINITY_LISTS,
  payload: {}
});

export const setAllAffinityLists = lists => ({
  type: CrmListAction.SET_ALL_AFFINITY_LISTS,
  payload: { lists }
});

export const addAffinityList = payload => ({
  type: CrmListAction.ADD_AFFINITY_LIST,
  payload
});

export const addAffinityListCompany = payload => ({
  type: CrmListAction.ADD_AFFINITY_LIST_COMPANY,
  payload
});

export const deleteAffinityListCompany = payload => ({
  type: CrmListAction.DELETE_AFFINITY_LIST_COMPANY,
  payload
});
