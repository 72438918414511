import React from 'react';
import { Checkbox } from 'antd';
import SearchableSelect from '../Shared/SearchableSelect';
import { stopPropagation } from '../../Utils/Shared';
import styles from './SelectControls.module.scss';

const noneItems = [{ label: 'None', value: 'none' }];

export default ({
  mode,
  filters,
  filterKey,
  search,
  staticOptions,
  label,
  orNone,
  anyKey,
  toOption,
  fromOption,
  addToFilters
}) => {
  const noneIncluded = () => filters[filterKey]?.find(({ value }) => value === 'none');

  const getItems = () => {
    if (!filters[filterKey]) { return []; }
    if (mode || !orNone) { return filters[filterKey]; }
    return filters[filterKey].filter(({ value }) => value !== 'none');
  };

  const getExtras = () => !mode && noneIncluded() ? noneItems : undefined;

  const onToggleNone = () => addToFilters({
    [filterKey]: noneIncluded() ? (
      filters[filterKey].filter(({ value }) => value !== 'none')
    ) : (
      [...(filters[filterKey] || []), ...noneItems]
    )
  });

  const onChange = filter => addToFilters({ [filterKey]: filter });

  return (
    <div
      className={styles.selectControls}
      onScroll={stopPropagation}
    >
      <div className={styles.heading}>{label}</div>
      <SearchableSelect
        mode={mode}
        items={getItems()}
        search={search}
        staticOptions={staticOptions}
        anyKey={anyKey}
        extras={getExtras()}
        toOption={toOption}
        fromOption={fromOption}
        onChange={onChange}
      />
      {orNone ? (
        <Checkbox
          className={styles.noneCheckbox}
          checked={noneIncluded()}
          disabled={!mode && !filters[filterKey]?.length}
          onChange={onToggleNone}
        >
          {`Include no ${label.toLowerCase()}`}
        </Checkbox>
      ) : null}
    </div>
  );
};
