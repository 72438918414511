import { axiosInstance } from '../index';

const getCompanyOwner = async companyId => {
  return await axiosInstance.get(`companies/${companyId}/owners`);
};

const changeCompanySector = async (sectorId, companyId) => {
  return axiosInstance.post(`companies/${companyId}/change-sector`, {
    sectorId
  });
};

const updateCompanyStatus = async (companyId, newStatus) => {
  return axiosInstance.put(`companies/${companyId}/status`, {
    newStatus
  });
};

const leaderships = async companyId => {
  return axiosInstance.get(`companies/${companyId}/company-leadership`);
};

const competitors = async companyId => {
  return axiosInstance.get(`companies/${companyId}/competitors`);
};

const addCompanyCompetitor = async (companyId, competitorId) => {
  return await axiosInstance.post(`companies/${companyId}/competitors`, {
    competitorId
  });
};

const removeCompanyCompetitor = async (companyId, competitorId) => {
  return await axiosInstance.delete(`companies/${companyId}/competitors`, {
    data: { competitorId }
  });
};

const fundingRounds = async companyId => {
  return axiosInstance.get(`companies/${companyId}/funding-rounds`);
};

const topRatedEmployees = async ({ companyId, offset }) => {
  return axiosInstance.get(`companies/${companyId}/top-rated-employees`, {
    params: { offset },
    handlerEnabled: true
  });
};

const graph = async (
  companyId,
  { graphKey, graphDimension = '2d', timeRange }
) => {
  return axiosInstance.get(`companies/${companyId}/graph`, {
    params: {
      metricTypeID: graphKey,
      dimension: graphDimension,
      timeRange
    }
  });
};

const graphTypes = async (companyId, graphDimension) => {
  return axiosInstance.get(`companies/${companyId}/graph-types`, {
    params: graphDimension && graphDimension.length ? { graphDimension } : {},
    handlerEnabled: true
  });
};

const updateCompanySectorIc = async (companyId, newSectorIc) => {
  return axiosInstance.put(`companies/${companyId}/sector-ic`, {
    newSectorIc
  });
};

const updateCompanyProjectedRaiseDate = async (companyId, newProjectedRaiseDate) => {
  return axiosInstance.put(`companies/${companyId}/projected-raise-date`, {
    newProjectedRaiseDate
  });
};

const updateCompanyFinancials = async (companyId, newFinancials) => {
  return axiosInstance.put(`companies/${companyId}/financials`, {
    newFinancials
  });
};

const updateCompanyNextStep = async (companyId, newNextStep) => {
  return axiosInstance.put(`companies/${companyId}/next-step`, {
    newNextStep
  });
};

const updateCompanyUrgent = async (companyId, newUrgent) => {
  return axiosInstance.put(`companies/${companyId}/urgent`, {
    newUrgent
  });
};

export default {
  getCompanyOwner,
  changeCompanySector,
  updateCompanyStatus,
  leaderships,
  competitors,
  addCompanyCompetitor,
  removeCompanyCompetitor,
  fundingRounds,
  topRatedEmployees,
  graph,
  graphTypes,
  updateCompanySectorIc,
  updateCompanyProjectedRaiseDate,
  updateCompanyFinancials,
  updateCompanyNextStep,
  updateCompanyUrgent
};
