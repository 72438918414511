import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import styles from './TextControls.module.scss';

export default ({ filters, filterKey, label, addToFilters }) => {
  const [value, setValue] = useState(filters[filterKey]?.[0]?.value);

  useEffect(() => {
    if (value === filters[filterKey]?.[0]?.value) {
      return;
    }
    const timeout = setTimeout(() => addToFilters({ [filterKey]: value ? [{ label: value, value: value }] : [] }), 1000);
    return () => clearTimeout(timeout);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value]);

  useEffect(() => {
    setValue(filters[filterKey]?.[0]?.value);
  }, [filters, filterKey]);

  const onChange = event => setValue(event.target.value);

  return (
    <div className={styles.textControls}>
      <div className={styles.heading}>{label}</div>
      <Input
        className={styles.text}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
