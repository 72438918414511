import { call, put, takeLatest } from 'redux-saga/effects';
import {
  setPeopleProfile,
  localUpdatePersonStatusAction
} from '../../actions/people/details';
import updateLoader from '../../actions/Loader';
import API from '../../../Network/Apis/people/details';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { PeopleDetailsAction } from '../../types/people/details';

export function* updatePersonStatus({ payload }) {
  try {
    const { id, newStatus } = payload;
    yield put(updateLoader({ statusModalLoading: true }));
    yield call(API.updatePersonStatus, id, newStatus);
    yield put(localUpdatePersonStatusAction(id, newStatus));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ statusModalLoading: false }));
  }
}

export function* getPersonProfile({ payload }) {
  try {
    const id = payload?.id;
    yield put(updateLoader({ peopleProfileLoader: true }));
    const response = yield call(API.getPersonProfile, id);
    yield put(setPeopleProfile(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ peopleProfileLoader: false }));
  }
}

export function* changePersonSector({ payload }) {
  try {
    const { sectorId, personId } = payload;
    yield call(API.changePersonSector, sectorId, personId);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* peopleDetailsSaga() {
  yield takeLatest(
    PeopleDetailsAction.UPDATE_PERSON_STATUS,
    updatePersonStatus
  );
  yield takeLatest(
    PeopleDetailsAction.GET_PEOPLE_PROFILE,
    getPersonProfile
  );
  yield takeLatest(
    PeopleDetailsAction.CHANGE_PERSON_SECTOR,
    changePersonSector
  );
}
