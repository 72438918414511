import _ from 'lodash';
import { axiosInstance } from '../index';

const NOISE_THRESHOLD = 2000;

const history = {};

const isNoisy = (activity, userId, details) => {
  const now = Date.now();
  for (const key in history) {
    const time = history[key];
    if (now - time > NOISE_THRESHOLD) {
      delete history[key];
    }
  }
  const key = JSON.stringify({activity, userId, details});
  const time = history[key];
  history[key] = now;
  return time;
};

const logActivity = async ({activity, userId, details}) => {
  if (isNoisy(activity, userId, details)) {
    return;
  }
  const body = {
    activity,
    userId
  };
  if (!_.isEmpty(details)) {
    body.details = details;
  }
  return await axiosInstance.post(`log/activity`, body);
};

export default {
  logActivity
};
