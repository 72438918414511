import React, { useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { useDeepCompareEffect } from '../../Utils/UseDeepCompareEffect';
import showNotification from '../Shared/Notification';
import { Tooltip, Menu, Dropdown } from 'antd';
import './TableDropdown.scss';
import sharedStyles from '../Shared/Shared.module.scss';

export default ({
  values: allValues = [],
  selection,
  width = '184px',
  tooltip = 'Change Value',
  noneValue = { id: 'None', name: 'None' },
  onSelect
}) => {
  const [values, setValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  useDeepCompareEffect(() => {
    setValues([...allValues, noneValue]);
  }, [allValues]);

  useDeepCompareEffect(() => {
    setSelectedValue(values?.find(value => value.id === selection));
  }, [selection, values]);

  const menuItemStyle = {
    height: '32px',
    color: '#2C2F37',
    fontSize: '14px'
  };

  const menu = (
    <Menu
      style={{
        width: width,
        borderRadius: '4px',
        backgroundColor: '#fff',
        padding: '5px 0'
      }}
    >
      {values.map(value => (
        <Menu.Item
          style={menuItemStyle}
          key={value.id}
          onClick={() => {
            onSelect(value.id, selection);
            setSelectedValue(value);
            showNotification('info', 'Modifications submitted.');
          }}
        >
          <span className={'dropbox__item'}>
            <span
              className={
                (selectedValue?.name || 'None') === value.name
                  ? 'selected-value'
                  : 'unselected-value'
              }
            >
              {value.name}
            </span>
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="table-dropdown">
      <Tooltip
        placement="top"
        overlayClassName={sharedStyles.tooltip}
        title={
          <span>{tooltip}</span>
        }
      >
        <Dropdown
          overlayClassName="table-dropdown-overlay"
          placement="bottomLeft"
          trigger={['click']}
          overlay={menu}
        >
          <a
            className="ant-dropdown-link"
            onClick={e => e.preventDefault()}
            href="/"
          >
            {selectedValue?.name || 'None'}
            <CaretDownOutlined style={{ margin: '0 -3px 0 9px' }} />
          </a>
        </Dropdown>
      </Tooltip>
    </div>
  );
};
