import { CrmEntityAction } from '../../types/crm/entity';

const INITIAL_STATE = {
  notes: {},
  interactions: {},
  loadingMoreInteractions: false
};

const crmEntity = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CrmEntityAction.SET_ENTITY_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.payload.id]: action.payload.notes
        }
      };
    case CrmEntityAction.SET_ENTITY_INTERACTIONS:
      return {
        ...state,
        interactions: {
          ...state.interactions,
          [action.payload.id]: action.payload.interactions
        }
      };
    case CrmEntityAction.GET_MORE_ENTITY_INTERACTIONS:
      return {
        ...state,
        loadingMoreInteractions: true
      };
    case CrmEntityAction.SET_MORE_ENTITY_INTERACTIONS:
      return {
        ...state,
        interactions: {
          ...state.interactions,
          [action.payload.id]: [...state.interactions[action.payload.id], ...action.payload.interactions]
        },
        loadingMoreInteractions: false
      };
    default:
      return state;
  }
};

export default crmEntity;
