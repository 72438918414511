import React from 'react';
import { formatDate } from '../../../Utils/Shared';
import ShowMoreText from 'react-show-more-text';
import styles from './CompanyInformation.module.scss';

export default ({ title = '', content, dateFormat, className='', viewMore, multiline }) => {
  const renderArrayContent = () =>
    content.length ? (
      content.map((elem, i) => <div key={i} className={className}>{elem}</div>)
    ) : (
      <div>N/A</div>
    );

  const renderContent = () =>
    Array.isArray(content) ? (
      renderArrayContent()
    ) : (
      <div className={className}>
        {dateFormat ? formatDate(content, dateFormat) : content}
      </div>
    );

  return (
    <div className={`${styles.companyInformation} ${multiline || Array.isArray(content) ? styles.multiline : ''}`}>
      <div className={styles.title}>{title}</div>
      {viewMore ? (
        <ShowMoreText
          lines={6}
          more={<div>View More</div>}
          less={<div>View Less</div>}
          className={`${styles.content} ${className}`}
          anchorClass="text-control"
          expanded={false}
        >
          {content ? renderContent() : <div>N/A</div>}
        </ShowMoreText>
      ) : content ? (
        renderContent()
      ) : (
        <div>N/A</div>
      )}
    </div>
  );
};
