import React, { useEffect } from 'react';
import PeopleTable from '../PeopleList/PeopleTable/PeopleTable';
import SeeMoreResultsButton from './SeeMoreResultsButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMorePeopleSearchList,
  getPeopleSearchList,
  getSearchPersonSummary,
  getSearchPersonFlag
} from '../../Store/actions/filter/globalSearch';
import sharedStyles from '../Shared/Shared.module.scss';
import styles from './GlobalSearchPeopleTable.module.scss';

export default function GlobalSearchPeopleTable({ keyword }) {
  const dispatch = useDispatch();

  const peopleState = useSelector(state => state.globalSearch.peopleState);
  const lsvpUser = useSelector(state => state.login.lsvpUser);

  useEffect(() => {
    dispatch(
      getPeopleSearchList({
        keyword,
        limit: 50,
        offset: 0,
        userId: lsvpUser.id
      })
    );
  }, [keyword, lsvpUser, dispatch]);

  return (
    <div className={`container-fluid ${sharedStyles.pagePadding}`}>
      <h4 className={styles.searchTitle}>People</h4>
      <PeopleTable
        {...peopleState}
        filters={{ keyword }}
        getPersonSummary={id => dispatch(getSearchPersonSummary(id))}
        getPersonFlag={id => dispatch(getSearchPersonFlag(id))}
        handleLoadMore={false}
        scrollbarStyle={{ x: 'calc(100% - 64px)', y: '488px' }}
      />
      {peopleState.people?.length > 0 && (
        <SeeMoreResultsButton
          onClick={() =>
            dispatch(
              getMorePeopleSearchList({
                keyword,
                limit: 50,
                offset: peopleState.people.length,
                userId: lsvpUser.id
              })
            )
          }
        />
      )}
    </div>
  );
}
