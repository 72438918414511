import { renderArrayCommaSeparated } from '../../Utils/Shared';

export const AffinityLists = ({ crmLists, crmEntityId }) => {
  return crmEntityId
    ? renderArrayCommaSeparated(
        crmLists.map(list => (list.name ? list.name : list.listId)),
        'affinityLists',
        3
      )
    : null;
};
