import { CompanyListAction } from '../../types/company/list';
import { Action } from '../../types/action';
import { CrmIdAction } from '../../types/crm/id';
import { EntityType } from '../../../Utils/Constants';
import {
  localAddAction,
  localRemoveAction,
  localChangeAction
} from '../shared/action';
import { LsvpUserAction } from '../../types/lsvp-user';
import { CompanyDetailsAction } from '../../types/company/details';

export const COMPANIES_INITIAL_STATE = {
  companies: [],
  count: 0,
  message: '',
  loadingMore: false,
  sectors: []
};

export default (state = COMPANIES_INITIAL_STATE, action) => {
  switch (action.type) {
    case CompanyListAction.GET_COMPANIES_LIST_RECEIVE:
      return {
        ...state,
        ...action.payload
      };

    case CompanyListAction.GET_MORE_COMPANIES_LIST_REQUEST:
      return {
        ...state,
        loadingMore: true
      };

    case CompanyListAction.GET_MORE_COMPANIES_LIST_RECEIVE:
      return {
        ...state,
        companies: [...state.companies, ...(action.payload?.companies || [])],
        loadingMore: false
      };

    case Action.LOCAL_ADD_ACTION:
      return localAddAction({
        state,
        type: EntityType.COMPANY,
        key: 'companies',
        params: action.payload
      });

    case Action.LOCAL_REMOVE_ACTION:
      return localRemoveAction({
        state,
        type: EntityType.COMPANY,
        key: 'companies',
        params: action.payload
      });

    case Action.LOCAL_CHANGE_ACTION:
      return localChangeAction({
        state,
        type: EntityType.COMPANY,
        key: 'companies',
        params: action.payload
      });

    case LsvpUserAction.LOCAL_ASSIGN_LSVP_USERS_TO_ENTITY: {
      const { entityId, entityType, addedUserIds, lsvpUsers } = action.payload;
      if (entityType !== EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        companies: state.companies.map(company => 
          company.id === entityId ? {
            ...company,
            owners: [...company.owners, ...lsvpUsers.filter(user => addedUserIds.includes(user.id))]
          } : company
        )
      };
    }

    case LsvpUserAction.LOCAL_UNASSIGN_LSVP_USERS_FROM_ENTITY: {
      const { entityId, entityType, removedUserIds } = action.payload;
      if (entityType !== EntityType.COMPANY) {
        return state;
      }
      return {
        ...state,
        companies: state.companies.map(company => 
          company.id === entityId ? {
            ...company,
            owners: company.owners.filter(owner => !removedUserIds.includes(owner.id))
          } : company
        )
      };
    }

    case CompanyDetailsAction.LOCAL_UPDATE_COMPANY_STATUS:
      return {
        ...state,
        companies: state.companies.map(company => company.id === action.payload.companyId ? { ...company, status: action.payload.newStatus } : company)
      };

    case CrmIdAction.LOCAL_UPDATE_CRM_ID: {
      const { id, entityType, crmEntityId } = action.payload;
      if (entityType !== EntityType.COMPANY) { return state; }
      return {
        ...state,
        companies: state.companies.map(company => company.id === id ? {
          ...company,
          crmEntityId,
          summary: company.summary ? { ...company.summary, crmEntityId } : undefined
        } : company)
      };
    }

    default:
      return state;
  }
};
