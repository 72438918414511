import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../../Network/Apis/action';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { Action } from '../../types/action';
import {
  localAddAction,
  localRemoveAction,
  localChangeAction
} from '../../actions/action/actions';

export function* addAction({ payload }) {
  try {
    yield call(API.addAction, payload);
    yield put(localAddAction(payload));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* removeAction({ payload }) {
  try {
    yield call(API.removeAction, payload);
    yield put(localRemoveAction(payload));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* changeAction({ payload }) {
  try {
    yield call(API.changeAction, payload);
    yield put(localChangeAction(payload));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* actionSaga() {
  yield takeEvery(
    Action.ADD_ACTION,
    addAction
  );
  yield takeEvery(
    Action.REMOVE_ACTION,
    removeAction
  );
  yield takeEvery(
    Action.CHANGE_ACTION,
    changeAction
  );
}
