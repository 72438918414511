import { call, put, takeEvery, select } from 'redux-saga/effects';
import API from '../../../Network/Apis/company/summary';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { PipelineSummaryAction } from '../../types/pipeline/summary';
import { getPipelineListResponse } from '../../actions/pipeline/list';

const addSummaryToCompany = (companiesList, summary, message) => ({
  ...companiesList,
  message,
  companies: companiesList.companies.map(company =>
    company.id === summary.id
      ? { ...company, summary }
      : company
  )
});

function* setCompanySummary(summary, message) {
  const companiesList = yield select(state => state.pipelineList);

  const updatedCompaniesList = addSummaryToCompany(
    companiesList,
    summary,
    message
  );

  yield put(getPipelineListResponse(updatedCompaniesList));
}

export function* companySummary({ payload }) {
  try {
    const response = yield call(
      API.getCompanySummary,
      payload.companyId,
      payload.params
    );
    const { companySummary, message } = response.data.data;
    yield setCompanySummary(companySummary, message);
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* pipelineSummarySaga() {
  yield takeEvery(PipelineSummaryAction.GET_PIPELINE_SUMMARY_REQUEST, companySummary);
}
