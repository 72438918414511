import { PeopleListAction } from '../../types/people/list';
import { Action } from '../../types/action';
import { EntityType } from '../../../Utils/Constants';
import {
  localAddAction,
  localRemoveAction,
  localChangeAction
} from '../shared/action';
import { LsvpUserAction } from '../../types/lsvp-user';
import { PeopleDetailsAction } from '../../types/people/details';

export const PEOPLE_INITIAL_STATE = {
  people: [],
  message: '',
  count: 0,
  loadingMore: false,
  highlights: [],
  sectors: [],
  schools: []
};

export default (state = PEOPLE_INITIAL_STATE, action) => {
  switch (action.type) {
    case PeopleListAction.SET_PEOPLE_LIST:
      return {
        ...state,
        ...action.payload
      };

    case PeopleListAction.GET_MORE_PEOPLE_LIST:
      return {
        ...state,
        loadingMore: true
      };

    case PeopleListAction.SET_MORE_PEOPLE_LIST:
      return {
        ...state,
        people: [...state.people, ...(action.payload?.people || [])],
        loadingMore: false
      };

    case PeopleListAction.UPDATE_PEOPLE_LIST:
      return {
        ...state,
        ...action.payload
      };

    case PeopleListAction.SET_PEOPLE_COUNT:
      return {
        ...state,
        count: action.payload?.count
      };

    case Action.LOCAL_ADD_ACTION:
      return localAddAction({
        state,
        type: EntityType.PERSON,
        key: 'people',
        params: action.payload
      });

    case Action.LOCAL_REMOVE_ACTION:
      return localRemoveAction({
        state,
        type: EntityType.PERSON,
        key: 'people',
        params: action.payload
      });

    case Action.LOCAL_CHANGE_ACTION:
      return localChangeAction({
        state,
        type: EntityType.PERSON,
        key: 'people',
        params: action.payload
      });

    case LsvpUserAction.LOCAL_ASSIGN_LSVP_USERS_TO_ENTITY: {
      const { entityId, entityType, addedUserIds, lsvpUsers } = action.payload;
      if (entityType !== EntityType.PERSON) {
        return state;
      }
      return {
        ...state,
        people: state.people.map(person => 
          person.id === entityId ? {
            ...person,
            owners: [...person.owners, ...lsvpUsers.filter(user => addedUserIds.includes(user.id))]
          } : person
        )
      };
    }

    case LsvpUserAction.LOCAL_UNASSIGN_LSVP_USERS_FROM_ENTITY: {
      const { entityId, entityType, removedUserIds } = action.payload;
      if (entityType !== EntityType.PERSON) {
        return state;
      }
      return {
        ...state,
        people: state.people.map(person => 
          person.id === entityId ? {
            ...person,
            owners: person.owners.filter(owner => !removedUserIds.includes(owner.id))
          } : person
        )
      };
    }

    case PeopleDetailsAction.LOCAL_UPDATE_PERSON_STATUS:
      return {
        ...state,
        people: state.people.map(person => person.id === action.payload.personId ? { ...person, status: action.payload.newStatus } : person)
      };

    default:
      return state;
  }
};
