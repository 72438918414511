import { TriggerAction } from '../../types/trigger';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TriggerAction.SET_TRIGGERS:
      return action.payload.triggers;
    default:
      return state;
  }
};
