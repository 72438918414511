import React from 'react';
import {
  formatDate,
  getDifferenceBetweenDates
} from '../../../Utils/Shared';
import styles from './PriorPosition.module.scss';

export default ({ priorPosition }) => {
  if (
    !priorPosition
    ||
    !priorPosition.position
    ||
    !priorPosition.positionStartDate
    ||
    !priorPosition.positionEndDate
    ||
    (!priorPosition.companyName && !priorPosition.companyId)
  ) {
    return null;
  }
  const start = priorPosition.positionStartDate.slice(0, 10);
  const end = priorPosition.positionEndDate.slice(0, 10);
  const { years, months } = getDifferenceBetweenDates(
    formatDate(start),
    formatDate(end)
  );
  return (
    <div className={styles.priorPosition}>
      <div>{priorPosition.companyName || priorPosition.companyId}</div>
      <div>{priorPosition.position}, <span>{` ${years}y ${months}m`}</span></div>
      <div>{`${formatDate(start, 'MMM YYYY')} - ${formatDate(end, 'MMM YYYY')}`}</div>
    </div>
  );
};
