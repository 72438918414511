import { call, put, takeEvery, select } from 'redux-saga/effects';
import API from '../../../Network/Apis/crypto/summary';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CryptoSummaryAction } from '../../types/crypto/summary';
import { setCryptoList } from '../../actions/crypto/list';

const addSummaryToCrypto = (cryptoList, cryptoSummary, message) => ({
  ...cryptoList,
  message,
  cryptos: cryptoList.cryptos.map(crypto =>
    crypto.id === cryptoSummary.id
      ? { ...crypto, cryptoSummary }
      : crypto
  )
});

function* setCryptoSummary(cryptoSummary, message) {
  const cryptoList = yield select(state => state.cryptoList);

  const updatedCryptoList = addSummaryToCrypto(
    cryptoList,
    cryptoSummary,
    message
  );

  yield put(setCryptoList(updatedCryptoList));
}

export function* cryptoSummary({ payload }) {
  try {
    const response = yield call(
      API.getCryptoSummary,
      payload.cryptoId,
      payload.params
    );
    const { cryptoSummary, message } = response.data.data;
    yield setCryptoSummary(cryptoSummary, message);
  } catch (err) {
    dispatchSnackbarError(err?.response?.data);
  }
}

export function* cryptoSummarySaga() {
  yield takeEvery(CryptoSummaryAction.GET_CRYPTO_SUMMARY, cryptoSummary);
}
