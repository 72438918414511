import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Layout, Menu, Avatar, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { menuItems } from './../../Utils/Constants';
import { NavLink } from 'react-router-dom';
import SideDrawer from './SideDrawer';
import AtlasLogo from '../../Assets/images/Atlas.svg';
import PATH_NAME from '../../Routes/PathNames';
import sharedStyles from '../Shared/Shared.module.scss';
import styles from './NavBar.module.scss';

export default forwardRef(({ onSearchValueChange }, ref) => {
  const [searchValue, setSearchValue] = useState('');
  const { Header } = Layout;

  useEffect(() => {
    onSearchValueChange(searchValue);
  }, [onSearchValueChange, searchValue]);

  useImperativeHandle(ref, () => ({
    clear: () => setSearchValue('')
  }));

  return (
    <Header
      className={`${styles.navBar} ${sharedStyles.pagePadding} d-flex justify-content-between align-items-center w-100`}
      theme="light"
    >
      <div className={`${styles.leftSide} d-flex justify-content-start align-items-center w-100`}>
        <NavLink className={styles.linkLogo} exact to="/">
          <img src={AtlasLogo} alt="Atlas Logo" />
        </NavLink>
        <SideDrawer />
        <Menu className={styles.navlink} mode="horizontal" defaultSelectedKeys={['2']}>
          {menuItems.map((item, index) =>
            process.env.REACT_APP_STAGE === 'production' &&
            item.route !== PATH_NAME.HOME &&
            item.route !== PATH_NAME.COMPANIES &&
            item.route !== PATH_NAME.PEOPLE &&
            item.route !== PATH_NAME.FUNDING_ROUNDS &&
            item.route !== PATH_NAME.PIPELINE &&
            item.route !== PATH_NAME.FLAGS ? (
              <></>
            ) : (
              <Menu.Item key={index}>
                <NavLink
                  className={styles.link}
                  exact
                  to={item.route}
                  onClick={() => setSearchValue('')}
                >
                  {item?.label}
                </NavLink>
              </Menu.Item>
            )
          )}
        </Menu>
        <Input.Search
          className={styles.searchField}
          placeholder="Search"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        >
        </Input.Search>
      </div>
      <div className={styles.navAvatarWrapper}>
        <Avatar
          className={styles.navAvatar}
          icon={<UserOutlined />}
          alt="avatar-img"
        />
      </div>
    </Header>
  );
});
