import { axiosInstance } from '../index';

export const fetchEntityNotes = async (entityId, entityType) => {
  return axiosInstance.get(`entities/${entityId}/notes`, {
    params: { entityType },
    handlerEnabled: true
  });
};

export const createEntityNote = async (
  entityId,
  entityType,
  content,
  creatorId,
  mentionList
) => {
  return axiosInstance.post(`entities/${entityId}/notes`, {
    entityType,
    note: content,
    creatorId,
    mentionList
  });
};

export const updatehEntityNote = async (noteId, content) => {
  return axiosInstance.put(`entities/notes/${noteId}`, {
    noteContent: content
  });
};

export const deleteEntityNote = async noteId => {
  return axiosInstance.delete(`entities/notes/${noteId}`);
};

export const getEntityInteractions = async ({ companyId, limit, offset }) => {
  return axiosInstance.get(`companies/${companyId}/interactions`, {
    params: { limit, offset },
    handlerEnabled: true
  });
};

export default {
  fetchEntityNotes,
  createEntityNote,
  updatehEntityNote,
  deleteEntityNote,
  getEntityInteractions
};
