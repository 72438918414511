import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/pipeline/list';
import {
  getPipelineListResponse,
  getMorePipelineListResponse
} from '../../actions/pipeline/list';
import updateLoader from '../../actions/Loader';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { PipelineListAction } from '../../types/pipeline/list';

function* pipelineList({ payload }) {
  try {
    yield put(updateLoader({ listLoader: true }));
    const response = yield call(API.getPipelineList, payload);
    yield put(getPipelineListResponse(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ listLoader: false }));
  }
}

function* downloadPipelineList({ payload }) {
  try {
    yield put(updateLoader({ csvLoader: true }));
    const { downloadResolver, params } = payload;
    const response = yield call(API.downloadPipelineList, params);
    downloadResolver(response?.data?.data);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ csvLoader: false }));
  }
}

function* morePipelineList({ payload }) {
  try {
    const response = yield call(API.getPipelineList, payload);
    yield put(getMorePipelineListResponse(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* pipelineListSaga() {
  yield takeLatest(PipelineListAction.GET_PIPELINE_LIST_REQUEST, pipelineList);
  yield takeLatest(PipelineListAction.DOWNLOAD_PIPELINE_CSV, downloadPipelineList);
  yield takeLatest(PipelineListAction.GET_MORE_PIPELINE_LIST_REQUEST, morePipelineList);
}
