import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/flag/list';
import * as Actions from '../../actions/flag/list';
import updateLoader from '../../actions/Loader';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { FlagListAction } from '../../types/flag/list';

export function* flagList({ payload }) {
  try {
    yield put(updateLoader({ listLoader: true }));
    const response = yield call(API.getFlagList, payload);
    yield put(Actions.setFlagList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ listLoader: false }));
  }
}

export function* moreFlagList({ payload }) {
  try {
    const response = yield call(API.getFlagList, payload);
    yield put(Actions.setMoreFlagList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* flagCount({ payload }) {
  try {
    const response = yield call(API.getFlagCount, payload);
    yield put(Actions.setFlagCount(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* downloadFlagList({ payload }) {
  try {
    yield put(updateLoader({ csvLoader: true }));
    const { downloadResolver, params } = payload;
    const response = yield call(API.downloadFlagList, params);
    downloadResolver(response?.data?.data);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ csvLoader: false }));
  }
}

export function* flagListSaga() {
  yield takeLatest(FlagListAction.GET_FLAG_LIST, flagList);
  yield takeLatest(FlagListAction.GET_MORE_FLAG_LIST, moreFlagList);
  yield takeLatest(FlagListAction.GET_FLAG_COUNT, flagCount);
  yield takeLatest(FlagListAction.DOWNLOAD_FLAG_CSV, downloadFlagList);
}
