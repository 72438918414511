import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { formatNumber } from '../../Utils/NumberFormatter';
import SearchableSelect from '../Shared/SearchableSelect';
import { stopPropagation } from '../../Utils/Shared';
import {
  filterKeys,
  growthMetricOptions,
  growthMetricOptionDrilldown,
  growthMetricMap,
  scoreRange
} from '../../Utils/Constants';
import Range from './Range';
import styles from './GrowthMetricControls.module.scss';

export default ({
  filters,
  addToFilters
}) => {
  const getValues = () => {
    const mm = filters[filterKeys.score]?.[0]?.value.split('-');
    if (!mm) { return { min: 'any', max: 'any' }; }
    return { min: mm[0], max: mm[1] };
  };

  const [min, setMin] = useState(getValues().min);
  const [max, setMax] = useState(getValues().max);

  useEffect(() => {
    const values = getValues();
    setMin(values.min);
    setMax(values.max);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters]);

  const isAny = value => !value || value === 'any';

  const getFilter = (lo, hi) => {
    if (isAny(lo) && isAny(hi)) { return []; }
    const filter = [{
      label: `${isAny(lo) ? 'Any' : formatNumber({ amount: lo })}-${isAny(hi) ? 'Any' : formatNumber({ amount: hi })}`,
      value: `${isAny(lo) ? '' : lo}-${isAny(hi) ? '' : hi}`
    }];
    return filter;
  };

  const save = (lo, hi) => addToFilters({ [filterKeys.score]: getFilter(lo, hi) });

  useEffect(() => {
    if (_.isEqual(getFilter(min, max), filters[filterKeys.score])) { return; }
    const timeout = setTimeout(() => save(min, max), 1000);
    return () => clearTimeout(timeout);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [min, max]);

  const getGrowthItems = () => {
    if (!filters[filterKeys.growth]?.length) { return []; }
    const value = growthMetricMap[filters[filterKeys.growth][0].value];
    if (value) { return growthMetricOptions.find(option => option.value === value); }
    return filters[filterKeys.growth];
  };

  const getGrowthDrilldownItems = () => {
    if (!filters[filterKeys.growth]?.length) { return []; }
    const value = growthMetricMap[filters[filterKeys.growth][0].value];
    if (!value) { return []; }
    return growthMetricOptionDrilldown[value].find(option => option.value === filters[filterKeys.growth][0].value);
  };

  const getGrowthDrilldownOptions = () => {
    if (!filters[filterKeys.growth]?.length) { return []; }
    const value = growthMetricMap[filters[filterKeys.growth][0].value];
    if (!value) { return []; }
    return growthMetricOptionDrilldown[value];
  };

  const onGrowthChange = filter => addToFilters({ [filterKeys.growth]: filter });

  return (
    <div
      className={styles.growthMetricControls}
      onScroll={stopPropagation}
    >
      <div className={styles.heading}>Order by Atlas Growth</div>
      <SearchableSelect
        className={styles.growthSelector}
        items={getGrowthItems()}
        staticOptions={growthMetricOptions}
        anyKey="never"
        onChange={onGrowthChange}
      />
      {getGrowthDrilldownOptions().length ? (
        <SearchableSelect
          className={styles.growthDrilldownSelector}
          items={getGrowthDrilldownItems()}
          staticOptions={getGrowthDrilldownOptions()}
          onChange={onGrowthChange}
        />
      ) : null}
      <Range
        className={styles.range}
        min={min}
        max={max}
        range={scoreRange}
        onChangeMin={setMin}
        onChangeMax={setMax}
      />
    </div>
  );
};
