import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import sharedStyles from '../Shared/Shared.module.scss';
import styles from './Highlights.module.scss';

const getAbbreviation = highlight => {
  const words = highlight.split(/\s+/);
  return (words.length > 1 ? words.map(word => word[0]).join('') : words[0].slice(0, 2)).toUpperCase();
};

export default ({ highlights }) =>  {
  if (!highlights?.length) { return null; }
  const highlightMap = useSelector(state => state.peopleList.highlights).reduce((map, highlight) => {
    map[highlight.id] = highlight;
    return map;
  }, {});
  return (
    <div className={styles.highlights}>
      {highlights.map(id => {
        const highlight = highlightMap[id];
        const { color, name, abbreviation, description } = highlight ?? {
          color: '#808080',
          name: id,
          abbreviation: getAbbreviation(id),
          description: ''
        };
        return (
          <Tooltip
            key={id}
            placement="top"
            overlayClassName={sharedStyles.tooltip}
            title={
              <div>
                <div>{name}</div>
                {description ? <div className={styles.description}>{description}</div> : null}
              </div>
            }
          >
            <div className="position-relative">
              <div
                style={{
                  backgroundColor: color,
                  opacity: 0.1
                }}
                className={`${styles.highlight}`}
              >
                <div
                  className={styles.abbreviation}
                  style={{
                    color
                  }}
                >{abbreviation}</div>
              </div>
              <div
                style={{
                  backgroundColor: 'transparent',
                  position: 'absolute'
                }}
                className={`${styles.highlight}`}
              >
                <div
                  className={styles.abbreviation}
                  style={{
                    color
                  }}
                >{abbreviation}</div>
              </div>
            </div>
          </Tooltip>
        )
      })}
    </div>
  );
}
