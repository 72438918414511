import React, { useState } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import store from '../Store';
import { showSnackbarAction } from '../Store/actions/snackbar';
import {
  Status,
  FlagState
} from './Constants';
import { Link } from 'react-router-dom';
import { logError } from './ErrorReport';
import showNotification from '../Components/Shared/Notification';
import AffinityStatusModal from '../Components/AffinityStatusModal/AffinityStatusModal';
import PathNames from '../Routes/PathNames';
import { toAbsoluteUrl } from './Utils';

const mainRegions = [
  'United States',
  'China',
  'Europe',
  'India',
  'Israel',
  'Latin America'
];

export const StatusColor = {
  [Status.NAMED_ACCOUNT]: 'green',
  [Status.ACTIVE_DEAL]: 'green',
  [Status.TOP_OF_FUNNEL]: 'orange',
  [Status.NEW]: 'green',
  [Status.PROSPECT]: 'green',
  [Status.TOP_PROSPECT]: 'blue',
  [Status.HARD_TO_CRACK]: 'blue',
  [Status.IN_CONTACT]: 'blue',
  [Status.TRACKING]: 'orange',
  [Status.DUE_DILIGENCE]: 'purple',
  [Status.ONE_PAGER]: 'purple',
  [Status.TERM_SHEET]: 'purple',
  [Status.PORTFOLIO]: 'orange',
  [Status.DECLINE]: 'Decline',
  [Status.LOST]: 'red',
  [Status.PASSED]: 'red'
};

export const FlagStateColor = {
  [FlagState.NEW]: 'green',
  [FlagState.ACCEPTED]: 'green',
  [FlagState.TRACKING]: 'blue',
  [FlagState.REJECTED]: 'red',
  [FlagState.PORTFOLIO]: 'orange',
  [FlagState.REACHED_OUT]: 'blue',
  [FlagState.MET]: 'blue'
};

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const formatDate = (
  date,
  format = DEFAULT_DATE_FORMAT,
  invalidDateMsg = 'None'
) => (date ? moment(date).format(format) : invalidDateMsg);

export const getDifferenceBetweenDates = (firstDate, secondDate) => {
  const m2 = moment(secondDate, DEFAULT_DATE_FORMAT);
  const years = m2.diff(firstDate, 'years');
  const months = m2.diff(firstDate, 'months') - years * 12;
  return { years, months };
};

export const daysAgo = date => {
  const other = moment(date);
  const now = moment();
  const days = now.diff(other, 'days');
  if (days > 30) {
    const format = now.diff(other, 'years') < 1 ? 'MMM D' : 'MMM YYYY';
    return other.format(format);
  }
  switch (days) {
    case 0:
      return 'today';
    case 1:
      return 'yesterday';
    default:
      return `${days} days ago`;
  }
};

export function dispatchSnackbarError(data) {
  if (data) {
    let errorMsg = data?.error?.message || data?.message || data?.error || data;
    if (typeof errorMsg === 'object') {
      errorMsg = JSON.stringify(errorMsg);
    }
    logError(errorMsg);
    showNotification('error', 'Error', errorMsg);
    store.dispatch(showSnackbarAction(errorMsg, 'error'));
  }
}

export function dispatchSnackbarSuccess(message) {
  showNotification('success', message);
  store.dispatch(showSnackbarAction(message, 'success'));
}

export function capitalizeFirstLetter(word) {
  if (!word) return;
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const AffinityStatus = ({
  status,
  id,
  crmEntityId,
  entityType,
  editable = true,
  onChange
}) => {
  const color = StatusColor[status];
  const [visible, setVisible] = useState(false);
  const canEdit = editable && crmEntityId;
  return (
    <>
      <Tag
        style={{ cursor: canEdit ? 'pointer' : 'default' }}
        color={color}
        onClick={() => { if (canEdit) { setVisible(true); } }}
        key={id}
      >
        {status?.toUpperCase() || 'NONE'}
      </Tag>
      <AffinityStatusModal
        visible={visible}
        onClose={() => setVisible(false)}
        status={status}
        entityId={id}
        entityType={entityType}
        onChange={onChange}
      />
    </>
  );
};

export function renderOwners(owners) {
  return (
    owners &&
    owners.map((owner, index) => {
      if (!owner) return null;
      return (
        <span key={index}>
          <a
            href={'mailto:' + owner.email}
            style={{
              color: '#2061ef',
              textDecoration: 'none',
              whiteSpace: 'normal'
            }}
          >
            {owner.firstName} {owner.lastName}
          </a>
          {index < owners.length - 1 ? ', ' : ''}
        </span>
      );
    })
  );
}

export function renderNumberCommaSeparated(number) {
  return hasValue(number)
    ? Intl.NumberFormat(Intl.locale).format(number)
    : 'N/A';
}

export function renderArrayCommaSeparated(
  items,
  className = '',
  maxDisplay = 10
) {
  const renderMaxItems = () =>
    items.slice(0, maxDisplay).map((item, index) => {
      if (!item) return <></>;
      return (
        <span key={index} className={className}>
          {item}
          {index < items.length - 1 ? ', ' : ''}
        </span>
      );
    });

  const renderRestAsNumber = () => (
    <span key={items.length} className={className}>
      {items.length - maxDisplay > 0
        ? '+'.concat(`${items.length - maxDisplay}`)
        : ''}
    </span>
  );

  return items && items.length
    ? [...renderMaxItems(), renderRestAsNumber()]
    : 'N/A';
}

export function renderLocation(items) {
  items = items.filter(item => item);
  return items.join(', ');
}

export function renderFundingRoundInvestors(items, leadOnly = true, notableClassName = '') {
  return items
    ? items.map((item, index) => {
        if (!item || (leadOnly && !item.isLeadInvestor)) return '';
        const url =
          item.type === 'company'
            ? `${PathNames.COMPANIES}/${item.id}`
            : `${PathNames.PEOPLE}/${item.id}`;
        return (
          <span key={index}>
            <Link to={url} target="_blank" className={item.isNotableInvestor ? notableClassName : ''}>
              {item.name}
            </Link>
            {index < items.length - 1 ? ', ' : ''}
          </span>
        );
      })
    : 'N/A';
}

export function hasValue(item) {
  return item !== null && item !== undefined;
}

export function roundDecimal(number) {
  return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
}

export function isValidArray(array) {
  return Array.isArray(array) && array.length;
}

export const parseLink = link => '//' + link;

export const codeRepeater = n => {
  const repeatArray = [];
  for (let i = 0; i < n; i++) {
    repeatArray.push(i);
  }
  return repeatArray;
};

export const isEmptyObject = value => (
  value && typeof value === 'object' && !Array.isArray(value) && !Object.keys(value).length
);

export const isEmptyFilter = value => {
  if (Array.isArray(value)) {
    return _.isEmpty(value.filter(v => !isEmptyFilter(v)));
  }
  if (_.isEmpty(value)) {
    return true;
  }
  return typeof value === 'object' && value.value === undefined;
};

export const hasDefaultSort = (sort = [], key) => {
  for (const [column, order] of sort) {
    if (key === column) {
      return order === 'ASC' ? 'ascend' : 'descend';
    }
  }
  return undefined;
};

export const downloadResolver = url => {
  window.open(url);
};

export const stopPropagation = e => e.stopPropagation();

export const getSocialMediaLinks = (json = '') => {
  try {
    return _.mapValues(JSON.parse(json), toAbsoluteUrl);
  } catch (e) {
    return {};
  }
};

export const sanitizeLink = link => (
  link.startsWith('https://') || link.startsWith('http://') || link.startsWith('mailto:') ? link : `https://${link}`
);

export const renderMainRegion = regions => regions.reduce((oldMain, region) => { 
  const newMain = mainRegions.find(main => main === region);
  return newMain ?? oldMain;
}, 'Other');

export const getFlagState = (states) => {
  states = states.filter(({ state }) => state !== FlagState.FUNDING_EVENT);

  if (!states?.[0]) { return undefined; }

  const weights = {
    [FlagState.NEW]: 0,
    [FlagState.REJECTED]: 1,
    [FlagState.TRACKING]: 2,
    [FlagState.ACCEPTED]: 3,
    [FlagState.REACHED_OUT]: 4,
    [FlagState.MET]: 5,
    [FlagState.PORTFOLIO]: 6
  };

  states.sort(({ date: d1, state: s1 }, { date: d2, state: s2 }) => {
    if (d1 < d2) { return 1; }
    if (d2 < d1) { return -1; }
    if (weights[s1] < weights[s2]) { return 1; }
    if (weights[s2] < weights[s1]) { return -1; }
    return 0;
  });

  return states[0].state;
};

export const comparator = (v1, v2) => {
  if (v1 < v2) { return -1; }
  if (v1 > v2) { return 1; }
  return 0;
};

export const getFlagInteraction = ({
  flagDate,
  interactions,
  maxDays,
  owners
}) => interactions.filter(({ userId, date }) => (
  !moment(date).isBefore(moment(flagDate))
  &&
  moment(date).isBefore(moment(flagDate).add(maxDays, 'days'))
  &&
  owners.find(({ id }) => id === userId)
)).sort(({ date: d1 }, { date: d2 }) => comparator(d1, d2)).shift();

export const renderInteraction = ({
  type,
  emailed,
  messaged,
  met,
  interactionClassName = '',
  descriptionClassName = ''
}) => {
  let interaction = null;
  let action;
  switch (type) {
    case FlagState.REACHED_OUT:
      if (emailed && messaged) {
        if (messaged.date < emailed.date) {
          interaction = messaged;
          action = 'Messaged';
        } else {
          interaction = emailed;
          action = 'Emailed';
        }
      } else if (emailed) {
        interaction = emailed;
        action = 'Emailed';
      } else if (messaged) {
        interaction = messaged;
        action = 'Messaged';
      }
      break;
    case FlagState.MET:
      if (met) {
        interaction = met;
        action = 'Met';
      }
      break;
    default:
      break;
  }

  return interaction ? (
    <div className={interactionClassName}>
      <span className={descriptionClassName}><span role="img" aria-label="check">&#9989;</span> {`${action} ${moment(interaction.date).format('M/D/YY')} by ${interaction.name}`}</span>
    </div>
  ) : null;
};
