import React, { useEffect, useState, useRef } from 'react';
import { Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Layout, Spin } from 'antd';
import history from '../Routes/History';
import Routes from '../Routes/Routes';
import { IntlProvider } from 'react-intl';
import { debounce } from 'lodash';
import Navbar from '../Components/Navbar/Navbar';
import CsvLoadingBar from '../Components/CsvLoadingBar/CsvLoadingBar';
import messages from '../Assets/Local/messages';
import './App.scss';
import GlobalSearchResults from '../Components/GlobalSearchResults/GlobalSearchResults';
import { LoadingOutlined } from '@ant-design/icons';
import { authenticateUser } from '../Utils/Auth';
import { useDeepCompareEffect } from '../Utils/UseDeepCompareEffect';
import * as FullStory from '@fullstory/browser';
import { getAffinityLists } from '../Store/actions/crm/list';
import { getTeamViews } from '../Store/actions/team/teamViews';
import { getTeamMembers } from '../Store/actions/team/teamMembers';
import { getSectors } from '../Store/actions/sector/list';
import { getAutoFlags } from '../Store/actions/auto';
import { getTriggers } from '../Store/actions/trigger';
import { PATH_NAME } from '../Routes/PathNames';
import { UserRole } from '../Utils/Constants';
import Error from '../Components/Error';

export default () => {
  const { currentUser, lsvpUser } = useSelector(state => state.login);
  const lang = useSelector(({ lang }) => lang);
  const loading = useSelector(({ loader }) => loader.csvLoader);
  const crmLists = useSelector(({ crmLists }) => crmLists);
  const teamViews = useSelector(({ teamViews }) => teamViews);
  const teamMembers = useSelector(({ teamMembers }) => teamMembers);
  const sectors = useSelector(({ sectors }) => sectors);
  const autoFlags = useSelector(({ autoFlags }) => autoFlags);
  const triggers = useSelector(({ triggers }) => triggers);
  const [globalSearchValue, setGlobalSearchValue] = useState('');
  const dispatch = useDispatch();
  const navBar = useRef();
  const { Content } = Layout;

  const isPublicPage = () => (window.location.pathname === PATH_NAME.ERROR || window.location.pathname === PATH_NAME.LANDING);

  useEffect(() => { if (!isPublicPage()) { authenticateUser(); } }, []);

  useEffect(() => {
    if (lsvpUser && lsvpUser.role === UserRole.DEACTIVATED) { window.location.href = PATH_NAME.ERROR; }
  }, [lsvpUser]);

  useDeepCompareEffect(() => {
    if (lsvpUser) {
      const userVars = {
        id: lsvpUser.id,
        displayName: `${lsvpUser.first_name} ${lsvpUser.last_name}`,
        firstName: lsvpUser.first_name,
        lastName: lsvpUser.last_name,
        email: lsvpUser.email,
        crmUserId: lsvpUser.crm_user_id
      };

      FullStory.identify(lsvpUser.email || lsvpUser.id, userVars);
    }
  }, [lsvpUser]);

  useDeepCompareEffect(() => {
    if (lsvpUser && !crmLists.lists.length) {
      dispatch(getAffinityLists());
    }
  }, [crmLists.lists, lsvpUser]);

  useDeepCompareEffect(() => {
    if (lsvpUser && _.isEmpty(teamViews)) {
      dispatch(getTeamViews({ userId: lsvpUser.id }));
    }
  }, [teamViews, lsvpUser]);

  useDeepCompareEffect(() => {
    if (lsvpUser && _.isEmpty(teamMembers)) {
      dispatch(getTeamMembers());
    }
  }, [teamMembers, lsvpUser]);

  useDeepCompareEffect(() => {
    if (lsvpUser && _.isEmpty(sectors)) {
      dispatch(getSectors());
    }
  }, [sectors, lsvpUser]);

  useDeepCompareEffect(() => {
    if (lsvpUser && _.isEmpty(autoFlags)) {
      dispatch(getAutoFlags());
    }
  }, [autoFlags, lsvpUser]);

  useDeepCompareEffect(() => {
    if (lsvpUser && _.isEmpty(triggers)) {
      dispatch(getTriggers());
    }
  }, [triggers, lsvpUser]);

  const isActive = () => (currentUser || isPublicPage());

  const onSearchValueChange = debounce(
    value => setGlobalSearchValue(value),
    1000
  );

  const onCloseGlobalSearch = () => {
    navBar.current.clear();
    setGlobalSearchValue('');
  };

  return (
    window.location.pathname === PATH_NAME.ERROR ? (
      <Error />
    ) : (
      <>
        {!isActive() ? (
          <div className="spin-container">
            <Spin indicator={<LoadingOutlined spin />} />
          </div>
        ) : null}
        <IntlProvider locale={lang} messages={messages[lang]}>
          <Router history={history}>
            <div
              className="height-100"
              dir={'ltr'}
              style={isActive() ? {} : { display: 'none' }}
            >
              {loading ? <CsvLoadingBar /> : null}
              <Layout className="layout height-100">
                <Navbar onSearchValueChange={onSearchValueChange} ref={navBar} />
                {_.isEmpty(globalSearchValue) ? (
                  <Content className="bg-white m-0 height-100">
                    <div className="site-layout-content container-fluid p-0 m-0 height-100">
                      {Routes()}
                    </div>
                  </Content>
                ) : (
                  <GlobalSearchResults
                    keyword={globalSearchValue}
                    onClose={onCloseGlobalSearch}
                  />
                )}
              </Layout>
            </div>
          </Router>
        </IntlProvider>
      </>
    )
  );
};
