import React from 'react';
import About from './About';
import Education from './Education';
import Experiences from './Experiences';
import { EntityType } from '../../../Utils/Constants';
import AffinityId from '../../Shared/AffinityId';
import './Summary.scss';

export default ({ person, team, options }) => {
  const { education = [], experiences = [] } = person.summary || {};
  return (
    <div className="people-summary">
      <About person={person} options={options} />
      <Education education={education} />
      <Experiences experiences={experiences} team={team} />
      <AffinityId
        id={person.id}
        entityType={EntityType.PERSON}
        crmEntityId={person.crmEntityId}
      />
    </div>
  );
};
