import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { stopPropagation } from '../../Utils/Shared';
import ActionPopover from './ActionPopover';
import Actions from '../Shared/Actions';
import styles from './ActionButton.module.scss';

export default ({
  actions,
  entityId,
  entityType,
  teamIds,
  showSnoozed,
  showBlocked,
  team,
  owners: originalOwners,
  status: originalStatus,
  flag,
  availableGraphs,
  companyId,
  companyActions,
  singleActionType,
  tooltipPlacement = 'topRight',
  getCompanyFlag,
  getPersonFlag,
  getFundingRoundFlag
}) => {

  const renderTitle = () => {
    return (
      <div
        className={styles.tooltipContent}
        onScroll={stopPropagation}
      >
        <Actions
          actions={actions}
          entityType={entityType}
          companyActions={companyActions}
        />
      </div>
    );
  };

  return (
    <Tooltip
      overlayClassName={styles.tooltip}
      placement={tooltipPlacement}
      title={renderTitle()}
    >
      <ActionPopover
        actions={actions}
        entityId={entityId}
        entityType={entityType}
        teamIds={teamIds}
        showSnoozed={showSnoozed}
        showBlocked={showBlocked}
        team={team}
        originalOwners={originalOwners}
        originalStatus={originalStatus}
        flag={flag}
        availableGraphs={availableGraphs}
        companyId={companyId}
        companyActions={companyActions}
        singleActionType={singleActionType}
        getCompanyFlag={getCompanyFlag}
        getPersonFlag={getPersonFlag}
        getFundingRoundFlag={getFundingRoundFlag}
      >
        <div
          className={styles.icon}
        >
          <FontAwesomeIcon icon={faExclamationCircle} size="lg" color={actions.length || companyActions?.length ? '#DF695A' : '#888888'} />
        </div>
      </ActionPopover>
    </Tooltip>
  )
};
