import { call, select, takeEvery, put } from 'redux-saga/effects';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { setFlagList}  from '../../actions/flag/list';
import { FlagSummaryAction } from '../../types/flag/summary';
import { EntityType } from '../../../Utils/Constants';
import CompanySummaryApi from '../../../Network/Apis/company/summary';
import PeopleSummaryApi from '../../../Network/Apis/people/summary';
import CompanyListApi from '../../../Network/Apis/company/list';
import PeopleListApi from '../../../Network/Apis/people/list';

export function* flagSummary({ payload:  { entityId, entityType, companyId, userId, params } }) {
  try {
    let summaryResponse;
    let listResponse;
    switch (entityType) {
      case  EntityType.COMPANY:
        summaryResponse = yield call(
          CompanySummaryApi.getCompanySummary,
          entityId,
          params
        );
        listResponse = yield call(CompanyListApi.getCompaniesList, { companyId: entityId, userId });
        break;
      case  EntityType.PERSON:
        summaryResponse = yield call(
          PeopleSummaryApi.getPersonSummary,
          entityId
        );
        listResponse = yield call(PeopleListApi.getPeopleList, { personId: entityId, userId });
        break;
      case  EntityType.FUNDING_ROUND:
        summaryResponse = yield call(
          CompanySummaryApi.getCompanySummary,
          companyId,
          params
        );
        listResponse = yield call(CompanyListApi.getCompaniesList, { companyId, userId });
        break;
      default:
        break;
    }
    if (summaryResponse && listResponse) {
      const flagList = yield select(state => state.flagList);
      let summary;
      let entity;
      switch (entityType) {
        case  EntityType.COMPANY:
        case  EntityType.FUNDING_ROUND:
          summary = summaryResponse.data.data.companySummary;
          entity = listResponse.data.data.companies[0];
          break;
        case  EntityType.PERSON:
          summary = summaryResponse.data.data.personSummary;
          entity = listResponse.data.data.people[0];
          break;
        default:
          break;
      }
      const message = summaryResponse.data.data.message;
      yield put(
        setFlagList({
          ...flagList,
          message,
          flags: addSummaryToFlag(
            flagList?.flags,
            summary,
            entity
          )
        })
      );
      }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.summaryResponse?.data);
  }
}

function addSummaryToFlag(flags, summary, entity) {
  return flags.map(flag => (
    (flag.entityType === EntityType.FUNDING_ROUND && flag.targetEntityId === summary.id)
    ||
    (flag.entityType !== EntityType.FUNDING_ROUND && flag.entityId === summary.id)
  ) ? { ...flag, summary, entity } : flag);
}

export function* flagSummarySaga() {
  yield takeEvery(
    FlagSummaryAction.GET_FLAG_SUMMARY_REQUEST, flagSummary
  );
}

