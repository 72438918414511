import { AutoFlagAction } from '../../types/auto';

export const getAutoFlags = payload => ({
  type: AutoFlagAction.GET_AUTO_FLAGS,
  payload
});

export const setAutoFlags = payload => ({
  type: AutoFlagAction.SET_AUTO_FLAGS,
  payload
});
