import { CrmListEntryAction } from '../../types/crm/listEntry';

export const addEntityToLists = ({ entityId, crmEntityId, listsToAddTo }) => ({
  type: CrmListEntryAction.ADD_CRM_ENTITY_TO_LISTS,
  payload: { entityId, crmEntityId, listsToAddTo }
});

export const removeEntryFromLists = listsToRemoveFrom => ({
  type: CrmListEntryAction.REMOVE_CRM_ENTITY_FROM_LISTS,
  payload: { listsToRemoveFrom }
});
