export const GlobalSearchAction = {
  GET_COMPANIES_SEARCH_LIST: 'GET_COMPANIES_SEARCH_LIST',
  SET_COMPANIES_SEARCH_LIST: 'SET_COMPANIES_SEARCH_LIST',
  GET_MORE_COMPANIES_SEARCH_LIST: 'GET_MORE_COMPANIES_SEARCH_LIST',
  SET_MORE_COMPANIES_SEARCH_LIST: 'SET_MORE_COMPANIES_SEARCH_LIST',
  GET_SEARCH_COMPANY_SUMMARY: 'GET_SEARCH_COMPANY_SUMMARY',
  GET_SEARCH_COMPANY_FLAG: 'GET_SEARCH_COMPANY_FLAG',
  GET_PEOPLE_SEARCH_LIST: 'GET_PEOPLE_SEARCH_LIST',
  SET_PEOPLE_SEARCH_LIST: 'SET_PEOPLE_SEARCH_LIST',
  GET_MORE_PEOPLE_SEARCH_LIST: 'GET_MORE_PEOPLE_SEARCH_LIST',
  SET_MORE_PEOPLE_SEARCH_LIST: 'SET_MORE_PEOPLE_SEARCH_LIST',
  GET_SEARCH_PERSON_SUMMARY: 'GET_SEARCH_PERSON_SUMMARY',
  GET_SEARCH_PERSON_FLAG: 'GET_SEARCH_PERSON_FLAG'
};
