import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Popover,
  Menu,
  Button
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { deleteAffinityListCompany } from '../../Store/actions/crm/list';
import { getFilterParams } from '../../Utils/FiltersHelper';
import { getPipelineListRequest } from '../../Store/actions/pipeline/list';
import styles from './PipelineActionButton.module.scss';

export default ({
  companyId,
  companyName,
  listId,
  listName,
  filters
}) => {
  const [visible, setVisible] = useState();
  const [deleting, setDeleting] = useState();
  const lsvpUser = useSelector(state => state.login.lsvpUser);
  const dispatch = useDispatch();

  const onClickMenu = ({ key }) => {
    switch (key) {
      case 'delete':
        setDeleting(true);
        break;
      default:
        break;
    }
  };

  const onDeleteCompany = () => {
    setVisible(false);
    const filterParams = getFilterParams(filters);
    const postAction = getPipelineListRequest({
      ...filterParams,
      limit: 50,
      offset: 0,
      userId: lsvpUser.id
    });
    dispatch(deleteAffinityListCompany({
      listId,
      companyId,
      postAction
    }));
  };

  const onVisibleChange = visible => {
    if (visible) { setDeleting(false); }
    setVisible(visible);
  };

  const renderContent = () => {
    if (deleting) {
      return (
        <div className={styles.deleteCompany}>
          <div className={styles.heading}>{`Delete ${companyName} from ${listName}?`}</div>
          <div className={styles.buttons}>
            <Button 
              className={styles.cancel}
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button 
              className={styles.delete}
              type="primary"
              danger
              onClick={onDeleteCompany}
            >
              Delete
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Menu onClick={onClickMenu} mode="vertical">
        <Menu.Item key={'delete'}>Delete from List</Menu.Item>
      </Menu>
    );
  };

  return (
    <Popover
      content={renderContent()}
      trigger="click"
      placement="bottomRight"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <div
        className={styles.icon}
      >
        <FontAwesomeIcon icon={faExclamationCircle} size="lg" color={'#888888'} />
      </div>
    </Popover>
  )
};
