import { TeamMemberAction } from '../../types/team/teamMembers';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TeamMemberAction.SET_TEAM_MEMBERS:
      return action.payload.teamMembers;
    default:
      return state;
  }
};
