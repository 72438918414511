import { call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../../../Network/Apis/people/summary';
import { setPeopleList } from '../../actions/people/list';
import { setPeopleSearchList } from '../../actions/filter/globalSearch';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { PeopleSummaryAction } from '../../types/people/summary';
import { GlobalSearchAction } from '../../types/filter/globalSearch';

const addSummaryToPerson = (people, personSummary) =>
  people.map(person =>
    person.id === personSummary.id
      ? { ...person, summary: personSummary }
      : person
  );

function* setPersonSummary(personSummary, message) {
  const peopleList = yield select(state => state.peopleList);
  yield put(
    setPeopleList({
      ...peopleList,
      message,
      people: addSummaryToPerson(peopleList?.people, personSummary)
    })
  );
}

function* setSearchPersonSummary(personSummary, message) {
  const peopleState = yield select(state => state.globalSearch.peopleState);
  yield put(
    setPeopleSearchList({
      ...peopleState,
      message,
      people: addSummaryToPerson(peopleState?.people, personSummary)
    })
  );
}

function getSummarySetterFunction(actionType) {
  let summarySetterFunction;
  switch (actionType) {
    case PeopleSummaryAction.GET_PERSON_SUMMARY:
      summarySetterFunction = setPersonSummary;
      break;
    case GlobalSearchAction.GET_SEARCH_PERSON_SUMMARY:
      summarySetterFunction = setSearchPersonSummary;
      break;
    default:
      dispatchSnackbarError(`Unexpected action type: ${actionType}`);
      break;
  }
  return summarySetterFunction;
}

export function* personSummary({ type, payload }) {
  const summarySetterFunction = getSummarySetterFunction(type);

  try {
    const response = yield call(API.getPersonSummary, payload.personId);
    const { personSummary, message } = response.data.data;
    yield summarySetterFunction(personSummary, message);
  } catch (err) {
    yield summarySetterFunction(
      { id: payload.personId, hasNoSummary: true },
      err?.response?.data
    );
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* personSummarySaga() {
  yield takeEvery(
    [
      PeopleSummaryAction.GET_PERSON_SUMMARY,
      GlobalSearchAction.GET_SEARCH_PERSON_SUMMARY
    ],
    personSummary
  );
}
