import { CryptoListAction } from '../../types/crypto/list';

export const getCryptoList = payload => ({
  type: CryptoListAction.GET_CRYPTO_LIST,
  payload
});

export const setCryptoList = ({ cryptos }) => ({
  type: CryptoListAction.SET_CRYPTO_LIST,
  payload: { cryptos }
});

export const getMoreCryptoList = payload => ({
  type: CryptoListAction.GET_MORE_CRYPTO_LIST,
  payload
});

export const setMoreCryptoList = ({ cryptos, message }) => ({
  type: CryptoListAction.SET_MORE_CRYPTO_LIST,
  payload: { cryptos, message }
});

export const updateCryptoList = cryptos => ({
  type: CryptoListAction.UPDATE_CRYPTO_LIST,
  payload: { cryptos }
});

export const getCryptoCount = payload => ({
  type: CryptoListAction.GET_CRYPTO_COUNT,
  payload
});

export const setCryptoCount = payload => ({
  type: CryptoListAction.SET_CRYPTO_COUNT,
  payload
});

export const downloadCryptoCSVAction = (downloadResolver, params = {}) => ({
  type: CryptoListAction.DOWNLOAD_CRYPTO_CSV,
  payload: {
    downloadResolver,
    params
  }
});
