import { axiosInstance } from '../index';

const getPeopleList = async params => await axiosInstance.post('people/list', params, { handlerEnabled: true });

const downloadPeopleList = async params => await axiosInstance.post(`people/csv`, params, { handlerEnabled: true });


export default {
  getPeopleList, 
  downloadPeopleList
};
