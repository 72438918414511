import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../Network/Apis/crypto/list';
import * as Actions from '../../actions/crypto/list';
import updateLoader from '../../actions/Loader';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import { CryptoListAction } from '../../types/crypto/list';

export function* cryptoList({ payload }) {
  try {
    yield put(updateLoader({ listLoader: true }));
    const response = yield call(API.getCryptoList, payload);
    yield put(Actions.setCryptoList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ listLoader: false }));
  }
}

export function* moreCryptoList({ payload }) {
  try {
    const response = yield call(API.getCryptoList, payload);
    yield put(Actions.setMoreCryptoList(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* cryptoCount({ payload }) {
  try {
    const response = yield call(API.getCryptoCount, payload);
    yield put(Actions.setCryptoCount(response.data.data));
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* downloadCryptoList({ payload }) {
  try {
    yield put(updateLoader({ csvLoader: true }));
    const { downloadResolver, params } = payload;
    const response = yield call(API.downloadCryptoList, params);
    downloadResolver(response?.data?.data);
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  } finally {
    yield put(updateLoader({ csvLoader: false }));
  }
}

export function* cryptoListSaga() {
  yield takeLatest(CryptoListAction.GET_CRYPTO_LIST, cryptoList);
  yield takeLatest(CryptoListAction.GET_MORE_CRYPTO_LIST, moreCryptoList);
  yield takeLatest(CryptoListAction.GET_CRYPTO_COUNT, cryptoCount);
  yield takeLatest(CryptoListAction.DOWNLOAD_CRYPTO_CSV, downloadCryptoList);
}
