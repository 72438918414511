import axios from 'axios';
import { requestHandler, successHandler, errorHandler } from '../Interceptor';
import qs from 'qs';
import { LocalStorageKeys } from '../../Utils/LocalStorage';

const apiEndpoints = {
  local: 'http://localhost:3000/',
  develop: 'https://9xdqz9yg54.execute-api.us-west-2.amazonaws.com/dev/',
  staging: 'https://ijd6k858o1.execute-api.us-west-2.amazonaws.com/staging/',
  production: 'https://wdvhse47j3.execute-api.us-west-2.amazonaws.com/prod/'
};

export const axiosInstance = axios.create({
  baseURL: apiEndpoints[process.env.REACT_APP_STAGE],
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: localStorage.getItem(LocalStorageKeys.TOKEN)
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
});

// Handle request process
axiosInstance.interceptors.request.use(request => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
