import React, { useState } from 'react';
import {
  Avatar,
  Popover
} from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, } from '@fortawesome/free-regular-svg-icons';
import NameColumnTags from '../../Shared/NameColumnTags';
import Notes from '../../Shared/Notes/Notes';
import { EntityTypeCode } from '../../../Utils/Constants';
import { stopPropagation } from '../../../Utils/Shared';
import styles from './CompanyNameColumn.module.scss';

export default ({
  id,
  name,
  crmEntityId,
  logoUrl,
  team,
  target = '_blank',
  ...tagParams
}) => {
  const [notesVisible, setNotesVisible] = useState();

  const renderNotes = () => {
    return (
      <div
      className={styles.notes}
      onScroll={stopPropagation}
      >
        <Notes
          entityId={id}
          entityType={EntityTypeCode.COMPANY}
          crmEntityId={crmEntityId}
          readonly
        />
      </div>
    );
  };

  return (
    <span className="d-flex align-items-center">
      <div>
        {logoUrl ? (
          <Avatar className={styles.avatar} src={logoUrl} alt="avatar-img" />
        ) : (
          <Avatar className={styles.avatar} alt="avatar-img">
            {' '}
            {name && name[0] ? name[0] : ''}
          </Avatar>
        )}
      </div>
      <div className="d-flex flex-column align-items-start">
        <span>
          {id ? (
            <Link to={`/companies/${id}${team ? `?team=${team}` : ''}`} target={target}>
              {name}
            </Link>
          ) : name}
          {
            crmEntityId ? (
              <>
                &nbsp;
                <Popover
                  content={renderNotes()}
                  trigger="click"
                  placement="bottomLeft"
                  visible={notesVisible}
                  arrowPointAtCenter
                  onVisibleChange={setNotesVisible}
                >
                  <FontAwesomeIcon
                    className={styles.notesIcon}
                    size="sm"
                    icon={faStickyNote}
                    title="Notes"
                  />
                </Popover>
              </>
            ) : null
          }
        </span>
        <NameColumnTags {...tagParams} />
      </div>
    </span>
  );
};
