export const FlagListAction = {
  GET_FLAG_LIST: 'GET_FLAG_LIST',
  SET_FLAG_LIST: 'SET_FLAG_LIST',
  GET_MORE_FLAG_LIST: 'GET_MORE_FLAG_LIST',
  SET_MORE_FLAG_LIST: 'SET_MORE_FLAG_LIST',
  UPDATE_FLAG_LIST: 'UPDATE_FLAG_LIST',
  GET_FLAG_COUNT: 'GET_FLAG_COUNT',
  SET_FLAG_COUNT: 'SET_FLAG_COUNT',
  DOWNLOAD_FLAG_CSV: 'DOWNLOAD_FLAG_CSV',
  LOCAL_ADD_ACTION: 'LOCAL_ADD_ACTION',
  LOCAL_REMOVE_ACTION: 'LOCAL_REMOVE_ACTION'
};
