import { LocalStorageKeys } from '../../Utils/LocalStorage';
import { PATH_NAME } from '../../Routes/PathNames';

export const isHandlerEnabled = (config = {}) => {
  return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled);
};

export const requestHandler = request => {
  request.headers.Authorization = localStorage.getItem(LocalStorageKeys.TOKEN);
  if (isHandlerEnabled(request)) {
    // Modify request here
  }
  return request;
};

export const successHandler = response => {
  if (isHandlerEnabled(response)) {
    // Handle responses
  }
  return response;
};

export const errorHandler = error => {
  if (isHandlerEnabled(error.config)) {
    switch (error.response?.status) {
      case 401:
        window.location.reload();
        break;
      case 418:
        window.location.href = PATH_NAME.ERROR;
        break;
      default:
        break;
    }
  }
  return Promise.reject({ ...error });
};
