import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '../../Utils/Shared';
import {
  SpecialTeamId,
  EntityType
} from '../../Utils/Constants';
import {
  getActionTypeDescription,
  getActionDetailsDescription,
  isBaseAction
} from '../../Utils/Action'
import styles from './Actions.module.scss';

export default ({
  actions,
  entityType,
  companyActions
}) => {
  const renderAction = ({ actionType, teamId, userName, comment, details, createdAt, triggeredAt, deactivatedAt }) => {
    const rows = [];
    rows.push(
      <div key="action">{`${getActionTypeDescription(actionType)}`}</div>
    );
    rows.push(
      <div key="userName">{`by ${userName}`}</div>
    );
    rows.push(
      <div key="teamId">{`for ${teamId === SpecialTeamId.ME ? userName : teamId}`}</div>
    );
    rows.push(
      <div key="date">{`on ${moment(createdAt).format(DEFAULT_DATE_FORMAT)}`}</div>
    );
    if (details) {
      rows.push(
        <div key="details">{getActionDetailsDescription(details)}</div>
      );
    }
    if (triggeredAt) {
      rows.push(
        <div key="woken">{`woken on ${moment(triggeredAt).format(DEFAULT_DATE_FORMAT)}`}</div>
      );
    }
    if (deactivatedAt) {
      rows.push(
        <div key="deactivated">{`deactivated on ${moment(deactivatedAt).format(DEFAULT_DATE_FORMAT)}`}</div>
      );
    }
    if (comment) {
      rows.push(
        <div key="comment">{`"${comment}"`}</div>
      );
    }
    return (
      <div key={`${actionType}|${teamId}|${userName}`} className={styles.summary}>{rows}</div>
    );
  };

  const renderActions = () => {
    let list;
    if (entityType === EntityType.FUNDING_ROUND) {
      list = _.sortBy([
        ...actions.filter(action => !isBaseAction(action.actionType)), 
        ...companyActions.filter(action => isBaseAction(action.actionType))
      ], 'createdAt');
    } else {
      list = actions;
    }
    list = list.filter(action => !action.details?.test);
    if (!list.length) { return 'No actions' }
    return list.map(renderAction);
  };

  return renderActions();
};
