import { axiosInstance } from '../index';

const getFundingRoundsList = async params => await axiosInstance.post(`funding/list`, params, { handlerEnabled: true });

const getFundingRoundsCharts = async params => await axiosInstance.post(`funding/charts`, params, { handlerEnabled: true });

const downloadFundingRoundsList = async params => await axiosInstance.post(`funding/csv`, params, { handlerEnabled: true });

export default {
  getFundingRoundsList,
  getFundingRoundsCharts,
  downloadFundingRoundsList
};
