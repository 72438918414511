import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Drawer, Space, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { menuItems } from './../../Utils/Constants';
import AtlasLogo from '../../Assets/images/Atlas.svg';
import styles from './SideDrawer.module.scss';

export default function SideDrawer() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => setVisible(!visible);
  const onClose = () => setVisible(!visible);
  return (
    <>
      <Space className={styles.sideDrawer}>
        <FontAwesomeIcon
          icon={faBars}
          className={styles.drawerIcon}
          onClick={showDrawer}
        />
      </Space>
      <Drawer
        title={
          <NavLink className={styles.linkLogo} exact to="/">
            <img src={AtlasLogo} alt="Atlas Logo" />
          </NavLink>
        }
        placement={'left'}
        closable={false}
        onClose={onClose}
        visible={visible}
        maskClosable={true}
        zIndex="10000"
      >
        <Menu
          mode="vertical"
          defaultSelectedKeys={['2']}
          className={`${styles.navlink} border-0`}
        >
          {menuItems.map((item, index) => (
            <Menu.Item key={index} onClick={showDrawer}>
              <NavLink className={styles.link} exact to={item.route}>
                {item?.label}
              </NavLink>
            </Menu.Item>
          ))}
        </Menu>
      </Drawer>
    </>
  );
}
