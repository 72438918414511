import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getNormalizedFilters } from './FiltersHelper'
import {
  filterKeys,
  SpecialTeamId,
  TeamViewEntityType,
  UserRole,
  TEAM_VIEW_NONE
} from './Constants';
import showNotification from '../Components/Shared/Notification';
import {
  saveTeamView,
  addTeamView,
  deleteTeamView
} from '../Store/actions/team/teamViews';
import { useDeepCompareEffect } from './UseDeepCompareEffect';

const getTeamViewFilters = ({ teamViews, team, teamView }) => teamViews[team][teamView].filter.reduce(
  (filters, { key, label, value}) => {
    if (!filters[key]) {
      filters[key] = [];
    }
    filters[key].push({ label, value });
    return filters;
  },
  {
    team: [{ label: team, value: team }],
    teamView: [{ label: teamView, value: teamView }]
  }
);

const filtersMatchTeamView = ({
  filters,
  teamViews,
  team,
  teamView,
  excludedFilterKeys,
  extraFilterKey,
  extraFilterDefault
}) => {
  const normalizedFilters = getNormalizedFilters(filters);
  const teamViewFilters = getNormalizedFilters(getTeamViewFilters({ teamViews, team, teamView }));
  if (!_.isEqual(
    _.omit(normalizedFilters, excludedFilterKeys),
    _.omit(teamViewFilters, excludedFilterKeys)
  )) { return false; }
  const extraFilter = teamViewFilters[extraFilterKey] ?? extraFilterDefault;
  if (extraFilter && !_.isEqual(normalizedFilters[extraFilterKey], extraFilter)) { return false; }
  return true;
};

export const useTeamView = ({
  filtersFromUrl,
  initialFilters,
  excludedFilterKeys,
  extraFilterKey,
  extraFilterDefault,
  teamViews,
  viewType
}) => {
  const dispatch = useDispatch();
  const lsvpUser = useSelector(state => state.login.lsvpUser);
  const [filters, setFilters] = useState(!_.isEmpty(filtersFromUrl) ? filtersFromUrl : initialFilters);
  const [currentTeam, setCurrentTeam] = useState(SpecialTeamId.ME);
  const [currentTeamView, setCurrentTeamView] = useState(TEAM_VIEW_NONE);
  const [editingTeamView, setEditingTeamView] = useState(false);
  const [addingTeamView, setAddingTeamView] = useState();

  const initFilters = (filters = {}) => {
    setFilters({
      ...initialFilters,
      ...filters
    });
  };

  const shouldCancelTeamView = () => currentTeamView !== TEAM_VIEW_NONE && !editingTeamView;

  const cancelTeamView = () => {
    setCurrentTeamView(TEAM_VIEW_NONE);
    setEditingTeamView(false);
  };

  const addTeamToFilters = team => setFilters({ ..._.omit(filters, filterKeys.teamView), team: [{ label: team, value: team }] });

  const removeTeamFromFilters = () => setFilters(_.omit(filters, [filterKeys.team, filterKeys.teamView]));

  const removeTeamViewFromFilters = () => setFilters(_.omit(filters, filterKeys.teamView));

  useDeepCompareEffect(() => {
    setFilters(!_.isEmpty(filtersFromUrl) ? filtersFromUrl : initialFilters);
  }, [filtersFromUrl]);

  useDeepCompareEffect(() => {
    if (
      shouldCancelTeamView()
      &&
      !filtersMatchTeamView({ 
        filters,
        teamViews,
        team: currentTeam,
        teamView: currentTeamView,
        excludedFilterKeys,
        extraFilterKey,
        extraFilterDefault
      })
    ) {
      cancelTeamView();
      removeTeamViewFromFilters();
      showNotification('info', 'Team view cancelled');
    }
  }, [filters, teamViews, currentTeamView]);

  useDeepCompareEffect(() => {
    if (teamViews) {
      const team = filters[filterKeys.team]?.[0]?.value;
      const teamView = filters[filterKeys.teamView]?.[0]?.value;
      if (team && team !== currentTeam) {
        setCurrentTeam(team);
      }
      if (
        teamView && teamView !== currentTeamView && !editingTeamView
        &&
        !filtersMatchTeamView({
          filters,
          teamViews,
          team,
          teamView,
          excludedFilterKeys,
          extraFilterKey,
          extraFilterDefault
        })
      ) {
        initFilters(getTeamViewFilters({ teamViews, team, teamView }));
        setCurrentTeamView(teamView);
      }
    }
  }, [filters, teamViews, editingTeamView, dispatch]);

  useDeepCompareEffect(() => {
    if (addingTeamView) {
      const teamView = currentTeam === SpecialTeamId.ME ? `${lsvpUser.id}: ${addingTeamView}` : addingTeamView;
      if (teamViews[currentTeam][teamView]) {
        initFilters(getTeamViewFilters({ teamViews, team: currentTeam, teamView }));
        setCurrentTeamView(teamView);
        setAddingTeamView();
      }
    }
  }, [teamViews]);

  const clearFilters = () => {
    if (shouldCancelTeamView()) {
      initFilters();
      cancelTeamView();
      setCurrentTeam(SpecialTeamId.ME);
    } else if (currentTeamView !== TEAM_VIEW_NONE) {
      initFilters({
        team: [{ label: currentTeam, value: currentTeam }],
        teamView: [{ label: currentTeamView, value: currentTeamView }]
      });
    } else {
      initFilters();
      setCurrentTeam(SpecialTeamId.ME);
    }
  };

  const getTeamViewEntities = () => {
    const entities = [];
    const normalized = getNormalizedFilters(filters);
    for (const key in normalized) {
      if (key !== extraFilterKey && excludedFilterKeys.includes(key)) {
        continue;
      }
      for (const {value, label} of normalized[key]) {
        entities.push({ key, label, value });
      }
    }
    return {
      entities,
      normalized
    };
  }

  const getCurrentTeamView = () => {
    if (!filters[filterKeys.teamView]?.[0]?.value) {
      return null;
    }
    const { entities, normalized } = getTeamViewEntities();
    return {
      viewType,
      teamId: normalized[filterKeys.team][0].value,
      viewId: normalized[filterKeys.teamView][0].value,
      entityType: TeamViewEntityType.FILTER,
      entities
    };
  };

  const getNewTeamView = viewId => {
    const { entities, normalized } = getTeamViewEntities();
    return {
      viewType,
      teamId: normalized[filterKeys.team]?.[0].value ?? SpecialTeamId.ME,
      viewId,
      entityType: TeamViewEntityType.FILTER,
      entities
    };
  };

  const onChangeTeam = team => {
    if (team !== SpecialTeamId.ME) {
      addTeamToFilters(team);
    } else {
      removeTeamFromFilters();
    }
    setCurrentTeam(team);
    cancelTeamView();
  };

  const onChangeTeamView = teamView => {
    if (teamView !== TEAM_VIEW_NONE) {
      initFilters(getTeamViewFilters({ teamViews, team: currentTeam, teamView }));
      setCurrentTeamView(teamView);
    } else {
      cancelTeamView();
      removeTeamViewFromFilters();
    }
  };

  const onEditTeamView = () =>  {
    setEditingTeamView(true);
  };

  const onSaveTeamView = () =>  {
    if (editingTeamView) {
      const teamView = getCurrentTeamView();
      if (teamView) {
        dispatch(saveTeamView({
          userId: lsvpUser.id,
          ...teamView
        }));
      }
    }
    setEditingTeamView(false);
  };

  const onAddTeamView = viewId =>  {
    const teamView = getNewTeamView(viewId);
    if (!teamView.entities.length) {
      showNotification('error', 'No filters selected');
      return;
    }
    dispatch(addTeamView({
      userId: lsvpUser.id,
      ...teamView
    }));
    setAddingTeamView(viewId);
  };

  const onDeleteTeamView = () => {
    const teamView = getCurrentTeamView();
    if (teamView) {
      const { entities, ...params } = teamView;
      dispatch(deleteTeamView({
        userId: lsvpUser.id,
        ...params
      }));
      cancelTeamView();
      removeTeamViewFromFilters();
    }
  };

  return {
    filters,
    setFilters,
    currentTeam,
    currentTeamView,
    editingTeamView,
    clearFilters,
    onChangeTeam,
    onChangeTeamView,
    onEditTeamView,
    onSaveTeamView,
    onAddTeamView,
    onDeleteTeamView
  };
};

export const getAllTeams = teamViews => {
  const companyTeams = Object.keys(teamViews?.companies ?? {});
  const peopleTeams = Object.keys(teamViews?.fundingRounds ?? {});
  const fundingRoundTeams = Object.keys(teamViews?.fundingRounds ?? {});
  const teams = _.uniq([
    ...companyTeams,
    ...peopleTeams,
    ...fundingRoundTeams
  ]).filter(team => ![SpecialTeamId.ME, SpecialTeamId.ME].includes(team));
  return teams;
};

export const getTeamOptions = teamViews => teamViews ? [
  { label: SpecialTeamId.ME, value: SpecialTeamId.ME },
  ...Object.keys(teamViews).filter(key => key !== SpecialTeamId.ME).sort().map(key => ({ label: key, value: key }))
] : [];

export const getTeamViewLabel = (currentTeam, lsvpUser, viewId) => {
  if (
    currentTeam !== SpecialTeamId.ME
    ||
    viewId === TEAM_VIEW_NONE
    ||
    !lsvpUser
    ||
    lsvpUser.role === UserRole.ADMIN
  ) { return viewId; }
  return viewId.slice(lsvpUser.id.length + 2);
}

export const getTeamViewOptions = (teamViews, currentTeam, lsvpUser) => {
  if (!teamViews?.[currentTeam] || !lsvpUser) { return []; }
  let viewIds = Object.keys(teamViews[currentTeam]);
  if (currentTeam === SpecialTeamId.ME && lsvpUser.role !== UserRole.ADMIN) {
    viewIds = viewIds.filter(viewId => viewId.startsWith(`${lsvpUser.id}: `));
  }
  viewIds.sort();
  return teamViews?.[currentTeam] ? [
    { label: TEAM_VIEW_NONE, value: TEAM_VIEW_NONE },
    ...viewIds.map(viewId => ({
      label: getTeamViewLabel(currentTeam, lsvpUser, viewId),
      value: viewId
    }))
  ] : [];
};
