import { Action } from '../../types/action';

export const addAction = payload => ({
  type: Action.ADD_ACTION,
  payload
});

export const removeAction = payload => ({
  type: Action.REMOVE_ACTION,
  payload
});

export const changeAction = payload => ({
  type: Action.CHANGE_ACTION,
  payload
});

export const localAddAction = payload => ({
  type: Action.LOCAL_ADD_ACTION,
  payload
});

export const localRemoveAction = payload => ({
  type: Action.LOCAL_REMOVE_ACTION,
  payload
});

export const localChangeAction = payload => ({
  type: Action.LOCAL_CHANGE_ACTION,
  payload
});
