import React, { useState } from 'react';
import {
  Popover,
  Button,
  Input
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styles from './EditableText.module.scss';

export default ({
  title,
  text = '',
  onChange
}) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(text);

  const reset = () => setValue(text);

  const onSave = () => onChange(value);

  const renderContent = () => {
    return (
      <div>
        <div className={styles.title}>{title}</div>
        <Input.TextArea
          className={styles.text}
          rows="4"
          cols="40"
          value={value}
          onChange={event => setValue(event.target.value)}
        />
        <div className={styles.buttons}>
          <Button 
            className={styles.cancel}
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          <Button 
            type="primary"
            onClick={() => {
              setVisible(false);
              onSave();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.editableText}>
      <span>{text}</span>
      <Popover
        placement="bottomRight"
        trigger="click"
        content={renderContent()}
        arrowPointAtCenter
        visible={visible}
        onVisibleChange={visible => {
          if (visible) {
            reset();
          }
          setVisible(visible);
        }}
      >
        <span className={styles.editButton} >
          <EditOutlined />
        </span>
      </Popover>
    </div>
  );
};
