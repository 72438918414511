import { axiosInstance } from '../index';
const getCompanyProfile = async ({ companyId, userId }) => {
  return await axiosInstance.get(`companies/${companyId}`, {
    params: { userId },
    handlerEnabled: true
  });
};

export default {
  getCompanyProfile
};
