import React from 'react';
import * as Sentry from '@sentry/react';

export const SENTRY_DSN = {
  local:
    'https://5ffdd32c262547afbf098894801847d4@o444908.ingest.sentry.io/5420483',
  develop:
    'https://f6df0cec53f2470e8bac821bb55a82cc@o235976.ingest.sentry.io/5421865',
  staging: '',
  production: ''
};

export function initService() {
  Sentry.init({
    dsn: SENTRY_DSN[process.env.REACT_APP_STAGE]
  });
}

export const reduxEnhancer = Sentry.createReduxEnhancer({});

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

export const ErrorBoundary = ({ Component }) => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <Component />
  </Sentry.ErrorBoundary>
);

export function logError(error) {
  Sentry.captureException(error);
}
