import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  Table,
  Spin,
  Checkbox
} from 'antd';
import LastInteractionDate from '../../Shared/LastInteractionDate';
import CompanyNameColumn from './CompanyNameColumn';
import GrowthColumn from './GrowthColumn';
import { LoadingOutlined } from '@ant-design/icons';
import { AffinityLists } from '../../AffinityLists/AffinityLists';
import { formatMoney } from '../../../Utils/MoneyFormatter';
import { formatNumber } from '../../../Utils/NumberFormatter';
import {
  filterKeys,
  sectorsIc,
  projectedRaiseDates,
  growthMetricShortNames,
  EntityType,
  Activity,
  PeriodShortName
} from '../../../Utils/Constants';
import ActionButton from '../../Action/ActionButton';
import PipelineActionButton from '../../Action/PipelineActionButton';
import { changeCompanySector } from '../../../Store/actions/company/details';
import {
  renderNumberCommaSeparated,
  renderOwners,
  renderMainRegion,
  AffinityStatus,
  hasValue,
  formatDate
} from '../../../Utils/Shared';
import { getFilterParams } from '../../../Utils/FiltersHelper';
import AddToAffinityListModal from '../../AddToAffinityListModal/AddToAffinityListModal';
import CompanyExpandedRow from '../ExpandedRow/CompanyExpandedRow';
import AddOwnersModal from '../../AddOwnersModal/AddOwnersModal';
import TableDropdown from '../../TableDropdown/TableDropdown';
import { useDeepCompareEffect } from '../../../Utils/UseDeepCompareEffect';
import { logActivity } from '../../../Store/actions/log/actions';
import {
  getActionStatus,
  getWokenSnooze,
  ActionStatus
} from '../../../Utils/Action';
import { removeAction } from '../../../Store/actions/action/actions';
import ColumnTitle from '../../Shared/ColumnTitle';
import EditableText from '../../Shared/EditableText';
import {
  updateCompanySectorIcAction,
  updateCompanyProjectedRaiseDateAction,
  updateCompanyFinancialsAction,
  updateCompanyNextStepAction,
  updateCompanyUrgentAction
} from '../../../Store/actions/company/details';
import tableStyles from '../../Shared/Table.module.scss';
import styles from './CompanyTable.module.scss';

const getGrowthColumn = filters => {
  if (filters[filterKeys.lastAssignedDate]?.length) { return 'lastAssignedDate'; }
  if (filters[filterKeys.period]?.length) { return 'period'; }
  return 'growth';
};

const getDefaultColumnSet = filters => ([
  'name',
  'totalFunding',
  'employeeCount',
  'sector',
  getGrowthColumn(filters),
  'lastInteraction',
  'owners',
  'status',
  'crmLists',
  'actions'
]);

const render = (company, content) => company.section ? null : content;

export default ({
  filters,
  loadingMore,
  companies,
  scrollbarStyle,
  count, 
  titleFilter,
  filterExceptions,
  columnSet = getDefaultColumnSet(filters),
  addToFilters,
  getCompanySummary,
  fetchMoreCompanies,
  prepareData = _.identity,
  getCompanyFlag,
  noRowHighlight = false
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const lsvpUser = useSelector(({ login }) => login.lsvpUser);
  const loading = useSelector(({loader}) => loader.listLoader || loader.globalSearchCompaniesLoader)
  const teamIds = useSelector(state => Object.keys(state.teamViews?.companies ?? {}));
  const crmLists = useSelector(({ crmLists }) => crmLists.lists);
  const sectors = useSelector(({ sectors }) => sectors);
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    setExpandedRowKeys([]);
  }, [filters]);

  const columnSpec = {
    name: {
      title: (
        <ColumnTitle
          title="Company"
          filters={filters}
          filterKey={filterKeys.companyName}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'name',
      key: 'name',
      className: styles.name,
      width: 155,
      render: (name, company) =>  {
        if (company.section) {
          return (
            <span className={styles.sectionHeader}>{company.section}</span>
          )
        }
        const actionStatus = getActionStatus(company.actions, lsvpUser.id, filters.team?.[0]?.value) ;
        return render(company, (
          <CompanyNameColumn
            id={company.id}
            name={name}
            crmEntityId={company.crmEntityId}
            logoUrl={company.logoUrl}
            blocked={actionStatus.includes(ActionStatus.BLOCKED)}
            snoozed={actionStatus.includes(ActionStatus.SNOOZED)}
            woken={actionStatus.includes(ActionStatus.WOKEN)}
            ipo={company.ipo}
            acquired={company.acquired}
            pe={company.pe}
            team={filters.team?.[0]?.value}
          />
        ));
      }
    },
    totalFunding: {
      title: (
        <ColumnTitle
          title="Funding"
          filters={filters}
          filterKey={filterKeys.funding}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'totalFunding',
      key: 'totalFunding',
      className: styles.funding,
      width: 90,
      align: 'right',
      render: (totalFunding, company) => render(company, (
        totalFunding ? formatMoney({
          amount: totalFunding,
          currency: company.totalFundingCurrency,
          precision: 0
        }) : (
          <strong>N/A</strong>
        )
      ))
    },
    employeeCount: {
      title: (
        <ColumnTitle
          title={<span>Employee<br />Count</span>}
          filters={filters}
          filterKey={filterKeys.employeeCount}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      className: styles.employeeCount,
      width: 90,
      align: 'right',
      render: (employeeCount, company) => render(company, renderNumberCommaSeparated(employeeCount))
    },
    sector: {
      title: (
        <ColumnTitle
          title="Sector"
          filters={filters}
          filterKey={filterKeys.sector}
          staticOptions={(sectors).map(sector => ({value: sector.id, label: sector.name}))}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      className: `${styles.sector} ${tableStyles.dropdown}`,
      width: 140,
      render: company => render(company, (
        <TableDropdown
          values={sectors}
          selection={company.sector}
          tooltip="Change Sector"
          onSelect={sectorId => dispatch(changeCompanySector(sectorId, company.id))}
        />
      ))
    },
    growth: {
      title: (
        <ColumnTitle
          title={
            _.isEmpty(filters?.growth) ? (
              'Order by Atlas Growth'
             ) : (
              <span>Order by Atlas Growth:<br />{`${growthMetricShortNames[filters?.growth[0]?.value] ?? ''}`}</span>
             )
          }
          filters={filters}
          filterKey={filterKeys.growth}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'growth',
      key: 'growth',
      className: styles.growth,
      width: 155,
      align: 'right',
      render: (growth, company) => render(company, (
        <GrowthColumn company={company} growth={filters?.growth} />
      ))
    },
    period: {
      title: (
        <ColumnTitle
          title={
            _.isEmpty(filters?.period) ? (
              'Order by Period Growth'
             ) : (
              <span>Order by Period Growth:<br />{`${PeriodShortName[filters?.period[0]?.value]}`}</span>
             )
          }
          filters={filters}
          filterKey={filterKeys.period}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'period',
      key: 'period',
      className: styles.period,
      width: 155,
      align: 'right',
      render: (period, company) => render(company, (
        hasValue(period) ? `${formatNumber({ amount: period, precision: 2 })}%` : <strong>N/A</strong>
      ))
    },
    lastAssignedDate: {
      title: (
        <ColumnTitle
          title="Last Assigned Date"
          filters={filters}
          filterKey={filterKeys.lastAssignedDate}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'lastAssignedDate',
      key: 'lastAssignedDate',
      className: styles.lastAssignedDate,
      width: 155,
      render: (lastAssignedDate, company) => render(company, (
        lastAssignedDate ? ( 
          moment(lastAssignedDate).format('M/D/YYYY')
        ) : (
          <strong>N/A</strong>
        )
      ))
    },
    lastInteraction: {
      title: (
        <ColumnTitle
          title="Last Interaction"
          filters={filters}
          filterKey={filterKeys.lastInteraction}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'lastInteraction',
      key: 'lastInteraction',
      className: styles.lastInteraction,
      width: 130,
      render: (lastInteraction, company) => render(company, (
        <LastInteractionDate date={lastInteraction} />
      ))
    },
    owners: {
      title: (
        <ColumnTitle
          title="Owner"
          filters={filters}
          filterKey={filterKeys.owner}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'owners',
      key: 'owners',
      className: styles.owners,
      width: 140,
      render: (owners, company) => render(company, (
        <span>
          {owners?.length ? <span className={styles.owner}>{renderOwners(owners)}</span> : null}
          <AddOwnersModal
            currentOwners={owners}
            entityId={company.id}
            entityType={EntityType.COMPANY}
          />
        </span>
      ))
    },
    status: {
      title: (
        <ColumnTitle
          title="Status"
          filters={filters}
          filterKey={filterKeys.status}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'status',
      key: 'status',
      className: styles.status,
      width: 165,
      render: (status, company) => render(company, (
        <AffinityStatus
          status={status}
          id={company.id}
          crmEntityId={company.crmEntityId}
          entityType={EntityType.COMPANY}
        />
      ))
    },
    crmLists: {
      title: (
        <ColumnTitle
          title="List"
          filters={filters}
          filterKey={filterKeys.crmList}
          staticOptions={crmLists}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      dataIndex: 'crmLists',
      key: 'crmLists',
      className: styles.affinityLists,
      width: 160,
      render: (crmLists, company) => render(company, (
        <span>
          {
            crmLists.length ? (
              <span className={styles.affinityList}>
                <AffinityLists crmLists={crmLists} crmEntityId={company.crmEntityId} />
              </span>
            ) : null
          }
          <AddToAffinityListModal
            entityId={company.id}
            crmEntityId={company.crmEntityId}
            currentLists={company.crmLists}
          />
        </span>
      ))
    },
    actions: {
      width: 40,
      className: styles.action,
      render: company => render(company, (
        <ActionButton
          actions={company.actions}
          entityId={company.id}
          entityType={EntityType.COMPANY}
          teamIds={teamIds}
          owners={company.owners}
          status={company.status}
          showSnoozed={filters.showSnoozed?.[0]?.value.toString() === 'true'}
          showBlocked={filters.showBlocked?.[0]?.value.toString() === 'true'}
          team={filters.team?.[0]?.value}
          flag={company.flag?.comment}
          growth={filters.growth?.[0]?.value}
          availableGraphs={company.flag?.graphs}
          getCompanyFlag={getCompanyFlag}
        />
      ))
    },
    sectorIc: {
      title: (
        <ColumnTitle
          title="Sector IC"
          filters={filters}
          filterKey={filterKeys.sectorIc}
          titleFilter={titleFilter}
          filterExceptions={filterExceptions}
          addToFilters={addToFilters}
        />
      ),
      className: `${styles.sector} ${tableStyles.dropdown}`,
      width: 120,
      render: company => render(company, (
        <TableDropdown
          values={sectorsIc}
          selection={company.sectorIc || 'None'}
          tooltip="Change Sector IC"
          onSelect={secotorIc => dispatch(updateCompanySectorIcAction(company.id, secotorIc))}
        />
      ))
    },
    regions: {
      title: 'Region',
      dataIndex: 'regions',
      key: 'regions',
      className: styles.regions,
      width: 120,
      render: (regions, company) => render(company, (
        <span>{regions ? renderMainRegion(regions) : null}</span>
      ))
    },
    projectedRaiseDate: {
      title: 'Projected Raise Date',
      key: 'projectedRaiseDate',
      className: styles.projectedRaiseDate,
      width: 150,
      render: company => render(company, (
        <TableDropdown
          values={projectedRaiseDates}
          selection={company.projectedRaiseDate}
          tooltip="Change Projected Raise Date"
          noneValue={{ id: '', name: 'None' }}
          onSelect={projectedRaiseDate => dispatch(updateCompanyProjectedRaiseDateAction(company.id, projectedRaiseDate))}
        />
      ))
    },
    financials: {
      title: 'Financials',
      dataIndex: 'financials',
      key: 'financials',
      className: styles.financials,
      width: 150,
      render: (financials, company) => render(company, (
        <EditableText
          title="Financials"
          text={financials}
          onChange={financials => dispatch(updateCompanyFinancialsAction(company.id, financials))}
        />
      ))
    },
    nextStep: {
      title: 'Next Steps and Deal',
      dataIndex: 'nextStep',
      key: 'nextStep',
      className: styles.nextStep,
      width: 150,
      render: (nextStep, company) => render(company, (
        <EditableText
          title="Next Steps and Deal"
          text={nextStep}
          onChange={nextStep => dispatch(updateCompanyNextStepAction(company.id, nextStep))}
        />
      ))
    },
    pipelineActions: {
      width: 40,
      className: styles.action,
      render: company => render(company, (
        <PipelineActionButton
          companyId={company.crmEntityId}
          companyName={company.name}
          listId={filters[filterKeys.crmList]?.[0]?.value}
          listName={filters[filterKeys.crmList]?.[0]?.label}
          filters={filters}
        />
      ))
    },
    dateAdded: {
      title: 'Date Added',
      dataIndex: 'dateAdded',
      key: 'dateAdded',
      className: styles.dateAdded,
      width: 120,
      render: (dateAdded, company) => render(company, (
        formatDate(dateAdded)
      ))
    },
    urgent: {
      title: 'Urgent',
      dataIndex: 'urgent',
      key: 'urgent',
      className: styles.urgent,
      width: 80,
      render: (urgent, company) => render(company, (
        <Checkbox
          className={styles.urgent}
          checked={urgent === 'Urgent'}
          onChange={e => dispatch(updateCompanyUrgentAction(company.id, e.target.checked ? 'Urgent' : ''))}
        />
      ))
    },
  };

  const columns = columnSet.map(column => columnSpec[column]);

  const loadMoreCompanies = () => {
    if (count > companies.length) {
      fetchMoreCompanies({
        ...getFilterParams(filters),
        limit: 50,
        offset: companies.length
      });
    }
  };

  const handleScroll = event => {
    if (event.target.scrollTop === 0) {
      return; //Scrolling horizontally
    }

    const maxScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentScroll = event.target.scrollTop;
    if (!loadingMore && Math.abs(currentScroll - maxScroll) <= 100) {
      loadMoreCompanies();
    }
  };

  const summaryParams = {
    timeRange: moment().startOf('month').subtract(2, 'years').toISOString()
  };

  const getData = () => prepareData(
    companies ? companies.map(company => ({
      ...company,
      key: company.id ?? company.crmEntityId
    })) : []
  );

  const onRowCollapsed = key => {
    setExpandedRowKeys(expandedRowKeys.filter(rowKey => rowKey !== key));
  };

  const onRowExpanded = key => {
    if (!expandedRowKeys.includes(key)) {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  return (
    <div
      className={`${styles.companyTable} ${tableStyles.table} mt-2 position-relative`}
      onScroll={handleScroll}
    >
      <Table
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowKeys,
          expandedRowRender: company => (
            <CompanyExpandedRow company={company} />
          ),
          onExpand: (expanded, company) => {
            if (expanded) {
              if (!company.details) {
                const params = company.crmEntityId ? { ...summaryParams, crmEntityId: company.crmEntityId } : summaryParams;
                getCompanySummary(company.id, params);
              }
              const action = getWokenSnooze(company.actions, lsvpUser.id, filters.team?.[0]?.value);
              if (action) {
                dispatch(removeAction({
                  entityId: company.id,
                  entityType: EntityType.COMPANY,
                  actionType: action.actionType,
                  teamId: action.teamId,
                  userId: lsvpUser.id
                }));
                dispatch(logActivity(Activity.VIEW_WOKEN_COMPANY, lsvpUser.id, {
                  companyId: company.id
                }));
              }
              dispatch(logActivity(Activity.EXPAND_COMPANY, lsvpUser.id, {
                companyId: company.id
              }));
              onRowExpanded(company.key);
            } else {
              onRowCollapsed(company.key);
            }
          },
          rowExpandable: company => !company?.section
        }}
        dataSource={getData()}
        pagination={false}
        scroll={scrollbarStyle}
        rowClassName={company => {
          if (company.section) { return styles.section; }
          if (noRowHighlight) { return ''; }
          const actionStatus = getActionStatus(company.actions, lsvpUser.id, filters.team?.[0]?.value) ;
          if (actionStatus.includes(ActionStatus.BLOCKED)) { return tableStyles.blocked; }
          if (actionStatus.includes(ActionStatus.SNOOZED)) { return tableStyles.snoozed; }
          if (actionStatus.includes(ActionStatus.WOKEN)) { return tableStyles.woken; }
          if (actionStatus.includes(ActionStatus.FLAGGED)) { return tableStyles.flagged; }
          return '';
        }} 
      />
      {loadingMore && (
        <div className={tableStyles.spinner}>
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      )}
    </div>
  );
};
