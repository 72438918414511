import React from 'react';
import { Tag } from 'antd';

export default ({ blocked, snoozed, woken, ipo, acquired, pe }) => (
  <>
    {blocked ? <Tag color="red" key="blocked" className="mt-1">BLOCKED</Tag> : null}
    {snoozed ? <Tag color="orange" key="snoozed" className="mt-1">SNOOZED</Tag> : null}
    {woken ? <Tag color="green" key="woken" className="mt-1">WOKEN</Tag> : null}
    {ipo ? <Tag color="blue" key="ipo" className="mt-1">IPO</Tag> : null}
    {acquired ? <Tag color="purple" key="acuired" className="mt-1">ACQUIRED</Tag> : null}
    {pe ? <Tag color="cyan" key="pe" className="mt-1">PE</Tag> : null}
  </>
);
