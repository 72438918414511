import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Modal, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  removeEntryFromLists,
  addEntityToLists
} from '../../Store/actions/crm/listEntry';
import showNotification from '../Shared/Notification';
import PlusIcon from '../../Assets/images/plus.png';
import './AddToAffinityListModal.scss';
import sharedStyles from '../Shared/Shared.module.scss';

export default ({
  entityId,
  crmEntityId,
  currentLists = [],
  isCompanyInfo
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [visible, setVisible] = useState(false);
  const [listsToAddTo, setListsToAddTo] = useState([]);
  const [listsToRemoveFrom, setListsToRemoveFrom] = useState([]);
  const affinityLists = useSelector(({ crmLists }) => crmLists.lists);
  const dispatch = useDispatch();

  const showModal = () => setVisible(!visible);
  const handleOk = () => setVisible(!visible);
  const handleCancel = () => setVisible(!visible);

  const applyChanges = () => {
    if (listsToAddTo.length) {
      const params = { entityId, listsToAddTo };
      if (crmEntityId) { params.crmEntityId = crmEntityId; }
      dispatch(addEntityToLists(params));
    }
    if (listsToRemoveFrom.length) {
      dispatch(removeEntryFromLists(listsToRemoveFrom));
    }
    setListsToAddTo([]);
    setListsToRemoveFrom([]);
    handleCancel();
    showNotification('info', 'Modifications submitted!');
  };

  const onListChecked = listId => {
    if (listsToRemoveFrom.find(list => list.listId === listId)) {
      setListsToRemoveFrom(
        listsToRemoveFrom.filter(list => list.listId !== listId)
      );
    } else if (!currentLists.find(list => list.listId === listId)) {
      setListsToAddTo([...listsToAddTo, listId]);
    }
  };

  const onListUnchecked = listId => {
    if (listsToAddTo.includes(listId)) {
      setListsToAddTo(listsToAddTo.filter(value => value !== listId));
    } else if (currentLists.find(list => list.listId === listId)) {
      setListsToRemoveFrom([
        ...listsToRemoveFrom,
        currentLists.find(list => list.listId === listId)
      ]);
    }
  };

  const onCheckboxChanged = (checked, listId) =>
    checked ? onListChecked(listId) : onListUnchecked(listId);

  return (
    <>
      {isCompanyInfo ? (
        <span className="add-owners" onClick={showModal}>
          <span className="modal-button">Edit List(s)</span>
        </span>
      ) : (
        <Tooltip
          placement="topRight"
          overlayClassName={sharedStyles.tooltip}
          title={<span>Add this company to one more Affinity lists</span>}
        >
          <span className="add-owners" onClick={showModal}>
            <img src={PlusIcon} alt="add-icon" />
          </span>
        </Tooltip>
      )}
      <Modal
        className="affinity-list-modal-wrapper"
        title={
          <ModalHeader
            updateSearchTerm={setSearchTerm}
            title={'Add to Affinity list'}
            visible={visible}
          />
        }
        closeIcon={
          <FontAwesomeIcon icon={faTimes} className="modal-close-icon" />
        }
        width={621}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="modal-grid-content">
          {affinityLists
            .filter(({ label }) =>
              label.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map(({ value: listId, label }, index) => (
              <Checkbox
                key={index}
                defaultChecked={currentLists.find(
                  list => list.listId === listId
                )}
                onChange={e => onCheckboxChanged(e.target.checked, listId)}
              >
                {label}
              </Checkbox>
            ))}
        </div>
        <div className="modal-apply-btn-wrapper">
          <button
            className="modal-apply-btn"
            onClick={applyChanges}
            disabled={!listsToAddTo.length && !listsToRemoveFrom.length}
          >
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
};
