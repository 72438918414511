import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from '../../../Utils/UseDeepCompareEffect';
import { LoadingOutlined } from '@ant-design/icons';
import { getAllLSVPUsers } from '../../../Store/actions/lsvp-user/list';
import { Spin } from 'antd';
import {
  updateEntityNote,
  createEntityNote,
  deleteEntityNote,
  getEntityNotes
} from '../../../Store/actions/crm/entity';
import ReplyNoteItem from './ReplyNoteItem';
import NoteItem from './NoteItem';
import AddNote from './AddNote';
import moment from 'moment';
import styles from './Notes.module.scss';

export default ({
  entityId,
  entityType,
  crmEntityId,
  readonly
}) => {
  const [notes, setNotes] = useState([]);
  const [replyNotes, setReplyNotes] = useState([]);
  const entityNotes = useSelector(({ crmEntity }) =>  crmEntity.notes[entityId]);
  const lsvpUser = useSelector(({ login }) =>  login.lsvpUser);
  const loading = useSelector(({ loader }) =>  loader.notesLoader);
  const users = useSelector(({ lsvpUsersList }) => lsvpUsersList.users);
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    if (crmEntityId) {
      dispatch(getEntityNotes(entityId, entityType));
    }
  }, [entityId, getEntityNotes, crmEntityId, dispatch]);

  useEffect(() => {
    if (entityNotes) {
      setNotes(entityNotes.filter(note => !note.parentId));
      setReplyNotes(entityNotes.filter(note => note.parentId));
    }
  }, [entityNotes]);

  useEffect(() => {
    dispatch(getAllLSVPUsers());
  }, [dispatch]);

  const deleteNoteAction = noteId => {
    dispatch(deleteEntityNote(entityId, entityType, noteId));
  };

  const updateNoteAction = (noteId, content) => {
    dispatch(updateEntityNote(entityId, entityType, noteId, content));
  };

  // construct users array to be used as mention list
  const mapUsersToAcceptableFormat = () => {
    return (
      users &&
      users.map(user => {
        return {
          id: user.crmUserId,
          display: `${user.firstName} ${user.lastName}`
        };
      })
    );
  };

  const createNoteAction = (
    entityId,
    entityType,
    content,
    creatorId,
    mentionList
  ) => dispatch(createEntityNote(entityId, entityType, content, creatorId, mentionList));

  return (
    <div className={styles.notes}>
      <AddNote
        entityId={entityId}
        entityType={entityType}
        createNoteAction={createNoteAction}
        currentUser={lsvpUser}
        getLsvpUsers={mapUsersToAcceptableFormat}
        disableAddNoteBtn={loading ? true : false}
      />
      <Spin
        className="mt-5"
        spinning={loading}
        delay={300}
        indicator={<LoadingOutlined spin />}
      >
        {notes.map(note => {
          return (
            <>
              <NoteItem
                noteId={note?.id}
                noteCharacterName={note?.creatorName || 'Anonymous'}
                noteDate={moment(note?.createdAt).format('MMM D, YYYY')}
                noteContent={note?.content}
                readonly={readonly}
                deleteNoteAction={deleteNoteAction}
                updateNoteAction={updateNoteAction}
                getLsvpUsers={mapUsersToAcceptableFormat}
              />
              {replyNotes
                .filter(note => note.parent_id === note.id)
                .map(replyNote => {
                  return (
                    <ReplyNoteItem
                      replyNoteCharacterName={replyNote?.creatorName || 'Anonymous'}
                      replyNoteDate={moment(replyNote?.createdAt).format('MMM D, YYYY')}
                      replyNoteContent={replyNote?.content}
                    />
                  );
                })}
            </>
          );
        })}
      </Spin>
    </div>
  );
};
