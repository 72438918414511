import { CrmIdAction } from '../../types/crm/id';

export const setCrmId = payload => ({
  type: CrmIdAction.SET_CRM_ID,
  payload
});

export const localUpdateCrmId = payload => ({
  type: CrmIdAction.LOCAL_UPDATE_CRM_ID,
  payload
});
