import { SectorAction } from '../../types/sector/list';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SectorAction.SET_SECTORS:
      return action.payload.sectors;
    default:
      return state;
  }
};
