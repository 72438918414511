import { call, put, takeLatest } from 'redux-saga/effects';
import { CrmInteractionAction } from '../../types/crm/interaction';
import { dispatchSnackbarError } from '../../../Utils/Shared';
import API from '../../../Network/Apis/crm/interaction';

export function* addCrmInteraction({ payload }) {
  try {
    const { filters, postAction, ...rest } = payload;
    yield call(API.addCrmInteraction, rest);
    if (postAction) {yield put(postAction); }
  } catch (err) {
    yield call(dispatchSnackbarError, err?.response?.data);
  }
}

export function* crmInteractionSaga() {
  yield takeLatest(CrmInteractionAction.ADD_CRM_INTERACTION, addCrmInteraction);
}

