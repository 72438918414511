import * as types from "../types/moreFilters";

const INITIAL_STATE = false;

export default function moreFilters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SHOW_MORE_FILTERS:
      return action.payload;
    default:
      return state;
  }
}
