import { CrmCompanyAction } from '../../types/crm/company';

export const getCompanyPeople = payload => ({
  type: CrmCompanyAction.GET_COMPANY_PEOPLE,
  payload
});

export const setCompanyPeople = payload => ({
  type: CrmCompanyAction.SET_COMPANY_PEOPLE,
  payload
});

export const addCompanyPerson = payload => ({
  type: CrmCompanyAction.ADD_COMPANY_PERSON,
  payload
});

export const localAddCompanyPerson = payload => ({
  type: CrmCompanyAction.LOCAL_ADD_COMPANY_PERSON,
  payload
});
