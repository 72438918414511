import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { formatDate } from '../../Utils/Shared';

export default ({ date }) => {
  let color;
  if (date) {
    const differenceInDays = moment().diff(date, 'days');
    if (differenceInDays < 90) {
      color = 'green';
    } else if (differenceInDays < 180) {
      color = 'orange';
    } else {
      color = 'red';
    }
  } else {
    color = 'red';
  }
  const stringDate = formatDate(date);
  return (
    <Tag color={color} key={stringDate}>
      {stringDate.toUpperCase()}
    </Tag>
  );
};