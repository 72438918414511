import { PeopleListAction } from '../../types/people/list';

export const getPeopleList = payload => ({
  type: PeopleListAction.GET_PEOPLE_LIST,
  payload
});

export const setPeopleList = ({ people, message, highlights, sectors, schools, count }) => ({
  type: PeopleListAction.SET_PEOPLE_LIST,
  payload: { people, message, highlights, sectors, schools, count }
});

export const getMorePeopleList = payload => ({
  type: PeopleListAction.GET_MORE_PEOPLE_LIST,
  payload
});

export const setMorePeopleList = ({ people, message }) => ({
  type: PeopleListAction.SET_MORE_PEOPLE_LIST,
  payload: { people, message }
});

export const updatePeopleList = people => ({
  type: PeopleListAction.UPDATE_PEOPLE_LIST,
  payload: { people }
});

export const downloadPeopleCSVAction = (downloadResolver, params = {}) => ({
  type: PeopleListAction.DOWNLOAD_PEOPLE_CSV,
  payload: {
    downloadResolver,
    params
  }
});
