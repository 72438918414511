import { CrmEntityAction } from '../../types/crm/entity';

export const getEntityNotes = (entityId, entityType) => ({
  type: CrmEntityAction.GET_ENTITY_NOTES,
  payload: { entityId, entityType }
});

export const setEntityNotes = payload => ({
  type: CrmEntityAction.SET_ENTITY_NOTES,
  payload
});

export const createEntityNote = (
  entityId,
  entityType,
  content,
  creatorId,
  mentionList
) => ({
  type: CrmEntityAction.CREATE_ENTITY_NOTE,
  payload: { entityId, entityType, content, creatorId, mentionList }
});

export const updateEntityNote = (entityId, entityType, noteId, content) => ({
  type: CrmEntityAction.UPDATE_ENTITY_NOTE,
  payload: { entityId, entityType, noteId, content }
});

export const deleteEntityNote = (entityId, entityType, noteId) => ({
  type: CrmEntityAction.DELETE_ENTITY_NOTE,
  payload: { entityId, entityType, noteId }
});

export const getEntityInteractions = payload => ({
  type: CrmEntityAction.GET_ENTITY_INTERACTIONS,
  payload
});

export const getMoreEntityInteractions = payload => ({
  type: CrmEntityAction.GET_MORE_ENTITY_INTERACTIONS,
  payload
});

export const setEntityInteractions = payload => ({
  type: CrmEntityAction.SET_ENTITY_INTERACTIONS,
  payload
});

export const setMoreEntityInteractions = payload => ({
  type: CrmEntityAction.SET_MORE_ENTITY_INTERACTIONS,
  payload
});
