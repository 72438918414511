import React from 'react';
import { MiniArea } from 'ant-design-pro/lib/Charts';
import styles from './MiniAreaChart.module.scss';

export default ({
  borderColor,
  title,
  format,
  height,
  points
}) => {
  const data = points.map(({ value, time }) => ({
    x: time,
    y: parseFloat(value)
  })).reverse();

  return (
    <div className={styles.miniAreaChart}>
      <div className="flex-1">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className={styles.title}>{title}</h6>
          <span className={styles.max}>
            {format(data[data.length - 1]?.y)}
          </span>
        </div>
        <MiniArea
          borderColor={borderColor}
          color="transparent"
          data={data}
          height={height}
          animate={true}
          line={true}
        />
      </div>
    </div>
  );
};
